import { Icon } from '@chakra-ui/react';
import { MdPerson,  MdLock, MdPlace, MdAssignment, MdCalendarMonth, MdSensorOccupied, MdAccountBalanceWallet } from 'react-icons/md';
import { FaSpa } from "react-icons/fa";

// Admin Imports
import MainDashboard from 'views/admin/default'
import Profile from 'views/admin/profile';
// Auth Imports
import SignInCentered from 'views/auth/signIn';
import Patients from 'views/admin/patients';
import Users from 'views/admin/Users';
import PEC from 'views/admin/PEC/index';
import Plans from 'views/admin/Planing';
import Params from 'views/admin/params';
import { SettingsIcon } from '@chakra-ui/icons';
import { RiHospitalFill } from 'react-icons/ri';
import Clinique from 'views/admin/clinique';
import { HomeIcon } from 'components/icons/Icons';
import caisse from 'views/admin/caisse';
import Spa from 'views/admin/SPA';



const routes = [
	{
		name: 'Acceuil',
		layout: '/admin',
		path: '/default',
		icon: <Icon as={HomeIcon} width='20px' height='20px' color='inherit' />,
		component: MainDashboard
	},
{
		name: 'Caisse',
		layout: '/admin',
		path: '/caisse',
		icon: <Icon as={MdAccountBalanceWallet} width='20px' height='20px' color='inherit' />,
		component: caisse
	},
	// {
	// 	name: 'Profile',
	// 	layout: '/admin',
	// 	path: '/profile',
	// 	icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
	// 	component: Profile
	// },

	{
		name: 'Patient',
		layout: '/admin',
		path: '/patient',
		icon: <Icon as={MdSensorOccupied} width='20px' height='20px' color='inherit' />,
		component: Patients
	},
	{
		name: 'Utilisateur',
		layout: '/admin',
		path: '/users',
		icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
		component: Users
	},
	{
	name: 'Sign In',
	layout: '/auth',
	path: '/sign-in',
	icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
	component: SignInCentered
},
	{
		name: 'Prise en charge',
		layout: '/admin',
		path: '/Pec',
		icon: <Icon as={MdAssignment} width='20px' height='20px' color='inherit' />,
		component: PEC
	},
	{
	
		name: 'Planning',
		layout: '/admin',
		path: '/planning',
		icon: <Icon as={MdCalendarMonth} width='20px' height='20px' color='inherit' />,
		component: Plans
	},
	{
	
		name: 'Clinique',
		layout: '/admin',
		path: '/clinique',
		icon: <Icon as={RiHospitalFill } width='20px' height='20px' color='inherit' />,
		component: Clinique
	},
	{
	
		name: 'Paramètres',
		layout: '/admin',
		path: '/params',
		icon: <Icon as={SettingsIcon} width='20px' height='20px' color='inherit' />,
		component: Params
	},
	{
	
		name: 'Spa',
		layout: '/admin',
		path: '/Spa',
		icon: <FaSpa />
		,
		component: Spa
	},
	
];

export default routes;
