import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Spinner,
  Flex,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useToast,
} from "@chakra-ui/react";
import { createColumnHelper, flexRender, getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from "@tanstack/react-table";
import { HamburgerIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons';
import AddTreatment from '../addparam/addtreatment';
import Pagination from 'views/admin/Users/pagination';
import TreatmentSearchBar from './searches/searchbartreatment';
import apiClient from 'views/admin/appclient';
import EditTreatment from '../editparam/edittreatment';
interface PageState {
  currentPage: number;
  totalPages: number;
}
type Treatment = {
  _id: string;
  name: string;
  description: string;
  price: number;
  actions: JSX.Element;
};

const TreatmentTable: React.FC = () => {
  const columnHelper = createColumnHelper<Treatment>();
  const [treatments, setTreatments] = useState<Treatment[]>([]);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const [isLoading, setIsLoading] = useState(true);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [page, setPage] = useState<PageState>({ currentPage: 1, totalPages: 1 });

  const toast = useToast();
  useEffect(() => {
    fetchTreatments();
  }, []);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedTreatmentId, setSelectedTreatmentId] = useState<string>('');

const handleEditTreatment = (treatmentId: string) => {

  setSelectedTreatmentId(treatmentId);
  setIsEditModalOpen(true);
};

const closeEditModal = () => {
  setSelectedTreatmentId(null);
  setSelectedTreatmentId("");
  setIsEditModalOpen(false);
  
};

  const fetchTreatments = async () => {
    try {
        const accessToken = localStorage.getItem('token');
        if (!accessToken) {
            console.error('Access token not found in local storage');
            return;
          }
      const response = await apiClient.get('api/treatment' , 
      {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

      setTreatments(response.data.items);
      setOriginalData(response.data.items);
      console.log("data",  response.data);
    } catch (error) {
      console.error('Error fetching Treatments:', error);
    } finally {
      setIsLoading(false);
    }
  };
  const onDelete = async (treatmentID: string) => {
    const accessToken = localStorage.getItem('token');
    if (!accessToken) {
      console.error('Access token not found in local storage');
      return;
    }
  
    try {
      const response = await apiClient.delete(`/api/treatment/${treatmentID}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
  
      if (response.status === 200) {
        toast({
          title: "Traitement supprimé avec succès",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        await fetchTreatments();
      } else {
        console.error('Error deleting treatment. Status:', response.status);
      }
    } catch (error) {
      console.error('Error deleting treatment:', error);
    }
  };
  
  const handleAddTreatment = () => {
    setIsAddModalOpen(true);
  };

  const closeAddModal = () => {
    setIsAddModalOpen(false);
  };

  const onAddTreatment = () => {
    // You can trigger any actions needed after adding a treatment, e.g., refetch treatments
    fetchTreatments();
    closeAddModal(); // Close the modal after adding a treatment
  };
  const handlePageChange = (selectedPage: number) => {
    const accessToken = localStorage.getItem('token');
    const adjustedPage = Math.min(selectedPage, page.totalPages);
    const apiUrl = `/api/treatment/?page=${adjustedPage}`; // Corrected endpoint

    apiClient
      .get(apiUrl, { headers: { Authorization: `Bearer ${accessToken}` } })
      .then((response) => {
        setIsLoading(false);
        setTreatments(response.data.items);
  
        setPage({
          currentPage: adjustedPage,
          totalPages: response.data.totalPages,
        });
      })
      .catch((error) => {
        setIsLoading(false);
        console.error('Error fetching Medical records:', error);
        console.log(adjustedPage);
      });
  };
  const columns = [
    columnHelper.accessor("name", {
      id: "name",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "20px" }}
          color='blue.500'
        >
          Nom complet 
        </Text>
      ),
    }),
    columnHelper.accessor("description", {
      id: "description",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "20px" }}
          color='blue.500'
        >
          Description
        </Text>
      ),
    }),
    columnHelper.accessor("price", {
      id: "price",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "20px" }}
          color='blue.500'
        >
          Prix
        </Text>
      ),
    }),
    columnHelper.accessor("actions", {
      id: "actions",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "20px" }}
          color='blue.500'
        >
         Actions
        </Text>
      ),
      cell: (info: any) =>  (
        <Box
        display="flex"
        alignItems="center"
        marginLeft="10%"
      >
        <Menu>
          <MenuButton
            as={Button}
         
            fontSize={{ sm: '10px', lg: '16px' }}
            colorScheme="gray"
          >
            {/* Hamburger icon */}
            <HamburgerIcon />
          </MenuButton>
          <MenuList>
            <MenuItem
              fontSize={{ sm: '10px', lg: '16px' }}
              onClick={() => handleEditTreatment(info.row.original._id)}>
              <EditIcon mr={2} />
              Modify
            </MenuItem>
            <MenuItem
              fontSize={{ sm: '10px', lg: '16px' }}
             
              onClick={() => onDelete(info.row.original._id)}
            >
              {/* Delete icon */}
              <DeleteIcon mr={2} />
              Delete
            </MenuItem>
          </MenuList>
        </Menu>
        </Box>
      ),
    }),
  ];
  const table = useReactTable({
    data: treatments,
    columns: columns as any, 
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });
  const [selectedCategory, setSelectedCategory] = useState('name');
  const [originalData, setOriginalData] = useState([]);
  const handleSearch = (searchQuery: string, searchCategory: string) => {
    const filteredData = originalData.filter((t: any) => {
      const fieldToSearch =
        searchCategory === 'price' ? (t && t.price || '').toString().toLowerCase() :
        searchCategory === 'description' ? (t && t.description || '').toString().toLowerCase() :
        searchCategory === 'name' ? (t && t.name || '').toString().toLowerCase() :
          '';
  
 
      const includesQuery = fieldToSearch.includes(searchQuery?.toLowerCase());
  
      return includesQuery;
    });
  
    setTreatments(filteredData);
  };
  return (
    <Box w="100%" px="0px" overflowX={{ sm: "scroll", lg: "hidden" }} display="flex"
    maxWidth="100%"
    overflow="auto"
    borderWidth="1px"
    boxShadow="5px 14px 10px 0px  rgba(128, 128, 128, 0.5)"  
    padding="4"
    borderRadius="xl" 
   flexDirection="column"
    bg={"white"}>
         {isLoading ? (
          <Flex justifyContent="center" align="center" height="200px" fontSize="20px">
            <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" /> Loading
          </Flex>
        ) : (
       <><Flex justifyContent="space-between" alignItems="center" mb="4">
       <Text
                color={textColor}
                fontSize="24px"
                mb="4px"
                fontWeight="700"
                lineHeight="100%"
              >
                Liste des traitment
              </Text>


              <TreatmentSearchBar onSearch={handleSearch} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} />
    
            <Button onClick={handleAddTreatment} colorScheme="blue" size="lg" mb="4">
              Ajouter Traitment 
            </Button>
          </Flex><AddTreatment isOpen={isAddModalOpen} onClose={closeAddModal} onAddTreatment={onAddTreatment} /><Table variant="simple" color="gray.500" mb="24px" mt="12px">
              <Thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <Tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <Th
                        key={header.id}
                        colSpan={header.colSpan}
                        pe="10px"
                        borderColor={borderColor}
                        cursor="pointer"
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        <Flex
                          justifyContent="space-between"
                          align="center"
                          fontSize={{ sm: '10px', lg: '20px' }}
                          color="gray.400"
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {{
                            asc: "",
                            desc: "",
                          }[header.column.getIsSorted() as string] ?? null}
                        </Flex>
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>
              <Tbody>
                {table.getRowModel().rows?.map((row, rowIndex) => (
                  <Tr key={row?.id}>
                    {row?.getVisibleCells().map((cell) => (
                      <Td
                        key={cell.id}
                        fontSize={{ sm: '20px', lg: "20px" }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        borderBottomWidth="1px"
                        backgroundColor={`rgba(127, 204, 252, ${rowIndex % 2 === 0 ? 0 : 0.1})`}
                        borderStyle="solid"
                        fontWeight="bold"
                        color={textColor}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </Td>
                    ))}
                  </Tr>
                ))}
              </Tbody>
 
            </Table></>)}
            <Flex justify="center">
                <Box>
                  <Pagination currentPage={page.currentPage} totalPages={page.totalPages} onPageChange={handlePageChange} />
                </Box>
              </Flex>
              <EditTreatment
        isOpen={isEditModalOpen}
        onClose={closeEditModal}
        onEditTreatment={fetchTreatments}
        treatmentId={selectedTreatmentId || ''} 
      />
    </Box>
  );
};

export default TreatmentTable;
