import  { useEffect, useState } from "react";
import { Calendar, dateFnsLocalizer, momentLocalizer, Views } from "react-big-calendar";

import "react-big-calendar/lib/css/react-big-calendar.css";
import EventCard from "./EventCard";
import appclient from "views/admin/appclient";
import moment from 'moment';
import 'moment/locale/fr';
import KineLegend from "./KineLegend";
const localizer = momentLocalizer(moment);

const CalendarPage = () => {
  const token = localStorage.getItem("token");
  const [sessions, setSessions] = useState([]);
  const [team, setTeam] = useState([]);
  const [selectedPhysiotherapist, setSelectedPhysiotherapist] = useState("ALL");
  const [selectedEvent, setSelectedEvent] = useState(null);
  useEffect(() => {
    fetchTeam();
    fetchSessions();
  
  }, [token, selectedPhysiotherapist]);
  const getFormattedDate = (fullDateString: string): string => {
    const matchDate = fullDateString.match(/^(\d{4}-\d{2}-\d{2})$/);
    const matchDateString = fullDateString.match(/(\d{1,2})\/(\d{1,2})\/(\d{4})/);
  
    if (matchDateString) {
      const day = matchDateString[1].padStart(2, '0');
      const month = matchDateString[2].padStart(2, '0');
      const year = matchDateString[3];
  
      // Return the date in "yyyy-MM-dd" format
      return `${year}-${month}-${day}`;
    } else if (matchDate) {
      return matchDate[0]; // Return the full string if it matches the "yyyy-MM-dd" format
    } else {
      return ''; // Handle the invalid case accordingly
    }
  };
  const transformSessionData = (visit: any) => {
    const newSessions = visit.schedule.flatMap((entry: any) => {
      const daysOfWeek = entry.daysOfWeek;

      return daysOfWeek.map((dayOfWeek: any) => {
        // Check if daysOfWeek is defined and not empty
        if (dayOfWeek && dayOfWeek.trim()) {
          let formattedDate: string;
  
          // Check for different date formats and parse accordingly
          if (dayOfWeek.includes('/')) {
            formattedDate = getFormattedDate(dayOfWeek);
          } else {
            formattedDate = getFormattedDate(dayOfWeek);
          }
  
          // Check if startHour and endHour are defined
          const startHour = entry.startHour && entry.startHour.trim() ? entry.startHour : '00:00';
          const endHour = entry.endHour && entry.endHour.trim() ? entry.endHour : '00:00';
  
          const modifiedStart = new Date(formattedDate);
          modifiedStart.setHours(startHour.split(':')[0], startHour.split(':')[1], 0, 0);
  
          const modifiedEnd = new Date(formattedDate);
          modifiedEnd.setHours(endHour.split(':')[0], endHour.split(':')[1], 0, 0);
  
          return {
            title: visit.title,
            start: modifiedStart,
            end: modifiedEnd,
            kine: visit.kine,
            medicalCareId: visit.medicalCareId,
            visitID: visit.id,
            _id: visit?.schedule?.[0]?._id,
          };
        } else {
          // Handle the case when daysOfWeek is undefined or empty
          console.error('Invalid date format in daysOfWeek:', dayOfWeek);
          return null; // or handle it according to your requirements
        }
      });
    });
  
    const filteredSessions = selectedPhysiotherapist === 'ALL'
      ? newSessions
      : newSessions.filter((session: { kine: { _id: any } }) => session.kine._id === selectedPhysiotherapist);
  
    setSessions(filteredSessions);
  
    return filteredSessions;
  };
  
  
  const fetchSessions = async () => {
    try {
      let res;


        res = await appclient.get("/api/visits", {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        });
 
      const transformedSessions = res.data.flatMap((visit:any) => transformSessionData(visit));
      setSessions(transformedSessions);
      // console.log("  console.log(transformedSessions)" , transformedSessions)
      console.log("events", transformedSessions)
    } catch (e) {
      console.log(e);
    }
  };
  const fetchTeam = async () => {
    try {
      const res = await appclient.get("/api/resources", {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });
      const kineTeamMembers = res.data.kinés.map((kine : any ) => ({
        value: kine._id.toString(),
        label: kine.firstName ,
        color : kine.color
      }));
      setTeam(kineTeamMembers);
    
    } catch (e) {
      console.log(e);
    }  
  };
  const handleEventClick = (event : any) => {
    setSelectedEvent(event);
  };

  const closeEventCard = () => {
    setSelectedEvent(null);
  };
  const eventPropGetter = (event: { kine: { color: string; }; }) => {
    const backgroundColor = event.kine.color || '#ffffff'; 
    
    const getTextColor = (bgColor: string) => {
      const hex = bgColor.replace('#', '');
      const r = parseInt(hex.substring(0, 2), 16);
      const g = parseInt(hex.substring(2, 4), 16);
      const b = parseInt(hex.substring(4, 6), 16);
      const luminance = (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255;
      return luminance > 0.5 ? '#000000' : '#ffffff'; 
    };
    const textColor = getTextColor(backgroundColor);

  return {
    style: {
      backgroundColor: backgroundColor,
      borderRadius: '5px',
      opacity: 0.8,
      color: textColor, 
      display: 'flex',
      alignItems: 'center', 
      justifyContent: 'center',
    }
  };
};

   return (
    <div>
     {/* <CustomCalendar events={sessions} team={team} /> */}
     <div style={{ marginBottom: '5%', marginTop: '5%' }}>
  <label>Select kiné:</label>
  <select
    value={selectedPhysiotherapist}
    onChange={(e) => setSelectedPhysiotherapist(e.target.value)}
  >
    <option value="ALL">Tous les kinés</option>
    {team.map((kine) => (
      <option key={kine.value} value={kine.value}>
        {kine.label}
      </option>
    ))}
  </select>
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
    <KineLegend team={team} />
  </div>
</div>

      
      <Calendar
  events={sessions}
  className="relative flex mb-6 lg:flex-wrap md:mb-5"
  defaultDate={new Date()}
  localizer={localizer}
  defaultView={Views.WEEK}
  views={[Views.WEEK, Views.DAY, Views.AGENDA]}
  min={new Date(0, 0, 0, 8, 0, 0)}
  max={new Date(0, 0, 0, 19, 30, 0)}
  style={{ height: "20%", borderRadius: '5px', opacity: 0.8, position: 'relative' }}
  timeslots={1}
  onSelectEvent={handleEventClick}
  culture="fr"
  messages={{
    today: "Aujourd'hui",
    previous: "précédente",
    next: "suivante",
    month: "Mois",
    tomorrow: "Demain",
    allDay: "Toute la journée",
    work_week: "Semaine de travail",
    yesterday: "Hier",
    week: "Semaine",
    day: "Jour",
    agenda: "Agenda",
    date: "Date",
    time: "Heure",
    event: "Événement",
    noEventsInRange: "Aucun événement dans cette plage",
    showMore: (total) => `+ ${total} événement(s) supplémentaire(s)`,
  }}
  eventPropGetter={eventPropGetter} // Apply eventPropGetter
/>

          <EventCard isOpen={!!selectedEvent} onClose={closeEventCard} selectedEvent={selectedEvent}     refresh={fetchSessions} 
 />
    
    </div>
  );
};

export default CalendarPage;
