import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Input,
  ModalBody,
  Select,
} from '@chakra-ui/react';
import apiClient from 'views/admin/appclient';

interface AddCliniqueProps {
  onCliniqueAdded: (newClinique: any) => void;
  onClose: () => void;
}

const AddClinique: React.FC<AddCliniqueProps> = ({ onCliniqueAdded, onClose }) => {
  const [name, setChambre] = useState('');
  const [patient, setPatient] = useState('');
  const [medcin, setMedcin] = useState('');
  let [price, setPrice] = useState('35');
  const [paye, setPayé] = useState(false);
  const [date, setDate] = useState('');
  const [motrice, setMotrice] = useState(false); 
  const [respiratoire, setRespiratoire] = useState(false);
  // State to store the medecins data
  const [medTeamMembers, setMedTeamMembers] = useState<{ value: string; label: string }[]>([]);

  useEffect(() => {
    // Fetch medecins data when the component mounts
    const fetchMedecins = async () => {
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };
        const response = await apiClient.get('/api/resources', { headers });

        // Map the medecins data to the required format
        const medTeamMembersData = response.data.medecins.map((medecin: any) => ({
          value: medecin._id.toString(),
          label: medecin.nom,
        }));

        setMedTeamMembers(medTeamMembersData);
      } catch (error) {
        console.error('Error fetching medecins:', error);
      }
    };

    fetchMedecins();
 
  }, []);

  const handleAddClinique = async () => {
    try {
  
      const newClinique = {
        chambreName: name,
        patientData: {
          name: patient,
          isPaied : paye ,
          seance: {
            date,
            price: parseFloat(price),
            motrice: true, 
            respiratoire: true, 
            paye,
          },
          med: medcin,
   
        },
    
      };
      console.log('New Clinique:', newClinique);
      await onCliniqueAdded(newClinique);

      onClose();
    } catch (error) {
    
      console.error('Error adding clinique:', error);
    }
  };
  const handleMedcinChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
   
    setMedcin(e.target.value);
  };
  return (
    <ModalBody>
      <Box>
        <FormControl>
          <FormLabel>Chambre</FormLabel>
          <Input value={name} onChange={(e) => setChambre(e.target.value) } />
        </FormControl>
        <FormControl mt={4}>
          <FormLabel>Patient</FormLabel>
          <Input value={patient} onChange={(e) => setPatient(e.target.value)} />
        </FormControl>
        <FormControl mt={4}>
          <FormLabel>Médecin</FormLabel>
          <Select value={medcin} onChange={handleMedcinChange}>
      <option value="" disabled>Sélectionner un médecin</option>
      {medTeamMembers.map((medecin) => (
        <option key={medecin.value} value={medecin.label}>
          {medecin.label}
        </option>
      ))}
    </Select>



        </FormControl>
        <FormControl mt={4}>
  <FormLabel>Prix</FormLabel>
  <Input
    type="number"
    value={
      motrice && respiratoire && parseFloat(price) === 35
        ? parseFloat(price) * 2
        : parseFloat(price)
    }
    onChange={(e) => {
      const newValue = parseFloat(e.target.value);
      setPrice(newValue.toString());
      if (motrice && respiratoire && parseFloat(price) === 35) {
        setPrice((newValue * 2).toString());
      }
    }}
   
    title={
      motrice && respiratoire
        ? "Le prix double car la motricité et la respiration sont actives."
        : "Entrez le prix de la séance."
    }
  />
</FormControl>



       <Flex> <FormControl mt={4}>
          <FormLabel>Motrice</FormLabel>
          <Checkbox isChecked={motrice} onChange={() => setMotrice(!motrice)} />
        </FormControl>

        <FormControl mt={4}>
          <FormLabel>Réspiratoire</FormLabel>
          <Checkbox isChecked={respiratoire} onChange={() => setRespiratoire(!respiratoire)} />
        </FormControl>
        </Flex>
        <FormControl mt={4}>
          <FormLabel>Confirmation de paiement</FormLabel>
          <Checkbox isChecked={paye} onChange={() => setPayé(!paye)} />
        </FormControl>

        <FormControl mt={4}>
          <FormLabel>Date de la séance</FormLabel>
          <Input type="date" value={date} onChange={(e) => setDate(e.target.value)} />
        </FormControl>

        <Button mt={6} colorScheme="blue" onClick={handleAddClinique}>
          Ajouter chambre
        </Button>
      </Box>
    </ModalBody>
  );
};

export default AddClinique;
