import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import apiClient from 'views/admin/appclient';
import Select from 'react-select';

interface PackAddModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAddArrousa: () => void;
}

interface Service {
  _id: number;
  nom_service: string;
  type: string; 
  prix?: number;
}


const ArrousaAddModal: React.FC<PackAddModalProps> = ({ isOpen, onClose, onAddArrousa }) => {
  const [name, setName] = useState('');
  const [price, setPrice] = useState(0);
  const [description, setDescription] = useState('');
  const [remise, setRemise] = useState(null);
  const [nbrAtt, setNbrAtt] = useState(10);
  const [selectedServices, setSelectedServices] = useState<Service[]>([]);
  const [services, setServices] = useState<Service[]>([]);
  const [adjustedPrice, setAdjustedPrice] = useState(0);
  const [isPasswordPromptOpen, setIsPasswordPromptOpen] = useState(false);
  const [password, setPassword] = useState('');
  const [isRemiseEditable, setIsRemiseEditable] = useState(false);
  const [isPasswordEntered, setIsPasswordEntered] = useState(false); 
  const [enteredPricePerPerson, setEnteredPricePerPerson] = useState(25); 
  const [isPrixPerPersonEditable, setIsPrixPerPersonEditable] = useState(false);
  const [totalServicesPrice, setTotalServicesPrice] = useState(0);
  const [totalAcc, setTotalAcc] = useState(0); 
  const toast = useToast();

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const accessToken = localStorage.getItem('token');
        if (!accessToken) {
          throw new Error('Access token not found in local storage');
        }

        const response = await apiClient.get('/api/service', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const data: Service[] = await response.data;
        setServices(data);
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    };
  

    if (isOpen) {
      fetchServices();
    }
  }, [isOpen]);
  useEffect(() => {
    // Calculate the total price of selected services
    const totalServicesPrice = selectedServices.reduce(
      (acc, service) => {
        const selectedService = services.find(s => s._id === service._id);
        return acc + (selectedService?.prix || 0);
      },
      0
    );
  
    // Calculate the total price per person
    const totalAcc = nbrAtt * enteredPricePerPerson;
  setTotalAcc(totalAcc)
    // Calculate the total price
    const total = totalAcc + totalServicesPrice;
    setPrice(total);
    setTotalServicesPrice(totalServicesPrice);
    const priceWithRemise = total - remise;
    setAdjustedPrice(priceWithRemise);
  }, [selectedServices, remise, nbrAtt, enteredPricePerPerson, services]);
  
  
  const handleAddArrousa = async () => {
    const accessToken = localStorage.getItem('token');

    if (!accessToken) {
      console.error('Access token not found in local storage');
      return;
    }

    const apiUrl = 'api/arrousa';
    try {
      const remiseValue = remise ? parseFloat(remise) : 0;

      const payload = {
        name,
        price,
        description,
        nbrAtt,
        remise: remiseValue,
        services: selectedServices,
        prix_vente: price ,
        prix_avec_remise: adjustedPrice,
        prix_total_acc : totalAcc,
        prix_par_personne: enteredPricePerPerson,
      };
      if (!name) {
        toast({
          title: 'Le nom est requis.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }
      if (price <= 0) {
        toast({
          title: 'Le prix est requis.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }
if (selectedServices.length === 0) {
  toast({
    title: 'Veuillez sélectionner au moins un service.',
    status: 'error',
    duration: 3000,
    isClosable: true,
  });
  return;
}
            
      const response = await apiClient.post(apiUrl, payload, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 201) {
        console.log('Arrousa added successfully');
        onAddArrousa();
        setName('');
        setDescription('');
        setRemise('');
        setNbrAtt(10);
        setSelectedServices([]);
        setPrice(0);
        onClose();
        setIsPasswordEntered(false)
        setIsRemiseEditable(false);
      } else {
        console.error('Error adding arrousa. Status:', response.status);
      }
    } catch (error) {
      console.error('Error adding arrousa:', error);
    }
  };

  const serviceOptions = services.map(service => ({
    value: service._id,
    label: `${service.nom_service} - ${service.type}`, // Include name and price
  }));
  

  const handlePasswordSubmit = () => {
    if (password === '09383929') {
      setIsPasswordEntered(true);
      setIsRemiseEditable(true);
      setIsPrixPerPersonEditable(true);
      setIsPasswordPromptOpen(false);     
      setPassword('');
    } else {
      alert('Incorrect password');
    }
  };
  const groupBy = <T, K extends keyof T>(array: T[], key: K) => {
    return array.reduce<Record<string, T[]>>((result, currentValue) => {
      const groupKey = String(currentValue[key]);
      if (!result[groupKey]) {
        result[groupKey] = [];
      }
      result[groupKey].push(currentValue);
      return result;
    }, {});
  };

  const groupedServices = groupBy(selectedServices, 'type');
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setPassword('');
        setIsRemiseEditable(false);
        setSelectedServices([]);
        onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Ajouter un nouveau pack Arrousa</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Nom</FormLabel>
            <Input type="text" value={name} onChange={(e) => setName(e.target.value)} />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>Remise en DT</FormLabel>
            <Input
              type="number"
              value={remise !== null ? remise : ''}
              onChange={(e) => {
                if (!isRemiseEditable) {
                  setIsPasswordPromptOpen(true);
                } else {
                  setRemise(parseFloat(e.target.value));
                }
              }}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Nombre accompaign</FormLabel>
            <Input type="number" value={nbrAtt} onChange={(e) => setNbrAtt(parseInt(e.target.value))} />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Prix par personne</FormLabel>
            <Input
              type="text"
              value={enteredPricePerPerson.toString()}
              onChange={(e) => {
                if (!isPrixPerPersonEditable) {
                  setIsPasswordPromptOpen(true);
                } else {
                  setEnteredPricePerPerson(parseFloat(e.target.value) || 0);
                }
              }}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Total prix des services</FormLabel>
            <Input type="text" value={totalServicesPrice} isReadOnly />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>Totale prix accompaign</FormLabel>
            <Input type="text" value={totalAcc} isReadOnly />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>Prix aprés remise</FormLabel>
            <Input type="text" value={adjustedPrice} isReadOnly />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>Prix de vente</FormLabel>
            <Input type="text" value={price} isReadOnly />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>Description</FormLabel>
            <Textarea value={description} onChange={(e) => setDescription(e.target.value)} />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>Services</FormLabel>
            <Select
              isMulti
              options={serviceOptions}
              value={selectedServices.map(service => ({
                value: service._id,
                label: `${service.nom_service} - ${service.type}`, // Include name and type
              }))}
              onChange={(selectedOptions) =>
                setSelectedServices(selectedOptions.map(option => {
                  const [serviceName, serviceType] = option.label.split(' - '); // Split label into name and type
                  return {
                    _id: option.value,
                    nom_service: serviceName,
                    type: serviceType,
                  };
                }))
              }
              placeholder="Sélectionnez les services"
            />
          </FormControl>

          <FormLabel>Liste sélectionnés :</FormLabel>
          {Object.entries(groupedServices).map(([type, services]) => (
            <div key={type}>
              <ul>
                <li>{type}</li>
                {services.map(service => (
                  <p key={service._id}>
                    {service.nom_service}
                  </p>
                ))}
              </ul>
            </div>
          ))}

        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleAddArrousa}>
            Ajouter
          </Button>
        </ModalFooter>
      </ModalContent>

      <Modal isOpen={isPasswordPromptOpen} onClose={() => setIsPasswordPromptOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Enter Password</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Password</FormLabel>
              <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handlePasswordSubmit}>Submit</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Modal>
  );
};

export default ArrousaAddModal;