import { Box } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import CalendarPage from './components/calander';



export default function Plans() {
  return (
 
<Box marginTop={"100px"}>
  {/* <div style={{ display: "flex", alignItems: "center" }}>
    <span style={{ marginRight: "10px" }}>Ajouter séance pour passenger :</span>
    <span style={{ marginRight: "10px" }}>
      Date: <input type="date" />
    </span>
    <span style={{ marginRight: "10px" }}>
      Heure: <input type="time" />
    </span>
    <button style={{ padding: "5px 10px" }}>Ajouter</button>
  </div> */}
  <CalendarPage />
</Box>

  );
}


