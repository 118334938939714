import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import apiClient from 'views/admin/appclient';

type EditTreatmentProps = {
  isOpen: boolean;
  onClose: () => void;
  onEditTreatment: () => void;
  treatmentId: string; // Assuming treatmentId is a string, adjust as needed
};

const EditTreatment: React.FC<EditTreatmentProps> = ({ isOpen, onClose, onEditTreatment, treatmentId }) => {
    const [loading, setLoading] = useState(true);
     const [formData, setFormData] = useState({
    name: '',
    description: '',
    price: '',
  });
  const toast = useToast();

  const closeEditModal = () => {
    setFormData({
      name: '',
      description: '',
      price: '',
    });
    onClose();
  };
 

    const fetchTreatmentDetails = async () => {
      const apiUrl = `/api/treatment/${treatmentId}`;

      try {
        const token = localStorage.getItem('token');
        const response = await apiClient.get(apiUrl, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
        });

        if (response && response.status === 200) {
          const treatmentDetails = response.data;
         console.log(response.data)
          setFormData({
            name: treatmentDetails.name,
            description: treatmentDetails.description,
            price: treatmentDetails.price,
            // Update more fields as needed
          });
        } else {
          console.error(`Failed to fetch treatment details. Status: ${response.status}`);
        }
      } catch (error) {
        console.error('Error fetching treatment details:', error);
      }finally {
        setLoading(false); // Set loading to false when done fetching
      }
    };


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  useEffect(() => {
   
    fetchTreatmentDetails();
    if (isOpen) {
      setLoading(true); // Set loading to true when modal is opened
      fetchTreatmentDetails();
    }
  }, [isOpen, treatmentId]);

  const handleEdit = async () => {
    const apiUrl = `/api/treatment/${treatmentId}`;

    try {
      const token = localStorage.getItem('token');
      const response = await apiClient.patch(apiUrl, formData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });

      if (response && response.status === 200) {
        onEditTreatment();
        onClose();
        toast({
            title: 'Kiné modifié avec succès',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
    } catch (error) {
        toast({
            title: 'traitment non modifié',
            status: "error",
            duration: 3000,
            isClosable: true,
          });
      console.error('Error updating treatment:', error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Modifier traitment</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
        {loading ? (
            <Spinner size="lg" color="blue.500" />
          ) : (
          <><FormControl>
                              <FormLabel>Nom</FormLabel>
                              <Input
                                  type="text"
                                  name="name"
                                  value={formData.name}
                                  onChange={handleChange} />
                          </FormControl><FormControl mt={4}>
                                  <FormLabel>Description</FormLabel>
                                  <Input
                                      type="text"
                                      name="description"
                                      value={formData?.description}
                                      onChange={handleChange} />
                              </FormControl><FormControl mt={4}>
                                  <FormLabel>Prix</FormLabel>
                                  <Input
                                      type="number"
                                      name="price"
                                      value={formData?.price}
                                      onChange={handleChange} />
                              </FormControl></>
          )}
        </ModalBody>
        <ModalFooter>
          <Button style={{ marginRight: "5%" }} colorScheme="blue" onClick={handleEdit}>
            Modifier
          </Button>
          <Button mr={3} onClick={closeEditModal}>
          Annuler
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditTreatment;
