// apiClient.js
import axios from 'axios';

const apiClient = axios.create({
 baseURL: "https://www.centre-bennahia.tn/",
  // baseURL: 'https://getways.centrebennahia.tn', 
  //  baseURL: 'http://localhost:5000',
});

export default apiClient;
