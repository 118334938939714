import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  ModalBody,
} from '@chakra-ui/react';
import appclient from 'views/admin/appclient';

interface EditCliniqueProps {
  clinique: any;
  onCliniqueEdited: () => void;
  onClose: () => void;
}

const EditClinique: React.FC<EditCliniqueProps> = ({ clinique, onCliniqueEdited, onClose }) => {
  console.log(clinique)
  const [chambre, setChambre] = useState(clinique.name);
  const [patient, setPatient] = useState(clinique.patient);
  const [medcin, setMedcin] = useState(clinique.patients[0].med);
  const [price, setPrice] = useState(clinique.patients[0].totalPrice);
  const [payé, setPayé] = useState(clinique.patients[0].isPaied);
  const [date, setDate] = useState(clinique.patients[0].seances[0].date);

  useEffect(() => {
    setChambre(clinique.name);
    setPatient(clinique.patients[0].name);
    setMedcin(clinique.patients[0].med);
    setPrice(clinique.patients[0].totalPrice);
    setPayé(clinique.patients[0].isPaied);
    setDate(new Date(clinique.patients[0].seances[0].date).toISOString().slice(0, -1));

    console.log(date)
  }, [clinique]);

  const handleEditClinique = () => {
    const updatedClinique = {
      name: chambre,
      patients: {
        name: patient,
        med: medcin,
        seances: {
          date: new Date(date),
          price: parseFloat(price),
          motrice: true, // You may need to adjust these values based on your requirements
          respiratoire: true,
          paye: payé,
        },
        "_id": "65bc121503b354d92f4ac028",
        totalPrice: 40, // You may need to adjust this value based on your requirements
        isPaied: false, // You may need to adjust this value based on your requirements
        
      },
    };

    const token = localStorage.getItem('token');
    const headers = { Authorization: `Bearer ${token}` };
  
    appclient
      .patch(`/api/clinique/edit/${clinique._id}`, updatedClinique, { headers })
      .then(() => {
        // Call the callback to update the parent component with the updated Clinique
        // Note: You might need to pass the Clinique ID to the onCliniqueEdited function
        onCliniqueEdited();
  
        // Close the modal
        onClose();
      })
      .catch((error) => {
        // Handle the error appropriately
        console.error('Error updating clinique:', error);
      });
    onCliniqueEdited();
    onClose();
  };

  return (
    <ModalBody>
      <Box>
        <FormControl>
          <FormLabel>Chambre</FormLabel>
          <Input value={chambre} onChange={(e) => setChambre(e.target.value)} />
        </FormControl>
        <FormControl mt={4}>
          <FormLabel>Patient</FormLabel>
          <Input value={patient} onChange={(e) => setPatient(e.target.value)} />
        </FormControl>
        <FormControl mt={4}>
          <FormLabel>Médecin</FormLabel>
          <Input value={medcin} onChange={(e) => setMedcin(e.target.value)} />
        </FormControl>
        <FormControl mt={4}>
          <FormLabel>Prix total</FormLabel>
          <Input type="number" value={price} onChange={(e) => setPrice(e.target.value)} />
        </FormControl>
      
        <Button mt={6} colorScheme="blue" onClick={handleEditClinique}>
          Enregistrer les modifications
        </Button>
      </Box>
    </ModalBody>
  );
};

export default EditClinique;
