import React from 'react';
import { Input, InputGroup, InputRightElement, Select, Box, useColorModeValue } from '@chakra-ui/react';

type SearchBarProps = {
  onSearch: (searchQuery: string, searchCategory: string) => void;
  selectedCategory: string;
  setSelectedCategory: (category: string) => void;
};

const SearchBarClinique: React.FC<SearchBarProps> = ({
  onSearch,
  selectedCategory,
  setSelectedCategory,
}) => {
  const handleCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCategory(event.target.value);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchQuery = event.target.value;
    onSearch(searchQuery, selectedCategory);
  };
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  return (
    <form>
      <InputGroup marginLeft={{ base: "-50%", md:0 , sm:0 , lg:"-50%" }} >
      <Box width="150%" marginRight={12} >
        <Input
          width="80%"
          type="text"
          name="searchField"
          placeholder={`Recherche par`}
          onChange={handleInputChange}
          opacity="1"
          color={textColor}
        />
        </Box>
        <Box width="50px" > {/* Add spacing between Input and Select */}
        <InputRightElement width="40%" marginLeft={2}>
          <Select
            value={selectedCategory}
            onChange={handleCategoryChange}
            
          >
            <option value="chambre">Chambre</option>
             <option value="patient">Patient</option>
 

          </Select>
        </InputRightElement>
        </Box>
      </InputGroup>
    </form>
  );
};

export default SearchBarClinique;
