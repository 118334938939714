import React, { useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import appclient from 'views/admin/appclient';

interface Option {
  value: string;
  label: string;
}

interface AutoSelectProps {
  label?: string;
  options: Option[];
  defaultValue?: Option | null;
  onChange?: (selectedOption: Option | null) => void;
  apiEndpoint: string;
  apiField: string; // Field name for the API call
  apiMethod?: 'post' | 'patch'; // HTTP method, defaults to 'post'
  selectedId?: string | null; // Newly added prop to store the selected ID
}

const AutoSelectPEC: React.FC<AutoSelectProps> = ({
  label,
  options,
  defaultValue,
  onChange,
  apiEndpoint,
  apiField,
  apiMethod = 'post',
  selectedId,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentOptions, setCurrentOptions] = useState(options);
  const [value, setValue] = useState<Option | null>(defaultValue || null);

  useEffect(() => {
    // Update the value when the selectedId prop changes
    if (selectedId) {
      const selectedOption = options.find((option) => option.value === selectedId);
      setValue(selectedOption || null);
    }
  }, [selectedId]);

  const handleCreateOption = async (inputValue: string) => {
    setIsLoading(true);

    try {
      const newOption = { value: inputValue, label: inputValue };
  

      const data = {
        [apiField]: inputValue,
      };

      const axiosConfig = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
          'Content-Type': 'application/json',
        },
      };

      const response =
        apiMethod === 'post'
          ? await appclient.post(apiEndpoint, data, axiosConfig)
          : await appclient.patch(apiEndpoint, data, axiosConfig);
      // Notify the parent component about the selected ID
      const newId = await response.data._id;

      // Update the option with the correct ID
      const updatedOption = { value: newId, label: inputValue };
      setCurrentOptions((prevOptions) => [...prevOptions, updatedOption]);
      setValue(updatedOption);
  
      // Notify the parent component about the selected ID
      if (onChange) {
        onChange(updatedOption);
      }
    } catch (error) {
      console.error('Error creating option:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {label && <label>{label}</label>}
      <CreatableSelect
        options={currentOptions}
        defaultValue={defaultValue}
        onChange={(selectedOption) => {
          setValue(selectedOption as Option | null);
          if (onChange) {
            onChange(selectedOption as Option | null);
          }
        }}
        onCreateOption={handleCreateOption}
        isClearable
        isDisabled={isLoading}
        isLoading={isLoading}
        placeholder={`Select ${label}`}
        value={value}
      />
    </div>
  );
};

export default AutoSelectPEC;
