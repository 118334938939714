import React, {  useState } from "react";
import {
  Flex,
  Box,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  useToast,
  Spinner,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay, // Import Spinner for the loading banner
} from "@chakra-ui/react";
import {
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import Card from "components/card/Card";

import Pagination from "../pagination";
import { HamburgerIcon, EditIcon, DeleteIcon } from "@chakra-ui/icons";
import SearchBarPEC from "./searchbaruser";
import AddUser from "./addUsers";
import EditUser from "./editUsers";
import apiClient from "views/admin/appclient";

type RowObj = {
  _id: string;
  fullName: string;
  email: string;
  role: string;
  actions : string ; 
};
interface PageState {
  currentPage: number;
  totalPages: number;
}
interface UsersTableProps {
  users: RowObj[];
}

const UsersTable: React.FC<UsersTableProps> = () => {
  const columnHelper = createColumnHelper<RowObj>();
  const [sorting, setSorting] = useState<SortingState>([]);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const toast = useToast();
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true); // State for loading
  const [page, setPage] = useState<PageState>({ currentPage: 1, totalPages: 1 });
  const [originalData, setOriginalData] = useState([]);
  const [isUserFormOpen, setIsUserFormOpen] = useState(false);
  const [isUserEditOpen , setIsUserEditOpen]=useState(false);
  const [editingUser, setEditingUser] = useState<RowObj | null>(null);

  const handleAddUser = (newUser : any) => {

    setIsUserFormOpen(false);
    fetchData(`/api/user/all?page=${currentPage}`);
  };
  const handleCloseAddUser = () => {
    setIsUserFormOpen(false);
  }
  const handleEditUser = (user: RowObj) => {
    setEditingUser(user);
    setIsUserEditOpen(true);
    
  };
  const handleEditUserModalSave = () => {
    setEditingUser(null);
    setIsUserEditOpen(false);
    fetchData(`/api/user/all?page=${currentPage}`);
   
  };
  const handleEditUserClose = () => {
    setEditingUser(null);
    setIsUserEditOpen(false);

   
  };
  const onDelete = (userId: string) => {
  
    apiClient
      .delete(`/api/user/`, {
        data: { _id: userId },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        toast({
          title: "User deleted successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        fetchData(`/api/user/all?page=${currentPage}`);
      })
      .catch((error) => {
        console.error("Error deleting user:", error);
        toast({
          title: "Error deleting user",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };
  const handlePageChange = (selectedPage: number) => {
    const adjustedPage = Math.min(selectedPage, page.totalPages);
    const apiUrl = `getways.centrebennahia.tn/api/user?page=${adjustedPage}`;

    apiClient
      .get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setIsLoading(false);
        setData(response.data.items);
setCurrentPage(adjustedPage)

        setPage({
          currentPage: adjustedPage, 
          totalPages: response.data.totalPages, 
        });
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching PEC records:", error);
      });
  };
  const columns = [
    columnHelper.accessor("fullName", {
      id: "fullName",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "20px" }}
          color='blue.500'
        >
          Nom Complet
        </Text>
      ),
    }),
    columnHelper.accessor("email", {
      id: "email",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "20px" }}
          color='blue.500'
        >
          Mail
        </Text>
      ),
    }),
    columnHelper.accessor("role", {
      id: "role",
      header: () => (
        <Text fontSize={{ base: '20px', md: '14px', lg: '20px' }} color="blue.500">
         
          Role
        </Text>
      ),
      cell: (info: any) => (
        <Box
        display="flex"
        justifyContent="ceter"
        alignItems="center"
   
      >
        <Text
          justifyContent="space-between"
     
          fontSize={{ sm: "10px", lg: "20px" }}
          color="blue.500"
        >
          {info.row.original.role === "PHYSTOTHERAPIST" ? "Kiné" : info.row.original.role}
        </Text>
        </Box>      ),
    }),
    columnHelper.accessor('actions', {
      id: 'actions',
      header: () => (
        <Text fontSize={{ base: '20px', md: '14px', lg: '20px' }} color="blue.500">
        Actions
        </Text>
      ),
      
      cell: (info: any) =>  (
        <Box display="flex"
        justifyContent="ceter"
        alignItems="center"
        marginLeft="10%">
        <Menu>
          <MenuButton
            as={Button}
         
            fontSize={{ sm: '10px', lg: '16px' }}
            colorScheme="gray"
          >
            <HamburgerIcon />
          </MenuButton>
          <MenuList>
            <MenuItem
              fontSize={{ sm: '10px', lg: '16px' }} onClick={() => handleEditUser(info.row.original)}>
              {/* Edit icon */}
              <EditIcon mr={2} />
              Modify
            </MenuItem>
            <MenuItem
              fontSize={{ sm: '10px', lg: '16px' }}
             
              onClick={() => onDelete(info.row.original._id)}
            >
      
              <DeleteIcon mr={2} />
              Delete
            </MenuItem>
          </MenuList>
        </Menu>
        </Box>
      ),
    }),
  ];
  const token = localStorage.getItem("token");
  const fetchData = (apiUrl: string) => {
    setIsLoading(true);
    apiClient
      .get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setData(response.data.items);
        setOriginalData(response.data.items);
        setPage({
          currentPage: response.data.page,
          totalPages: response.data.totalPages,
        });
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching users:", error);
      });
  };

  React.useEffect(() => {
    fetchData(`/api/user/all?page=${currentPage}`);
  }, []);
  const [data, setData] = useState([]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });
  const [selectedCategory, setSelectedCategory] = useState('fullName');
  const handleSearch = (searchQuery: string, searchCategory: string) => {
 
    const filteredData = originalData.filter((user : any) => {
      const fieldToSearch =
      searchCategory === 'fullName' ? (user?.fullName || '').toLowerCase() :
      searchCategory === 'email' ? (user?.email || '').toLowerCase() :
      searchCategory === 'role' ? (user?.role || '').toLowerCase() :
        ''; 
  
      return fieldToSearch.includes(searchQuery?.toLowerCase());
    });

    setData(filteredData);
  };
  return (
    <>
      <Card w="100%" px="0px" overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
          <Text
            color={textColor}
            fontSize="24px"
            mb="4px"
            fontWeight="700"
            lineHeight="100%"
          >
            Liste des utilisateurs
          </Text>
          <Flex mt="20px" justifyContent="space-between">
        </Flex>
          <SearchBarPEC onSearch={handleSearch} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} />
          <Button colorScheme="blue"   onClick={() => setIsUserFormOpen(true)}>Ajouter un utilisateur</Button>
     
        </Flex>
        <Box>
          {isLoading ? ( // Show loading banner when data is being fetched
            <Flex
              justifyContent="center"
              align="center"
              height="200px"
              fontSize="20px"
            >
             <Spinner
  thickness='4px'
  speed='0.65s'
  emptyColor='gray.200'
  color='blue.500'
  size='xl'
/> Loading
            </Flex>
          ) : (
            <Table variant="simple" color="gray.500" mb="24px" mt="12px">
              <Thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <Tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <Th
                        key={header.id}
                        colSpan={header.colSpan}
                        pe="10px"
                        borderColor={borderColor}
                        cursor="pointer"
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        <Flex
                          justifyContent="space-between"
                          align="center"
                          fontSize={{ sm: '10px', lg: '20px' }} 
                          color="gray.400"

                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {{
                            asc: "",
                            desc: "",
                          }[header.column.getIsSorted() as string] ?? null}
                        </Flex>
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>
              <Tbody>
                {table.getRowModel().rows?.map((row , rowIndex) => (
                  <Tr key={row?.id}>
                    {row?.getVisibleCells().map((cell) => (
                      <Td
                      key={cell.id}
                      fontSize={{ sm: '20px' , lg :"20px" }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      borderBottomWidth="1px"
                      backgroundColor={`rgba(127, 204, 252, ${rowIndex % 2 === 0 ? 0 : 0.1})`} 
                      borderStyle="solid"
                      fontWeight="bold"
                      color={textColor}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </Td>
                    ))}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )}
        </Box>
        <Flex  justify="center">
  <Box>
    <Pagination currentPage={page.currentPage} totalPages={page.totalPages} onPageChange={handlePageChange} />
  </Box>
  </Flex>
      </Card>
      <Modal isOpen={isUserFormOpen} onClose={() => setIsUserFormOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Ajouter Utilisateur</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AddUser onUserAdded={handleAddUser} onClose={handleCloseAddUser} />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isOpen={isUserEditOpen} onClose={handleEditUserClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Modifier utilisteur</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {editingUser && <EditUser user={editingUser} onUserEdited={handleEditUserModalSave} onClose={handleEditUserClose} />}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UsersTable;
