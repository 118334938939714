import React, { useEffect, useState } from 'react';
import { Box, Text, FormControl, FormLabel, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, RadioGroup, HStack, Radio, Flex, Input, Button, Switch, useToast, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import apiClient from 'views/admin/appclient';

interface PopupProps {
    id_pec: string;
    onClose: () => void;
    isOpen: boolean;
    fullName: string;
}

const Editpec: React.FC<PopupProps> = ({ isOpen, id_pec,fullName , onClose }) => {
    const [pecData, setPecData] = useState<any>(null);
    const [editedData, setEditedData] = useState<any>(null);
    const [isCnamPayment, setIsCnamPayment] = useState(false);
    const [isPayout, setIsPayout] = useState(false);
    const [PECExist, setPECExist]= useState(false);
    const token = localStorage.getItem('token');
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    };
    useEffect(() => {
        if (isOpen && id_pec) {
         
            apiClient.get(`/api/charges/${id_pec}`, config)
                .then((response) => {
                    console.log("Response:", response);
                    setPecData(response.data);
                    setEditedData(response.data);   
                     if (response.data?.paymentType === "CNAM") {
                        setIsCnamPayment(true);
                        setIsPayout(false);
                    }
                    else
                    {
                        setIsCnamPayment(false);
                        setIsPayout(true);
                    }
                })
                .catch((error) => {
                    console.error('Error fetching PEC data:', error);
                });
             
        }
    }, [isOpen, id_pec]);

    // Handle changes in the form inputs
    const handleInputChange = (name: string, value: string) => {
        setEditedData({
            ...editedData,
            [name]: value
        });
    };

    // Handle radio button change for payment type
    const handlePaymentTypeChange = (value: string) => {
        setEditedData({
            ...editedData,
            paymentType: value
        });
        if (value === "CNAM") {
            setIsCnamPayment(true);
            setIsPayout(false);
        } else if (value === "Payout") {
            setIsCnamPayment(false);
            setIsPayout(true);
        }
    };
    const toast = useToast();
    const handleEditSubmit = () => {
        
        apiClient.patch(`api/charges/update/`, 
        {
            _id :editedData._id , 
            NbSc : editedData.NbSc,
            NbScCnam : editedData.NbScCnam,
            pricePerVisit : editedData.pricePerVisit,
            paymentType : editedData.paymentType,
            NbScperweek: editedData.NbScperweek,
            PECExist:PECExist
        }
        , config)
            .then((response) => {
                if (PECExist) {
                    onClose();
                    toast({
                        title: 'PEC ajouté avec succès',
                        status: 'success',
                        duration: 3000, // Duration for which the toast is displayed
                        isClosable: true,
                    });
                    window.location.reload();
                }
            })
            .catch((error) => {

                console.error('Error updating PEC:', error);
            });
    };
 
    const handleToggle = () => {
        setPECExist((prevValue) => !prevValue);
      };


    return (
        <Modal size="xl" isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader fontSize={32}>{fullName}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                      <Flex>
    
</Flex>
            {/* Render form elements */}
            <Flex>
       
            <FormControl mb={4}>
                <FormLabel fontSize={18}>
                    Nombre de séances demandées
                </FormLabel>
                <NumberInput
                        value={editedData?.NbSc}
                        bg="white"
                        onChange={(valueString) => handleInputChange('NbSc', valueString)}
                        step={1}
                        min={0}
                        width={"100%"}
                    >
                    <NumberInputField />
                    <NumberInputStepper bg="gray.200">
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                    </NumberInputStepper>
                </NumberInput>
            </FormControl>

            <FormControl mb={4} ml={2}>
                <FormLabel fontSize={18}>
                    Nombre de séances par semaine
                </FormLabel>
                <NumberInput
                    value={editedData?.NbScperweek}
                    bg="white"
                    onChange={(valueString) => handleInputChange('NbScperweek', valueString)}
                    step={1}
                    min={0}
                    width={"100%"}
                >
                    <NumberInputField />
                    <NumberInputStepper bg="gray.200">
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                    </NumberInputStepper>
                </NumberInput>
            </FormControl>
            </Flex>
            <Flex alignItems="center">
      <Text marginRight="8%">Le patient a apporté sa PEC</Text>
      <Switch isChecked={PECExist} onChange={handleToggle} />
  
            <Text fontSize="lg" mt={4} ml={4}>
                Méthode de paiement :
            </Text>
            <RadioGroup defaultValue={editedData?.paymentType || "CNAM"} onChange={(value) => handlePaymentTypeChange(value)}>
                <HStack spacing="24px">
                    <Radio value="CNAM">CNAM</Radio>
                    <Radio value="Payout">Payout</Radio>
                </HStack>
            </RadioGroup>
            
    </Flex>
 
            {isCnamPayment && (
              <>
                <Flex alignItems="center" >
                  Nombre de sc accordé par le CNAM
                  <NumberInput
                    style={{ width: '200px', marginLeft:10 }}
                    value={editedData?.NbScCnam}
                    onChange={(valueString) => handleInputChange('NbScCnam', valueString)}
                    step={1}
                    min={0}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Flex>
                <Flex flexDirection="row">
                <Flex alignItems="center" marginRight={6} mt={2}>
  Date de début de PEC
  <Input
    style={{ width: '200px', marginLeft:106 }}
    placeholder="Date de début"
    type="date"
    value={pecData?.dateFactureCNAM ? new Date(pecData.dateDeFinPec).toISOString().split('T')[0] : new Date().toISOString().split('T')[0]}
    onChange={(e) => handleInputChange('dateDebut', e.target.value)}
  />
</Flex>


                </Flex>
                <Flex alignItems="center" marginRight={6} mt={2}>
  Date de Fin de PEC
  <Input
    style={{ width: '200px' , marginLeft:126}}
    placeholder="Date de début"
    type="date"
    value={pecData?.dateDeFinPec ? new Date(pecData.dateDeFinPec).toISOString().split('T')[0] : new Date().toISOString().split('T')[0]}
    onChange={(e) => handleInputChange('dateFin', e.target.value)}
  />
</Flex>
              </>
            )}
           {isPayout && (
              <Flex alignItems="center" mt={4}>
                <Text fontSize="lg" mr={4}>
                  Prix de la séance
                </Text>
                <NumberInput
                  bg="white"
                  value={editedData?.pricePerVisit ?? 0}
                  onChange={(valueString) => handleInputChange('pricePerVisit', valueString)}
                  step={1}
                  min={0}
                  mr={2}
                  ml={2}
                  width={100}
                >
                  <NumberInputField bg="white" />
                  <NumberInputStepper bg="gray.200">
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <Text fontSize="lg">DT</Text>
              </Flex>
            )}
          
      </ModalBody>
                <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={handleEditSubmit}>
                        Submit
                    </Button>
                    <Button onClick={onClose}>Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
       
    );
};

export default Editpec;
