import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import Select from 'react-select';
import apiClient from 'views/admin/appclient';

interface PackAddModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAddPack: () => void;
}

interface Service {
  _id: string;
  nom_service: string;
  prix: string;
}

const PackAddModal: React.FC<PackAddModalProps> = ({ isOpen, onClose, onAddPack }) => {
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [description, setDescription] = useState('');
  const [remise, setRemise] = useState('');
  const [image, setImage] = useState('');
  const [services, setServices] = useState<Service[]>([]);
  const [selectedServices, setSelectedServices] = useState<Service[]>([]);
  const toast = useToast();
  const [isRemiseEditable, setIsRemiseEditable] = useState(false);
  const [enteredPricePerPerson, setEnteredPricePerPerson] = useState(25); // State to store the entered price per person
  const [isPrixPerPersonEditable, setIsPrixPerPersonEditable] = useState(false);
  const [isPasswordPromptOpen, setIsPasswordPromptOpen] = useState(false);
  const [password, setPassword] = useState('');
  const [priceAfterRemise, setPriceAfterRemise] = useState(0); 
  useEffect(() => {
    const fetchServices = async () => {
      try {
        const accessToken = localStorage.getItem('token');
        if (!accessToken) {
          throw new Error('Access token not found in local storage');
        }

        const response = await apiClient.get('/api/service', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const data: Service[] = await response.data;
        setServices(data);
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    };

    if (isOpen) {
      fetchServices();
    }
  }, [isOpen]);

  const handleAddPack = async () => {
    if (!name) {
      toast({
        title: 'Le nom est requis.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (!price) {
      toast({
        title: 'Le prix est requis.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (!price) {
      toast({
        title: 'Le prix est requis.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (selectedServices.length === 0) {
      toast({
        title: 'Veuillez sélectionner au moins un service.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
                
    const accessToken = localStorage.getItem('token');
  
    if (!accessToken) {
      console.error('Access token not found in local storage');
      return;
    }
  
    const apiUrl = 'api/pack';
    try {
      const response = await apiClient.post(
        apiUrl,
        {
          name,
          price: priceAfterRemise.toString(),
          description,
          remise: parseFloat(remise) || undefined,
          image,
          services: selectedServices.map(service => service._id),
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.status === 201) {
        onAddPack();
        setName('');
        setPrice('');
        setDescription('');
        setRemise('');
        setImage('');
        setSelectedServices([]);
        onClose();
        toast({
          title: 'Pack ajouté avec succès',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } else {
        console.error('Error adding pack. Status:', response.status);
      }
    } catch (error) {
      console.error('Error adding pack:', error);
    }
  };
  

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      try {
        const accessToken = localStorage.getItem('token');
        if (!accessToken) {
          throw new Error('Access token not found in local storage');
        }

        const response = await apiClient.post('/upload', formData, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data',
          },
        });

        const data = await response.data;
        setImage(data.cloudinaryUrl); // Adjust this according to your API response
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  };

  const serviceOptions = services.map(service => ({
    value: service._id,
    label: service.nom_service,
    prix: service.prix,
  }));
  const handleRemiseChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    if (!isRemiseEditable) {
      setIsPasswordPromptOpen(true);
    } else {
      setRemise(e.target.value);
    }
  };
  const handlePasswordSubmit = () => {
    if (password === '09383929') {
      setIsRemiseEditable(true);
      setIsPrixPerPersonEditable(true);
      setIsPasswordPromptOpen(false);
    } else {
      alert('Incorrect password');
    }
  };
  const calculateTotalPrice = () => {
    let total = 0;
    selectedServices.forEach(service => {
      total += parseFloat(service.prix);
    });
    setPrice(total.toString());   
     const priceBeforeRemise = parseFloat(price);
    const remiseValue = parseFloat(remise) || 0;
    const priceAfterRemiseValue = priceBeforeRemise + remiseValue;
    setPriceAfterRemise(priceAfterRemiseValue);
  
  };

  useEffect(() => {
    calculateTotalPrice();
  }, [selectedServices, price, remise]);

  return (
    <Modal isOpen={isOpen} onClose={() => {
     setPassword('');
     setRemise('');
     setIsPasswordPromptOpen(false);
     setName("");
     setPrice(null);
    setImage("");
     setDescription("");
     setSelectedServices([])
      setIsRemiseEditable(false);
      onClose();
    }}>      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Ajouter un nouveau pack</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
        <FormControl>
  <FormLabel>Nom</FormLabel>
  <Input 
    type="text" 
    value={name} 
    onChange={(e) => setName(e.target.value)} 
    isRequired
  />
</FormControl>

     
       
<FormControl mt={4}>
  <FormLabel>Services</FormLabel>
  <Select
    isMulti
    options={serviceOptions}
    value={selectedServices.map(service => ({
      value: service._id,
      label: service.nom_service,
      prix: service.prix,
    }))}
    onChange={(selectedOptions) =>
      setSelectedServices(selectedOptions.map(option => ({
        _id: option.value,
        nom_service: option.label,
        prix: option.prix,
      })))
    }
    placeholder="Sélectionnez les services"
    required
  />
</FormControl>
          <FormControl mt={4}>
            <FormLabel>Image</FormLabel>
            <Input type="file" onChange={handleFileChange} />
            <FormControl mt={4}>
  <FormLabel>Prix</FormLabel>
  <Input 
    type="number" 
    value={price} 
    onChange={(e) => setPrice(e.target.value)} 
    isRequired
    min={0}
  />
</FormControl>

          <FormControl mt={4}>
            <FormLabel>Remise</FormLabel>
            <Input type="number" value={remise} onChange={handleRemiseChange}  />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Prix après remise</FormLabel>
            <Input type="number" value={priceAfterRemise.toString()} readOnly />
          </FormControl>
          </FormControl>
          {image && (
            <FormControl mt={4}>
              <FormLabel>Image Actuelle</FormLabel>
              <img src={image} alt="Uploaded" style={{ maxWidth: '100%' }} />
            </FormControl>
          )}
               <FormControl mt={4}>
            <FormLabel>Description</FormLabel>
            <Textarea value={description} onChange={(e) => setDescription(e.target.value)} />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleAddPack}>
            Ajouter Pack
          </Button>
         
        </ModalFooter>
      </ModalContent>
      <Modal isOpen={isPasswordPromptOpen} onClose={() => setIsPasswordPromptOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Enter Password</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Password</FormLabel>
              <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handlePasswordSubmit}>Submit</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Modal>
  );
};

export default PackAddModal;
