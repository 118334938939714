import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import apiClient from 'views/admin/appclient';

interface KineAddModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAddKine: () => void;
}

const KineAddModal: React.FC<KineAddModalProps> = ({ isOpen, onClose, onAddKine }) => {
  const [firstName, setNom] = useState('');
  const [address, setAdresse] = useState('');
  const [mobile, setMobile] = useState('');
  const [color, setColor] = useState('#ff0000'); // Default color
  const [isEmailValid, setIsEmailValid] = useState(true);

  const validateEmail = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return email.trim() === '' || regex.test(email);
  };

  const handleAddKine = async () => {
    const accessToken = localStorage.getItem('token');

    if (!accessToken) {
      console.error('Access token not found in local storage');
      return;
    }

    if (!validateEmail(address)) {
      setIsEmailValid(false);
      return;
    }

    const apiUrl = 'api/kine';
    try {
      const response = await apiClient.post(
        apiUrl,
        {
          firstName,
          address,
          mobile,
          color, // Send the color to the endpoint
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      onAddKine();
      if (response.status === 200) {
        setNom('');
        setAdresse('');
        setMobile('');
        setColor('#ff0000'); // Reset the color
        onClose();
      } else {
        console.error('Error adding kine. Status:', response.status);
      }
    } catch (error) {
      console.error('Error adding kine:', error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Création d'un nouveau Kine</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Nom</FormLabel>
            <Input type="text" value={firstName} onChange={(e) => setNom(e.target.value)} />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Adresse mail</FormLabel>
            <Input
              type="email"
              value={address}
              onChange={(e) => {
                setAdresse(e.target.value);
                setIsEmailValid(true); // Reset validation on input change
              }}
              isInvalid={!isEmailValid}
            />
            {!isEmailValid && (
              <span style={{ color: 'red' }}>Veuillez saisir une adresse e-mail valide</span>
            )}
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Mobile</FormLabel>
            <Input
              type="number"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              placeholder="Entrez le numéro de mobile"
              pattern="[0-9]{8}"
              title="Veuillez saisir un numéro de mobile valide à 8 chiffres"
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Couleur</FormLabel>
            <Input
              type="color"
              value={color}
              onChange={(e) => setColor(e.target.value)}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleAddKine}>
            Ajouter Kine
          </Button>
          <Button colorScheme="gray" onClick={onClose}>
            Annuler
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default KineAddModal;
