import React, { useState } from 'react';
import { Box, Button, Input, Text, FormControl, FormLabel, Switch, Flex, useColorModeValue } from '@chakra-ui/react';
import axios from 'axios';
import apiClient from 'views/admin/appclient';

interface PaymentSummary {
  totalPaid: number;
  totalUnpaid: number;
  overallTotal: number;
}

const PaymentSummaryComponent: React.FC = () => {
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [paymentSummary, setPaymentSummary] = useState<PaymentSummary | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [hideNumbers, setHideNumbers] = useState<boolean>(true);
  const token = localStorage.getItem('token');
  const headers = { Authorization: `Bearer ${token}` };
  const textColor = useColorModeValue('secondaryGray.900', 'white');
   
  const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(event.target.value);
  };

  const handleFetchPaymentSummary = async () => {
    try {
      const response = await apiClient.get<PaymentSummary>('/api/clinique/payment-summary', {
        headers,
        params: {
          startDate,
          endDate,
        },
      });

      setPaymentSummary(response.data);
      setError(null);
    } catch (error) {
      console.error('Error fetching payment summary:', error);
      setError('Error fetching payment summary');
    }
  };

  const handleToggleHideNumbers = () => {
    console.log('Toggle hide numbers');
    setHideNumbers((prevHideNumbers) => !prevHideNumbers);
  };
  

  return (
    <Box>
      <Flex flexDirection={{ base: 'column', md: 'row' }} alignItems="center" flexWrap="wrap">
        <Flex mb={{ base: '4', md: '0' }}>
          <FormControl mr={{ base: '0', md: '4' }}>
            <FormLabel color={textColor} htmlFor="startDate">
              Date de début:
            </FormLabel>
            <Input type="date" id="startDate" value={startDate} onChange={handleStartDateChange} />
          </FormControl>
          <FormControl mr={{ base: '0', md: '4' }}>
            <FormLabel htmlFor="endDate">Date de fin:</FormLabel>
            <Input type="date" id="endDate" value={endDate} onChange={handleEndDateChange} />
          </FormControl>
        </Flex>
        <Button
          colorScheme="facebook"
          marginTop={{ base: '4', md: '0' }}
          marginBottom={{ base: '4', md: '0' }}
          width={{ base: '100%', md: 'auto' }}
          onClick={handleFetchPaymentSummary}
        >
          Afficher
        </Button>
        <Switch
          colorScheme="teal"
          size="md"
          ml={{ base: '0', md: '4' }}
          marginTop={{ base: '4', md: '0' }}
          onChange={handleToggleHideNumbers}
          isChecked={!hideNumbers}
        />
        <Text ml={{ base: '2', md: '4' }} marginTop={{ base: '4', md: '0' }} fontWeight="bold">
          {!hideNumbers ? 'Afficher les nombres' : 'Cacher les nombres'}
        </Text>
      </Flex>
      {error && <Text color="red.500">{error}</Text>}
      {paymentSummary && (
        <Box mt={4}>
          <Text>Total Payé: {hideNumbers ? '*****' : paymentSummary.totalPaid}</Text>
          <Text>Total Non Payé: {hideNumbers ? '*****' : paymentSummary.totalUnpaid}</Text>
          <Text>Ensemble des payements: {hideNumbers ? '*****' : paymentSummary.overallTotal}</Text>
        </Box>
      )}
    </Box>
  );
};

export default PaymentSummaryComponent;
