import React, { useState, useEffect } from 'react';
import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Checkbox,
} from '@chakra-ui/react';
import apiClient from 'views/admin/appclient';
import Select from 'react-select';
interface Service {
  _id: string;
  nom_service: string;
  prix: number;
  duration?: number;
  startDate?: Date;
}

const Modal = ({ event, onClose, onSubmit }: { event: any, onClose: () => void, onSubmit: (updatedEvent: any) => void }) => {
  const [updatedEvent, setUpdatedEvent] = useState(event);
  const [services, setServices] = useState<string[]>([]);
  const [extraService, setExtraService] = useState<any[]>([]);
  const [selectedServices, setSelectedServices] = useState<string[]>([]);
  const [serviceToAdd, setServicesToAdd] = useState([]);
  const [nombreAccompagnant, setNombreAccompagnant] = useState('0'); 

  const isArrousaEvent = event.isArrousaEvent;
  const isPackEvent = event.isPack;
  const isServiceEvent = event.isService;
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value, type } = e.target;

    setUpdatedEvent((prevEvent: any) => ({
      ...prevEvent,
      [name]: type === 'checkbox' ? !prevEvent[name] : value,
    }));

    if (name === 'paid') {
      const total = parseFloat(updatedEvent.prix_avec_remise) || parseFloat(event.prix_avec_remise) || 0;
      const extraTotal = parseFloat(updatedEvent.extraTotal) || parseFloat(event.extraTotal) || 0;
      const extraNbrAtt = parseFloat(updatedEvent.extraNbrAtt) || parseFloat(event.extraNbrAtt) || 0;
      const deposit = parseFloat(updatedEvent.deposit) || parseFloat(event.deposit) || 0;

      let avance = deposit;
      let reste = (total + extraTotal + extraNbrAtt) - avance;

      if (type === 'checkbox' && !updatedEvent.paid) {
        avance += reste;
        reste = 0;
        
      }
      setUpdatedEvent((prevEvent: any) => ({
        ...prevEvent,
        deposit: avance,
        reste: reste,
      }));
    }
  };
  useEffect(() => {
    const total = parseFloat(updatedEvent.prix_avec_remise) || parseFloat(event.prix_avec_remise) || 0;
    const extraTotal = parseFloat(updatedEvent.extraTotal) || 0;
    const extraNbrAtt = parseFloat(updatedEvent.extraNbrAtt) || 0;
    const deposit = parseFloat(updatedEvent.deposit) || 0;
  
    const newTotalPrice = total + extraTotal + extraNbrAtt;
    const newReste = newTotalPrice - deposit;
  
    setUpdatedEvent((prevEvent: any) => ({
      ...prevEvent,
      totalPrice: newTotalPrice,
      reste: newReste,
      paid: newReste === 0
    }));
  }, [updatedEvent.extraTotal, updatedEvent.extraNbrAtt, updatedEvent.deposit, updatedEvent.prix_avec_remise]);
  
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    const fetchServices = async () => {
      try {
        const accessToken = localStorage.getItem('token');
        if (!accessToken) {
          throw new Error('Access token not found in local storage');
        }
        const extraServicePromises = event.extraService?.map(async (service: any) => {
          const response = await apiClient.get(`/api/service/${service._id}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
          return response.data;
        });
        if (extraServicePromises) {
          const extraServices = await Promise.all(extraServicePromises);
          setExtraService(extraServices); 
        }
  
        // Fetch regular services
        if (!isArrousaEvent && !isPackEvent) {
          const regularServicePromises = event.services?.map(async (serviceId: string) => {
              const response = await apiClient.get(`/api/service/${serviceId}`, {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              });
              console.log("rep for api", response.data)
              return response.data ; 
            }
          );
  
          if (regularServicePromises) {
            const regularServices = await Promise.all(regularServicePromises);
            setServices(regularServices.map(service => service.nom_service));
            console.log("aaa", services)
          }
        }
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    };
  
    fetchServices();
  }, [event.services, isArrousaEvent, isPackEvent, isServiceEvent, event.extraService]);  
  const handleSubmit = async () => {
    try {
      const accessToken = localStorage.getItem('token');
      if (!accessToken) {
        throw new Error('Access token not found in local storage');
      }
      const { client, servicesNames, ...restUpdatedEvent } = updatedEvent;

      await apiClient.patch(`/api/spa/${event._id}`, restUpdatedEvent , {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      onSubmit(updatedEvent);
      onClose();
    } catch (error) {
      console.error('Error updating event:', error);
    }
  };

  useEffect(() => {
    const fetchServicesToAdd = async () => {
      try {
        const accessToken = localStorage.getItem('token');
        if (!accessToken) {
          throw new Error('Access token not found in local storage');
        }

        const response = await apiClient.get('/api/service', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        setServicesToAdd(response.data);
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    };
    fetchServicesToAdd();

    setRefresh(false);
  }, [refresh]);

  const handleClose = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleNombreAccompagnantChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setNombreAccompagnant(value);
    const nombreAccompagnantNumber = parseFloat(value);
    const prixParPersonne = typeof event?.prix_par_personne === 'string'
      ? parseFloat(event.prix_par_personne)
      : event.prix_par_personne;

    if (!isNaN(nombreAccompagnantNumber) && !isNaN(prixParPersonne)) {
      const newPriceservices = nombreAccompagnantNumber * prixParPersonne;
      setUpdatedEvent((prevState: any) => ({ ...prevState, extraNbrAtt: newPriceservices }));
    } else {
      console.error('Invalid number inputs for calculation.');
    }
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);


  const serviceOptions = serviceToAdd.map((service: any) => ({
    value: service._id,
    label: service.nom_service,
  }));

  const handleAddExtraService = async () => {
    try {
      const accessToken = localStorage.getItem('token');
      if (!accessToken) {
        throw new Error('Access token not found in local storage');
      }
  
      const extraServicesPriceSum = selectedServices.reduce((totalPrice, serviceId) => {
        const service = serviceToAdd.find((service: Service) => service._id === serviceId);
        if (service) {
          return totalPrice + service.prix;
        }
        return totalPrice;
      }, 0);
  
      const existingExtraService = updatedEvent.extraService || [];
  
      const selectedServiceObjects = selectedServices.map(serviceId => {
        const service = serviceToAdd.find((service: Service) => service._id === serviceId);
        if (service) {
          return {
            ...service,
            duration: event.duration,
            startDate: event.start
          };
        }
        return null;
      }).filter((service): service is Service => service !== null);
  
      const updatedExtraServices = [
        ...existingExtraService,
        ...selectedServiceObjects
      ];
  
      const newExtraTotal = (updatedEvent.extraTotal || 0) + extraServicesPriceSum;
  
      setUpdatedEvent((prevState: any) => ({
        ...prevState,
        extraService: updatedExtraServices,
        extraTotal: newExtraTotal,
        totalPrice: newExtraTotal + (prevState.prix || event.prix),
        reste: ((prevState.prix || event.prix) + newExtraTotal ) - (prevState.deposit || event.deposit),
        paid: ( (prevState.prix || event.prix) + newExtraTotal )- (prevState.deposit || event.deposit) === 0
      }));
  
      await apiClient.patch(`/api/spa/${event._id}`, {
        extraService: updatedExtraServices,
        extraTotal: newExtraTotal,
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
  
      setRefresh(true);
    } catch (error) {
      console.error('Error adding extra service:', error);
    }
  };
  
  
  
  useEffect(() => {
    setExtraService(event.extraService?.map((service: { nom_service: any; }) => service.nom_service) || []);
  }, [event.extraService]);

  const handleRemoveExtraService = (serviceId: string) => {
    setExtraService(prevExtraServices => {
      const updatedExtraServices = prevExtraServices.filter(service => service._id !== serviceId);
  
      setUpdatedEvent((prevEvent: any) => ({
        ...prevEvent,
        extraService: updatedExtraServices
      }));
  
      return updatedExtraServices;
    });
  };
  
  

  
  return (
    <ChakraModal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Mondifer RDV</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
        {isArrousaEvent  ?(
          <FormControl mb={4}>
            <FormLabel>Client:</FormLabel>
            {updatedEvent.description}
          </FormControl>
        ):(
          <FormControl mb={4}>
            <FormLabel>Client:</FormLabel>
            {updatedEvent.client}
          </FormControl>
        )
        }
        
          <FormControl mb={4}>
            <FormLabel>Date de début:</FormLabel>
            <Input
              type="datetime-local"
              name="start"
              value={updatedEvent.start}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Durée ( minutes ) :</FormLabel>
            <Input
              type="stign"
              name="duration"
              value={updatedEvent.duration}
              onChange={handleChange}
            />
          </FormControl>
         
<FormControl mb={4}>
  <FormLabel>Extra Service:</FormLabel>
  {extraService.length > 0 ? (
    extraService.map((service: any, index: number) => (
      <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
        <span>{service.nom_service}</span>
        <Button
          ml={2}
          size="sm"
          color='black'
          onClick={() => handleRemoveExtraService(service._id)}
        >
          x
        </Button>
      </div>
    ))
  ) : (
    <div>pas de service extra</div>
  )}
</FormControl>

        {isArrousaEvent && (

            <FormControl mb={4}>
              <FormLabel>Extra Nombre accompagnant:</FormLabel>
              <Input
                value={nombreAccompagnant}
                onChange={handleNombreAccompagnantChange} // Update the nombreAccompagnant state
              />
            </FormControl>
)}
        <FormControl mb={4}>
          <FormLabel>Ajouter Extra service:</FormLabel>
          <Select
                options={serviceOptions}
                value={serviceOptions.filter(option => selectedServices.includes(option.value))}
                onChange={(selectedOption: any) => setSelectedServices(selectedOption.map((option: any) => option.value))}
                isMulti
              />
          <Button colorScheme="blue" ml={2} onClick={handleAddExtraService}>
            Modifer extra service 
          </Button>
        </FormControl>
        {!isServiceEvent && !isArrousaEvent && (
          <FormControl mb={4}>
            <FormLabel>Service:</FormLabel>
            <Textarea
              name="description"
              value={updatedEvent.servicesNames}
              onChange={handleChange}
              readOnly
            />
          </FormControl>
          )}
          {isArrousaEvent && (
            <FormControl mb={4}>
            <FormLabel>Service:</FormLabel>
            <Textarea
              name="description"
              value={updatedEvent.client}
              onChange={handleChange}
              readOnly
            />
          </FormControl>
          )

          }
          <FormControl mb={4}>
            <FormLabel>Couleur:</FormLabel>
            <Input
              type="color"
              name="color"
              value={updatedEvent.color}
              onChange={handleChange}
            />
          </FormControl>
         
          <FormControl mb={4}>
            <FormLabel>Commentaire:</FormLabel>
            <Textarea
              name="comment"
              value={updatedEvent.comment}
              onChange={handleChange}
            />
          </FormControl>
 
          <FormControl mb={4}>
            <FormLabel>Prix totale:</FormLabel>
            <Input
              type="text"
              name="prix"
              value={updatedEvent.prix_avec_remise !== undefined ? updatedEvent.prix_avec_remise : event.prix_avec_remise}
              onChange={handleChange}
              readOnly
            />
          </FormControl>
          <FormControl mb={4}>
  <FormLabel>Extra Total:</FormLabel>
  <Input
    type="text"
    name="extraTotal"
    value={updatedEvent.extraTotal !== undefined ? updatedEvent.extraTotal : event.extraTotal || 0}
    onChange={handleChange}
    readOnly
  />
</FormControl>
{isArrousaEvent && (
  <FormControl mb={4}>
  <FormLabel>Extra accompagnant:</FormLabel>
  <Input
    type="text"
    name="extraNbrAtt"
    value={updatedEvent.extraNbrAtt !== undefined ? updatedEvent.extraNbrAtt : event.extraNbrAtt || 0}
    onChange={handleChange}
    readOnly
  />
</FormControl>
)}

<FormControl mb={4}>
  <FormLabel>Avance:</FormLabel>
  <Input
    type="text"
    name="deposit"
    value={updatedEvent.deposit !== undefined ? updatedEvent.deposit : event.deposit || 0}
    onChange={handleChange}
    readOnly
  />
</FormControl>
<FormControl mb={4}>
  <FormLabel>Reste :</FormLabel>
  <Input
    type="text"
    name="reste"
    value={(updatedEvent.reste !== undefined ? updatedEvent.reste : event.reste )}
    onChange={handleChange}
    readOnly
  />
</FormControl>
<FormControl mb={4}>
  <FormLabel>Payé :</FormLabel>
  <Checkbox
    name="paid"
    isChecked={updatedEvent.paid !== undefined ? updatedEvent.paid : event.paid}
    onChange={handleChange}
  />
</FormControl>



        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
            Soumettre
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Fermer
          </Button>
        </ModalFooter>
      </ModalContent>
    </ChakraModal>
  );
};

export default Modal;
