import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Spinner,
  Flex,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useToast,
} from "@chakra-ui/react";
import { createColumnHelper, flexRender, getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from "@tanstack/react-table";
import KineAddModal from '../addparam/addkine';
import { DeleteIcon, EditIcon, HamburgerIcon } from '@chakra-ui/icons';
import apiClient from 'views/admin/appclient';
import Pagination from 'views/admin/Users/pagination';
import KineEditModal from '../editparam/KineEditModal';
import SearchBarKine from './searches/searchbarkine';

type RowObj = {
  _id: string;
  firstName: string;
  address: string;
  mobile: string;
  actions: string;
  color: string; // Add color property
};

interface PageState {
  currentPage: number;
  totalPages: number;
}

const KineTable: React.FC = () => {
  const columnHelper = createColumnHelper<RowObj>();
  const [kines, setKines] = useState<RowObj[]>([]);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const [isLoading, setIsLoading] = useState(true);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const toast = useToast();
  const [page, setPage] = useState<PageState>({ currentPage: 1, totalPages: 1 });
  const limit = 10;

  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedKineId, setSelectedKineId] = useState<string | null>(null);
  const handleEdit = (kineId: string) => {
    setSelectedKineId(kineId);
    setShowEditModal(true);
  };

  useEffect(() => {
    fetchKines();
  }, []);

  const handleAddKine = async () => {
    setShowAddModal(false);
    await fetchKines();
  };

  const fetchKines = async () => {
    try {
      const accessToken = localStorage.getItem('token');
      if (!accessToken) {
        console.error('Access token not found in local storage');
        return;
      }

      const response = await apiClient.get(`/api/kine?page=${page.currentPage}&limit=${limit}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          sortBy: 'createdAt',
          sortOrder: 'desc',
        },
      });

      setKines(response.data.items);
      setOriginalData(response.data.items);

      setPage({
        currentPage: response.data.pageInfo.page,
        totalPages: response.data.pageInfo.totalPages,
      });
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching Kines:', error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const onDelete = async (kineId: any) => {
    const accessToken = localStorage.getItem('token');
    if (!accessToken) {
      console.error('Access token not found in local storage');
      return;
    }

    try {
      const response = await apiClient.delete(`/api/kine/${kineId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 200) {
        toast({
          title: "kiné supprimé avec succès",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        await fetchKines();
      } else {
        console.error('Error deleting kine. Status:', response.status);
      }
    } catch (error) {
      console.error('Error deleting kine:', error);
    }
  };

  const handlePageChange = (selectedPage: number) => {
    const accessToken = localStorage.getItem('token');
    const adjustedPage = Math.min(selectedPage, page.totalPages);
    const apiUrl = `/api/medecin/?page=${adjustedPage}`;

    apiClient
      .get(apiUrl, { headers: { Authorization: `Bearer ${accessToken}` } })
      .then((response) => {
        setIsLoading(false);
        setKines(response.data.items);

        setPage({
          currentPage: adjustedPage,
          totalPages: response.data.totalPages,
        });
      })
      .catch((error) => {
        setIsLoading(false);
        console.error('Error fetching Medical records:', error);
      });
  };

  const [selectedCategory, setSelectedCategory] = useState('firstName');
  const [originalData, setOriginalData] = useState([]);
  const handleSearch = (searchQuery: string, searchCategory: string) => {
    const filteredData = originalData.filter((kine: any) => {
      const fieldToSearch =
        searchCategory === 'address' ? (kine && kine.address || '').toString().toLowerCase() :
        searchCategory === 'mobile' ? (kine && kine.mobile || '').toString().toLowerCase() :
        searchCategory === 'firstName' ? (kine && kine.firstName || '').toString().toLowerCase() :
          '';

      return fieldToSearch.includes(searchQuery?.toLowerCase());
    });

    setKines(filteredData);
  };

  const columns = [
    columnHelper.accessor("firstName", {
      id: "firstName",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "20px" }}
          color='blue.500'
        >
          Nom Complet
        </Text>
      ),
    }),
    columnHelper.accessor("address", {
      id: "address",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "20px" }}
          color='blue.500'
        >
          Mail
        </Text>
      ),
    }),
    columnHelper.accessor("mobile", {
      id: "mobile",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "20px" }}
          color='blue.500'
        >
          Mobile
        </Text>
      ),
    }),
    columnHelper.accessor("actions", {
      id: "actions",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "20px" }}
          color='blue.500'
        >
          Actions
        </Text>
      ),
      cell: (info: any) => (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginLeft="10%"
        >
          <Menu>
            <MenuButton
              as={Button}
              fontSize={{ sm: '10px', lg: '16px' }}
              colorScheme="gray"
            >
              <HamburgerIcon />
            </MenuButton>
            <MenuList>
              <MenuItem fontSize={{ sm: '10px', lg: '16px' }} onClick={() => handleEdit(info.row.original._id)}>
                <EditIcon mr={2} />
                Modifier
              </MenuItem>
              <MenuItem
                fontSize={{ sm: '10px', lg: '16px' }}
                onClick={() => onDelete(info.row.original._id)}
              >
                <DeleteIcon mr={2} />
                Supprimer
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      ),
    }),
  ];

  const table = useReactTable({
    data: kines,
    columns: columns as any,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <>
      <Box w="100%" px="0px" overflowX={{ sm: "scroll", lg: "hidden" }} display="flex"
        maxWidth="100%"
        overflow="auto"
        borderWidth="1px"
        boxShadow="5px 14px 10px 0px rgba(128, 128, 128, 0.5)"
        padding="4"
        borderRadius="xl"
        flexDirection="column"
        bg={"white"}>
        {isLoading ? (
          <Flex justifyContent="center" align="center" height="200px" fontSize="20px">
            <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" /> Loading
          </Flex>
        ) : (
          <>
            <Flex justifyContent="space-between" alignItems="center" mb="4">
              <Text
                color={textColor}
                fontSize="24px"
                mb="4px"
                fontWeight="700"
                lineHeight="100%"
              >
                Liste des kinés
              </Text>
              <SearchBarKine onSearch={handleSearch} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} />
              <Button colorScheme="blue" size="lg" onClick={() => setShowAddModal(true)}>
                Ajouter Kiné
              </Button>
              <KineAddModal isOpen={showAddModal} onClose={() => setShowAddModal(false)} onAddKine={handleAddKine} />
            </Flex>
            <Table variant="simple" color="gray.500" mb="24px" mt="12px">
              <Thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <Tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <Th
                        key={header.id}
                        colSpan={header.colSpan}
                        pe="10px"
                        borderColor={borderColor}
                        cursor="pointer"
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        <Flex
                          justifyContent="space-between"
                          align="center"
                          fontSize={{ sm: '10px', lg: '20px' }}
                          color="gray.400"
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {{
                            asc: "",
                            desc: "",
                          }[header.column.getIsSorted() as string] ?? null}
                        </Flex>
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>
              <Tbody>
                {table.getRowModel().rows?.map((row, rowIndex) => (
                  <Tr key={row?.id}>
                    {row?.getVisibleCells().map((cell) => (
                      <Td
                        key={cell.id}
                        fontSize={{ sm: '20px', lg: '20px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        borderBottomWidth="1px"
                        backgroundColor={row.original.color} // Use the color property
                        borderStyle="solid"
                        fontWeight="bold"
                        color={textColor}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </Td>
                    ))}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </>
        )}

        <Flex justify="center" mt="4">
          <Box>
            <Pagination currentPage={page.currentPage} totalPages={page.totalPages} onPageChange={handlePageChange} />
          </Box>
        </Flex>
      </Box>
      <KineEditModal
        isOpen={showEditModal}
        onClose={() => {
          setShowEditModal(false);
          setSelectedKineId(null);
        }}
        kineId={selectedKineId || ''}
        onEditKine={() => {
          fetchKines();
        }}
      />
    </>
  );
};

export default KineTable;
