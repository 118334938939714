import React, { useState } from 'react';
import {
  Box,
  Flex,
  Text,
  Icon,
  useColorModeValue,
  Input,
  InputGroup,
  InputLeftElement,
  Tooltip,
  Button,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import Card from 'components/card/Card';
import { MdDragIndicator } from 'react-icons/md';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

interface Patient {
  _id: string;
  fullName: string;
  phoneNumber: string;
  isInWaitingList: boolean;
}

interface TasksProps {
  patientsToCall: Patient[];
  handleIconClick: (patient: Patient) => void; // Add handleIconClick function to props
}

const ListeAtt: React.FC<TasksProps> = ({ patientsToCall, handleIconClick }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const itemsPerPage = 6;
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  // Filter patients based on search term
  const filteredPatients = patientsToCall.filter(
    (patient) =>
      patient.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      patient.phoneNumber.includes(searchTerm)
  );

  // Calculate pagination
  const totalPages = Math.ceil(filteredPatients.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Slice data for the current page
  const currentPatients = filteredPatients.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset page when the search term changes
  };

  return (
    <Card p='20px' alignItems='center' flexDirection='column' w='100%' minHeight='70vh'>
      <Flex alignItems='center' w='100%' mb='30px'>
        <Text color={textColor} fontSize='lg' fontWeight='700'>
          Patient en liste d'attente
        </Text>
        <Flex align='center' ml='auto'>
          <Tooltip label="Previous Page" fontSize="sm">
            <Button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              variant='outline'
              mr='2'
            >
              <FaArrowLeft />
            </Button>
          </Tooltip>
          <Text fontSize='md' mx='2'>
            {currentPage} / {totalPages}
          </Text>
          <Tooltip label="Next Page" fontSize="sm">
            <Button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              variant='outline'
            >
              <FaArrowRight />
            </Button>
          </Tooltip>
        </Flex>
      </Flex>
      <Box px='11px' w='100%' h='100%'>
        <InputGroup mb='4'>
          <InputLeftElement pointerEvents='none'>
            <SearchIcon color='gray.300' />
          </InputLeftElement>
          <Input
            type='text'
            placeholder='Recherche par nom ou numéro'
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </InputGroup>
        {currentPatients.map((patient) => (
          <Flex key={patient._id} w='100%' justify='space-between' mb='20px' align='center'>
            <Text fontWeight='bold' color={textColor} fontSize='md' textAlign='start'>
              {patient.fullName} - {patient.phoneNumber}
            </Text>
            <Icon
              as={MdDragIndicator}
              color='secondaryGray.600'
              w='24px'
              h='24px'
              onClick={() => handleIconClick(patient)}
              cursor='pointer'
            />
          </Flex>
        ))}
      </Box>
    </Card>
  );
};

export default ListeAtt;
