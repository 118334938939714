import { Box, Flex, Icon, SimpleGrid, Spinner, useColorModeValue } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { MdAddTask, MdAttachMoney, MdBarChart, MdFileCopy, MdPerson } from 'react-icons/md';

import appclient from 'views/admin/appclient';
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import ComplexTable from 'views/admin/default/components/ComplexTable';
import Tasks from 'views/admin/default/components/Tasks';
import AbsentTable from './components/absencetable';
import ListeAtt from './components/listeAtt';

export default function UserReports() {
    const brandColor = useColorModeValue('brand.500', 'white');
    const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
    const [statsData, setStatsData] = useState(null);

    const fetchData = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await appclient.get('api/stats/', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.data;
            setStatsData(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleIconClick = async (patient: { _id: any }) => {
        try {
            const token = localStorage.getItem('token');
            await appclient.patch('/api/patient', { _id: patient._id, isInWaitingList: false }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            alert('Patient supprimé de la liste d\'attente');
            fetchData();
        } catch (error) {
            console.error('Erreur suppression patient', error);
        }
    };

    if (!statsData) {
        return (
            <Flex justifyContent="center" alignItems="center" height="100vh">
                <Spinner color="blue.500" size="xl" />
            </Flex>
        );
    }

    return (
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4} mb='20px'>
                <MiniStatistics
                    startContent={
                        <IconBox
                            w='56px'
                            h='56px'
                            bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
                            icon={<Icon w='28px' h='28px' as={MdAddTask} color='white' />}
                        />
                    }
                    name='Nombre des séances aujourd’hui'
                    value={statsData.nbrOfVisitsToday}
                />
                <MiniStatistics
                    startContent={
                        <IconBox
                            w='56px'
                            h='56px'
                            bg={boxBg}
                            icon={<Icon w='32px' h='32px' as={MdFileCopy} color={brandColor} />}
                        />
                    }
                    name='Nombre des séances'
                    value={statsData.totalMedicalCareSessions}
                />
                <MiniStatistics
                    startContent={
                        <IconBox
                            w='56px'
                            h='56px'
                            bg={boxBg}
                            icon={<Icon w='32px' h='32px' as={MdPerson} color={brandColor} />}
                        />
                    }
                    name='Patient en attente'
                    value={statsData.numberOfPatientsInWaitingList}
                />
            </SimpleGrid>

           

          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
    {statsData?.unpaidPatientsList && (
        <Box p={4} bg={boxBg} borderRadius="md" boxShadow="md">
            <ComplexTable 
                tableData={statsData.unpaidPatientsList} 
                displayDataset="patientnonpayes" 
            />
        </Box>
    )}
    {statsData?.finishedPatientsList && (
        <Box p={4} bg={boxBg} borderRadius="md" boxShadow="md">
            <ComplexTable 
                tableData={statsData.finishedPatientsList} 
                displayDataset="patientnonpayesencours" 
            />
        </Box>
    )}
    {statsData?.patientsWithSCList && (
        <Box p={4} bg={boxBg} borderRadius="md" boxShadow="md">
            <ComplexTable 
                tableData={statsData.patientsWithSCList} 
                displayDataset="patientnonpayesterminé" 
            />
        </Box>
    )}
</SimpleGrid>


            <Flex direction={{ base: 'column', md: 'row' }} gap={4} mt={8}>
                <Box flex="1" p={4} bg={boxBg} borderRadius="md" boxShadow="md">
                    <Tasks patientsToCall={statsData?.patientsWithNoPEC} />
                </Box>
                {statsData?.patientsWithNoPresence && (
                    <Box flex="1" p={4} bg={boxBg} borderRadius="md" boxShadow="md">
                        <AbsentTable tableData={statsData.patientsWithNoPresence} />
                    </Box>
                )}
            </Flex>
			<Flex direction={{ base: 'column', md: 'row' }} gap={4} mb={6}>
                <Box flex="1" p={4} bg={boxBg} borderRadius="md" boxShadow="md">
                    <ListeAtt patientsToCall={statsData.patientsInWaitingList} handleIconClick={handleIconClick} />
                </Box>
            </Flex>
        </Box>
    );
}
