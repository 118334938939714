import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Button,
  Spinner, // Import Spinner component from Chakra UI
  useToast,
} from '@chakra-ui/react';
import apiClient from 'views/admin/appclient';

interface KineEditModalProps {
  isOpen: boolean;
  onClose: () => void;
  kineId: string; // Pass the kineId to identify the record to edit
  onEditKine: () => void; // Callback function to fetch updated data after editing
}

const KineEditModal: React.FC<KineEditModalProps> = ({ isOpen, onClose, kineId, onEditKine }) => {
  const [loading, setLoading] = useState(true); 
  const [firstName, setFirstName] = useState('');
  const [address, setAddress] = useState('');
  const [mobile, setMobile] = useState('');
  const [color, setColor] = useState('#ff0000'); // Default color

  const toast = useToast();

  const fetchKineDetails = async () => {
    try {
      const accessToken = localStorage.getItem('token');

      if (!accessToken) {
        console.error('Access token not found in local storage');
        return;
      }

      const response = await apiClient.get(`/api/kine/${kineId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const { firstName, address, mobile, color } = response.data;
      setFirstName(firstName);
      setAddress(address);
      setMobile(mobile);
      setColor(color || '#ff0000'); // Set the color or default to red
    } catch (error) {
      toast({
        title: 'Erreur de chargement des détails du Kiné',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false); // Set loading to false when done fetching
    }
  };

  useEffect(() => {
    if (isOpen) {
      setLoading(true); // Set loading to true when modal is opened
      fetchKineDetails();
    }
  }, [isOpen, kineId]);

  const handleEditKine = async () => {
    try {
      const accessToken = localStorage.getItem('token');

      if (!accessToken) {
        console.error('Access token not found in local storage');
        return;
      }

      const response = await apiClient.patch(
        `/api/kine/${kineId}`,
        {
          firstName,
          address,
          mobile,
          color, // Send the color to the endpoint
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        toast({
          title: 'Kiné modifié avec succès',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });

        // Close the modal and trigger a fetch of updated data
        onClose();
        onEditKine();
      } else {
        console.error('Error editing Kine. Status:', response.status);
      }
    } catch (error) {
      console.error('Error editing Kine:', error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Modifier Kiné</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {loading ? (
            <Spinner size="lg" color="blue.500" />
          ) : (
            <>
              <FormControl mb="4">
                <FormLabel>Nom Complet</FormLabel>
                <Input value={firstName} onChange={(e) => setFirstName(e.target.value)} />
              </FormControl>
              <FormControl mb="4">
                <FormLabel>Mail</FormLabel>
                <Input value={address} onChange={(e) => setAddress(e.target.value)} />
              </FormControl>
              <FormControl mb="4">
                <FormLabel>Mobile</FormLabel>
                <Input value={mobile} onChange={(e) => setMobile(e.target.value)} />
              </FormControl>
              <FormControl mb="4">
                <FormLabel>Couleur</FormLabel>
                <Input
                  type="color"
                  value={color}
                  onChange={(e) => setColor(e.target.value)}
                />
              </FormControl>
            </>
          )}
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" onClick={handleEditKine}>
            Enregistrer
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Annuler
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default KineEditModal;
