    import React, { useState, useEffect } from 'react';
    import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    FormControl,
    FormLabel,
    Input,
    useToast,
    Textarea,
    } from '@chakra-ui/react';
    import apiClient from 'views/admin/appclient';
    import Select from 'react-select';
    import CreatableSelect from 'react-select/creatable';

    interface AddArrousaModalProps {
    isOpen: boolean;
    onClose: () => void;
    onAddArrousa: () => void;
    }
    interface Client {
        _id: string;
        fullName: string;
      }
      interface ServiceDetail {
        _id?: string;
        nom_service: string;
        type: string;
        startDate: string;
        duration: number;
        prix: number;
      }
    const AddArrousaRdvModal: React.FC<AddArrousaModalProps> = ({ isOpen, onClose, onAddArrousa }) => {
    const [deposit, setDeposit] = useState('');
    const [depositNumber, setDepositNumber] = useState('');

    const [arrousa, setArrousa] = useState([]);
    const [color, setColor] = useState('#FF6347');
    const toast = useToast();
    const now = new Date().toISOString().slice(0, 16);
    const [arrousaId , setArrousaId] = useState("");
    const [selectedPrix, setSelectedPrix] = useState('');
    const [clients, setClients] = useState<Client[]>([]);
    const [clientId, setClientId] = useState('');
const [arrousaService , setArrousaService] = useState<ServiceDetail[]>([]);
const [comment  , setComment]= useState('');
 

    useEffect(() => {
        const fetchArrousaOptions = async () => {
            try {
                const accessToken = localStorage.getItem('token');
                if (!accessToken) {
                    throw new Error('Access token not found in local storage');
                }
        
                const response = await apiClient.get('/api/arrousa', {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
        
                const arrousaData: any[] = response.data;
        
                // Mapping arrousa data for Select component options
                const options = arrousaData.map(arrousa => ({
                    value: arrousa._id,
                    label: arrousa.name,
                    prix: arrousa.price, 
                    services : arrousa.services
                }));
        
          
                setArrousa(options);
            } catch (error) {
                console.error('Error fetching arrousa options:', error);
            }
        };
        
        const fetchClientsAndServices = async () => {
            try {
              const accessToken = localStorage.getItem('token');
              if (!accessToken) {
                throw new Error('Access token not found in local storage');
              }
      
              const responses = await Promise.all([
                apiClient.get('/api/client', {
                  headers: {
                    Authorization: `Bearer ${accessToken}`,
                  },
                }),
               
              ]);
      
              const clientsData: Client[] = responses[0].data;
              setClients(clientsData);
            } catch (error) {
              console.error('Error fetching clients and services:', error);
            }
          };
        if (isOpen) {
        fetchArrousaOptions();
        fetchClientsAndServices();
        }
    }, [isOpen]);

    const handleAddArrousa = async () => {
      const accessToken = localStorage.getItem('token');
    
      if (!accessToken) {
        console.error('Access token not found in local storage');
        return;
      }
    
      // Check if user selected either Pack or Services
      if (!arrousaId && arrousaService.length === 0) {
        toast({
          title: 'Veuillez sélectionner un pack  !',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }
    
      // Check if client is selected
      if (!clientId) {
        toast({
          title: 'Le client est requis !',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }
    
      // Validate service startDate and duration
      const invalidService = arrousaService.find(service => !service.startDate || !service.duration || service.duration <= 0);
      if (invalidService) {
        toast({
          title: 'Tous les services doivent avoir une date de début  !',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }
    
      let dataToSend: any = {};
    
      if (arrousaId) {
        dataToSend = {
          nom_service: "Arrousa",
          deposit,
          numDeposit: depositNumber,
          color: color || "#FF6347",
          arrousa: arrousaId,
          prix: selectedPrix,
          services: arrousaService,
          comment,
          type: "arrousa",
          client: clientId,
        };
      } else if (arrousaService.length > 0) {
        dataToSend = {
          nom_service: "Arrousa",
          deposit,
          numDeposit: depositNumber,
          color: color || "#FF6347",
          services: arrousaService,
          comment,
          type: "arrousa",
          client: clientId,
        };
      } else {
        toast({
          title: 'Sélection invalide !',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }
    
      try {
        const response = await apiClient.post(
          '/api/spa',
          dataToSend,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (response.status === 201) {
          onAddArrousa();
          resetForm();
          onClose();
          toast({
            title: 'Arrousa ajouté avec succès',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        } else {
          console.error('Error adding arrousa. Status:', response.status);
        }
      } catch (error) {
        console.error('Error adding arrousa:', error);
      }
    };
  
    const clientOptions = clients.map(client => ({
        value: client._id,
        label: client.fullName,
      }));
      const addNewClient = async (fullName: string) => {
        const accessToken = localStorage.getItem('token');
        
        if (!accessToken) {
          console.error('Access token not found in local storage');
          toast({
            title: 'Access token is missing!',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
          return;
        }
      
        try {
          const response = await apiClient.post(
            '/api/client',
            { fullName },
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
      
          if (response.status === 201) {
            const newClient = response.data;
            setClients(prevClients => [...prevClients, newClient]);
            setClientId(newClient._id);
            toast({
              title: 'New client added successfully',
              status: 'success',
              duration: 3000,
              isClosable: true,
            });
          } else {
            console.error('Error adding new client. Status:', response.status);
            toast({
              title: 'Error adding client',
              status: 'error',
              duration: 3000,
              isClosable: true,
            });
          }
        } catch (error) {
          console.error('Error adding new client:', error);
          toast({
            title: 'Error adding client',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
      };
      
    const resetForm = () => {
        setDeposit('');
        setDepositNumber('');
  setArrousaService([]);
        setColor('#FF6347');
        setArrousaId('');
        setSelectedPrix('');
    };

    const handleClose = () => {
        resetForm();
        onClose();
    };

    const handleArrousaChange = (option: any) => {
        setArrousaId(option?.value || '');
        setSelectedPrix(option?.prix || '');
    
        // Set arrousa service to the services of the selected arrousa
        const selectedArrousa = arrousa.find(arrousa => arrousa.value === option.value);
        if (selectedArrousa) {
            setArrousaService(selectedArrousa.services || []);
        } else {
            setArrousaService([]); // Clear arrousa service if no option is selected
        }
    };
    
    const arrousaOptions = arrousa.map(option => ({
        value: option.value,
        label: option.label,
        prix: option.prix
    }));
    const groupBy = <T, K extends keyof T>(array: T[], key: K) => {
        return array.reduce<Record<string, T[]>>((result, currentValue) => {
          const groupKey = String(currentValue[key]);
          if (!result[groupKey]) {
            result[groupKey] = [];
          }
          result[groupKey].push(currentValue);
          return result;
        }, {});
      };
      const handleServiceDetailsChange = (type: string, field: string, value: string | number) => {
        setArrousaService(prevDetails => {
          return prevDetails.map(detail => {
            if (detail.type === type) {
              return {
                ...detail,
                [field]: value
              };
            }
            return detail;
          });
        });
      };
    return (
        <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>Ajouter un nouveau arrousa</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
            <FormControl mt={4}>
                <FormLabel>Sélectionner Pack Arrousa</FormLabel>
                <Select
                options={arrousaOptions}
                value={arrousaOptions.find(option => option.value === arrousaId)}
                onChange={handleArrousaChange}
                />
            </FormControl>
            <FormControl mt={4}>
          <FormLabel>Client</FormLabel>
          <CreatableSelect
            options={clientOptions}
            value={clientOptions.find(option => option.value === clientId)}
            onChange={option => setClientId(option?.value || '')}
            onCreateOption={label => addNewClient(label)}
            placeholder="Saisissez un nom de client"
            noOptionsMessage={() => "Aucune option disponible"}
            formatCreateLabel={inputValue => `Créer nouveau client : "${inputValue}"`}
          />
                </FormControl>
            <FormControl mt={4}>
                <FormLabel>Avance</FormLabel>
                <Input type="number" value={deposit} onChange={e => setDeposit(e.target.value)} />
            </FormControl>
            <FormControl mt={4}>
                <FormLabel>Numéro récu</FormLabel>
                <Input type="text" value={depositNumber} onChange={e => setDepositNumber(e.target.value)} />
            </FormControl>
            <FormControl mt={4}>
                <FormLabel>Prix de pack</FormLabel>
                <Input type="text" value={selectedPrix} isReadOnly />
            </FormControl>
            
            <FormControl mb={4}>
                <FormLabel>Couleur</FormLabel>
                <Input
                type="color"
                name="color"
                value={color}
                onChange={e => setColor(e.target.value)}
                />
            </FormControl>
            {Object.entries(groupBy(arrousaService, 'type')).map(([type, services], index) => (
      <div key={type}>
        <FormLabel mt={4}>{type}</FormLabel>
        {services.length > 0 && (
          <div>
            <FormControl mt={4}>
              <FormLabel>Date de début</FormLabel>
              <Input
                type="datetime-local"
                value={services[0].startDate} // Use the startDate of the first service in the group
                onChange={e => handleServiceDetailsChange(type, 'startDate', e.target.value)}
              />
            </FormControl>
            <FormControl mt={4}>
  <FormLabel>Durée (minutes)</FormLabel>
  <Input
    type="number"
    value={services[0].duration || ''}
    onChange={e => {
      const value = parseInt(e.target.value);
      if (value <= 0) {
        e.target.style.borderColor = 'red';
      } else {
        e.target.style.borderColor = '';
        handleServiceDetailsChange(type, 'duration', value);
      }
    }}
    onBlur={e => {
      if (e.target.value.trim() === '') {
        e.target.style.borderColor = 'red';
      } else {
        e.target.style.borderColor = '';
      }
    }}
    onWheel={e => e.preventDefault()}
    isRequired
  />
</FormControl>
          </div>
        )}
      </div>
    ))}
            <FormControl mt={4}>
                <FormLabel>Commentaire</FormLabel>
                <Textarea name='comment' value={comment} onChange={e => setComment(e.target.value)}  />
            </FormControl>
      
            </ModalBody>
            <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleAddArrousa}>
                Ajouter Arrousa
            </Button>

            </ModalFooter>
        </ModalContent>
        </Modal>
    );
    };

    export default AddArrousaRdvModal;
