import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Select,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import apiClient from 'views/admin/appclient';

type EditServiceProps = {
  isOpen: boolean;
  onClose: () => void;
  onEditService: () => void;
  serviceId: string; // Assuming serviceId is a string, adjust as needed
};

const EditService: React.FC<EditServiceProps> = ({ isOpen, onClose, onEditService, serviceId }) => {
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    nom_service: '',
    prix: '',
    duration: '',
    type: '',
  });
  const [types, setTypes] = useState<string[]>([]);
  const [newType, setNewType] = useState('');
  const toast = useToast();

  const closeEditModal = () => {
    setFormData({
      nom_service: '',
      prix: '',
      duration: '',
      type: '',
    });
    onClose();
  };

  const fetchServiceDetails = async () => {
    const apiUrl = `/api/service/${serviceId}`;

    try {
      const token = localStorage.getItem('token');
      const response = await apiClient.get(apiUrl, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
      });

      if (response && response.status === 200) {
        const serviceDetails = response.data;
        setFormData({
          nom_service: serviceDetails.nom_service,
          prix: serviceDetails.prix,
          duration: serviceDetails.duration,
          type: serviceDetails.type,
        });
      } else {
        console.error(`Failed to fetch service details. Status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error fetching service details:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTypes = async () => {
    try {
      const response = await apiClient.get('/api/type',    {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setTypes(response.data.map((typeObj: { value: string }) => typeObj.value));
    } catch (error) {
      console.error('Error fetching types:', error);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setLoading(true);
      fetchServiceDetails();
      fetchTypes();
    }
  }, [isOpen, serviceId]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEdit = async () => {
    const apiUrl = `/api/service/${serviceId}`;

    try {
      const token = localStorage.getItem('token');
      const response = await apiClient.patch(apiUrl, formData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });

      if (response && response.status === 200) {
        onEditService();
        onClose();
        toast({
          title: 'Service modifié avec succès',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Service non modifié',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      console.error('Error updating service:', error);
    }
  };

  const handleAddType = async () => {
    if (!newType) return;
const token = localStorage.getItem('token');
    try {
      const response = await apiClient.post(
        '/api/type',
        { value: newType },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 201) {
        setTypes([...types, newType]);
        setNewType('');
      } else {
        console.error('Error adding new type. Status:', response.status);
      }
    } catch (error) {
      console.error('Error adding new type:', error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Modifier Service</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {loading ? (
            <Spinner size="lg" color="blue.500" />
          ) : (
            <>
              <FormControl>
                <FormLabel>Nom du Service</FormLabel>
                <Input
                  type="text"
                  name="nom_service"
                  value={formData.nom_service}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Prix</FormLabel>
                <Input
                  type="number"
                  name="prix"
                  value={formData.prix}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Durée</FormLabel>
                <Input
                  type="text"
                  name="duration"
                  value={formData.duration}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Type</FormLabel>
                <Select
                  name="type"
                  value={formData.type}
                  onChange={handleChange}
                >
                  <option value="">Sélectionnez le type</option>
                  {types.map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </Select>
              </FormControl>
           
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleEdit}>
            Modifier
          </Button>
          <Button colorScheme="gray" onClick={closeEditModal}>
            Annuler
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditService;
