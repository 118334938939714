import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Spinner,
  Flex,
  Button,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useToast,
} from "@chakra-ui/react";
import { createColumnHelper, flexRender, getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from '@tanstack/react-table';
import { DeleteIcon, EditIcon, HamburgerIcon } from '@chakra-ui/icons';
import apiClient from 'views/admin/appclient';
import ClientAddModal from '../addspa/addcient';
import EditClient from '../editspa/editclient';

type Client = {
  _id: string;
  fullName: string;
  facebook?: string;
  instagram?: string;
  email: string;
  phoneNumber: string;
  paymentStatus?: boolean;
  actions: JSX.Element;
};

const ClientTable: React.FC = () => {
  const [clients, setClients] = useState<Client[]>([]);
  const [filteredClients, setFilteredClients] = useState<Client[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const columnHelper = createColumnHelper<Client>();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [originalData, setOriginalData] = useState<Client[]>([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState<string>('');

  useEffect(() => {
    fetchClients();
  }, []);

  useEffect(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setFilteredClients(originalData.slice(startIndex, endIndex));
    setTotalPages(Math.ceil(originalData.length / itemsPerPage));
  }, [originalData, currentPage, itemsPerPage]);

  const fetchClients = async () => {
    try {
      const accessToken = localStorage.getItem('token');
      if (!accessToken) {
        console.error('Access token not found in local storage');
        return;
      }
      const response = await apiClient.get('api/client', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setClients(response.data);
      setOriginalData(response.data);
    } catch (error) {
      console.error('Error fetching clients:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddClient = () => {
    setIsAddModalOpen(true);
  };

  const closeAddModal = () => {
    setIsAddModalOpen(false);
  };

  const onAddClient = () => {
    fetchClients();
    closeAddModal();
  };

  const handleEditClient = (clientId: string) => {
    setSelectedClientId(clientId);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setSelectedClientId('');
    setIsEditModalOpen(false);
  };

  const toast = useToast();

  const onDelete = async (clientId: string) => {
    const accessToken = localStorage.getItem('token');
    if (!accessToken) {
      console.error('Access token not found in local storage');
      return;
    }

    try {
      const response = await apiClient.delete(`/api/client/${clientId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 200) {
        toast({
          title: "Client supprimé avec succès",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        await fetchClients();
      } else {
        console.error('Error deleting client. Status:', response.status);
      }
    } catch (error) {
      console.error('Error deleting client:', error);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value;
    setSearchTerm(term);
    const lowercasedTerm = term.toLowerCase();
    const filtered = originalData.filter(client =>
      client.fullName.toLowerCase().includes(lowercasedTerm) ||
      client.email.toLowerCase().includes(lowercasedTerm)
    );
    setFilteredClients(filtered);
    setTotalPages(Math.ceil(filtered.length / itemsPerPage));
    setCurrentPage(1); // Reset to first page after search
  };

  const columns = [
    columnHelper.accessor("fullName", {
      id: "fullName",
      header: "Nom",
    }),
    columnHelper.accessor("facebook", {
      id: "facebook",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "20px" }}
          color='blue.500'
        >
          Facebook
        </Text>
      ),
    }),
    columnHelper.accessor("instagram", {
      id: "instagram",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "20px" }}
          color='blue.500'
        >
          Instagram
        </Text>
      ),
    }),
    columnHelper.accessor("email", {
      id: "email",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "20px" }}
          color='blue.500'
        >
          Email
        </Text>
      ),
    }),
    columnHelper.accessor("phoneNumber", {
      id: "phoneNumber",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "20px" }}
          color='blue.500'
        >
          Tél
        </Text>
      ),
    }),
    columnHelper.accessor("paymentStatus", {
      id: "paymentStatus",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "20px" }}
          color='blue.500'
        >
          Status
        </Text>
      ),
      cell: (info: any) => (
        <Text>{info.getValue() ? 'Payé' : 'Non payé'}</Text>
      ),
    }),
    columnHelper.accessor("actions", {
      id: "actions",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "20px" }}
          color='blue.500'
        >
          Actions
        </Text>
      ),
      cell: (info: any) => (
        <Box display="flex" alignItems="center" marginLeft="10%">
          <Menu>
            <MenuButton as={Button} fontSize="16px" colorScheme="gray">
              <HamburgerIcon />
            </MenuButton>
            <MenuList>
              <MenuItem fontSize="16px" onClick={() => handleEditClient(info.row.original._id)}>
                <EditIcon mr={2} />
                Modifier
              </MenuItem>
              <MenuItem fontSize="16px" onClick={() => onDelete(info.row.original._id)}>
                <DeleteIcon mr={2} />
                Supprimer
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      ),
    }),
  ];

  const table = useReactTable({
    data: filteredClients,
    columns: columns as any,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <Box w="100%" px="0px" overflowX="scroll" display="flex" maxWidth="100%" overflow="auto" borderWidth="1px" boxShadow="5px 14px 10px rgba(128, 128, 128, 0.5)" padding="4" borderRadius="xl" flexDirection="column" bg="white">
      {isLoading ? (
        <Flex justifyContent="center" align="center" height="200px" fontSize="20px">
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" /> Loading
        </Flex>
      ) : (
        <>
          <Flex justifyContent="space-between" alignItems="center" mb="4">
            <Text color={textColor} fontSize="24px" mb="4px" fontWeight="700" lineHeight="100%">
              Liste des Clients
            </Text>
            <Input
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearch}
              width="300px"
              mb="4"
            />
            <Button onClick={handleAddClient} colorScheme="blue" size="lg" mb="4">
              Ajouter client
            </Button>
            <ClientAddModal isOpen={isAddModalOpen} onClose={closeAddModal} onAddClient={onAddClient} />
          </Flex>
          <Table variant="simple" color="gray.500" mb="24px" mt="12px">
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Th key={header.id} colSpan={header.colSpan} pe="10px" borderColor={borderColor} cursor="pointer" onClick={header.column.getToggleSortingHandler()}>
                      <Flex justifyContent="space-between" align="center" fontSize="20px" color="gray.400">
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted() as string] ?? null}
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table.getRowModel().rows?.map((row, rowIndex) => (
                <Tr key={row?.id}>
                  {row?.getVisibleCells().map((cell) => (
                    <Td key={cell.id} fontSize="20px" minW="150px" borderBottomWidth="1px" backgroundColor={`rgba(127, 204, 252, ${rowIndex % 2 === 0 ? 0 : 0.1})`} borderStyle="solid" fontWeight="bold" color={textColor}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Flex justifyContent="space-between" alignItems="center" mt="4">
            <Button
              onClick={() => setCurrentPage(page => Math.max(page - 1, 1))}
              disabled={currentPage === 1}
              colorScheme="blue"

            >
              Précédent
            </Button>
            <Text fontSize="16px" color={textColor}>
              Page {currentPage} sur {totalPages}
            </Text>
            <Button
              onClick={() => setCurrentPage(page => Math.min(page + 1, totalPages))}
              disabled={currentPage === totalPages}
              colorScheme="blue"

            >
              Suivant
            </Button>
          </Flex>
        </>
      )}
      <EditClient
        isOpen={isEditModalOpen}
        onClose={closeEditModal}
        onEditClient={fetchClients}
        clientId={selectedClientId || ''} 
      />
    </Box>
  );
};

export default ClientTable;
