import { SearchIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Icon, Input, InputGroup, InputLeftElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Progress, Table, Tbody, Td, Text, Th, Thead, Tooltip, Tr, useColorModeValue } from '@chakra-ui/react';
import {
	createColumnHelper,
	flexRender,
	getCoreRowModel,
	getSortedRowModel,
	SortingState,
	useReactTable
} from '@tanstack/react-table';
// Custom components
import Card from 'components/card/Card';
import Menu from 'components/menu/MainMenu';
import * as React from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
// Assets
import { MdCancel, MdCheckCircle, MdOutlineError } from 'react-icons/md';



type RowObj = {
	fullName: string;
	status: string;
	schedule: any; 

};

const columnHelper = createColumnHelper<RowObj>();

// const columns = columnsDataCheck;
export default function AbsentTable(props: { tableData: any }) {
	const { tableData   } = props;
	const [ sorting, setSorting ] = React.useState<SortingState>([]);
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
	const [filterValue, setFilterValue] = React.useState('');
	const [currentPage, setCurrentPage] = React.useState(1);
	const itemsPerPage = 5;
  
	const defaultData = tableData;

    const [selectedDate, setSelectedDate] = React.useState<string | null>(null);

	const columns = [
		columnHelper.accessor('fullName', {
			id: 'fullName',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					NAME
				</Text>
			),
            cell: (info: any) => (
                <Flex align='center'>
                  <Text color={textColor} fontSize='sm' fontWeight='700'>
                    {info.row.original.patient.fullName}
                  </Text>
                </Flex>
              ),
            }),
		columnHelper.accessor('status', {
			id: 'status',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					STATUS
				</Text>
			),
			cell: (info) => (
			<Flex align='center'>
												<Icon
													w='24px'
													h='24px'
													me='5px'
													color={
														info.getValue() === 'En attente' ? (
															'green.500'
														) : info.getValue() === 'Non payé' ? (
															'red.500'
														) : info.getValue() === 'Error' ? (
															'orange.500'
														) : null
													}
													as={
														info.getValue() === 'Approved' ? (
															MdCheckCircle
														) : info.getValue() === 'Non payé' ? (
															MdCancel
														) : info.getValue() === 'Error' ? (
															MdOutlineError
														) : null
													}
												/>
												<Text color={textColor} fontSize='sm' fontWeight='700'>
													{info.getValue()}
												</Text>
											</Flex> 
			)
		}),
        columnHelper.accessor('schedule', {
            id: 'schedule',
            header: () => (
              <Text
                justifyContent='space-between'
                align='center'
                fontSize={{ sm: '10px', lg: '12px' }}
                color='gray.400'>
                SCHEDULE
              </Text>
            ),
            cell: (info) => (
                <>
                  {info.row.original.schedule.length > 0 && (
                    <Button
                      fontSize={{ sm: '10px', lg: '14px' }}
                      color={textColor}
                      onClick={() => {
                        setSelectedDate(info.row.original.schedule[0].daysOfWeek[0]); // Set the selected date
                      }}
                    >
                      {info.row.original.schedule[0].daysOfWeek[0]}
                    </Button>
                  )}
                </>
              ),
              
              
          }),
        ];
	const [data, setData] = React.useState(() => [...defaultData]);
    const patientName = defaultData.length > 0 ? defaultData[0].patient.fullName : ''; // Extract patient name
	const [filteredData, setFilteredData] = React.useState(defaultData);
	React.useEffect(() => {
		console.log(defaultData)
		// Filter the data based on the filter value
		const filteredData = defaultData.filter((row: any) =>
		  row.patient?.fullName?.toLowerCase().includes(filterValue.toLowerCase())
		);
	
		setFilteredData(filteredData);
	
		// Apply pagination to the filtered data
		const startIndex = (currentPage - 1) * itemsPerPage;
		const endIndex = startIndex + itemsPerPage;
		const paginatedData = filteredData.slice(startIndex, endIndex);
	
		setData(paginatedData);
	  }, [defaultData, filterValue, currentPage]);
	  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
	  const handlePageChange = (newPage: number) => {
		setCurrentPage(newPage);
	  };
	
	const table = useReactTable({
		data,
		columns,
		state: {
		  sorting,
		},
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		debugTable: true,
	  });
 
      const DateModal: React.FC<{ isOpen: boolean; onClose: () => void; dates: string[]; patientName: string }> = ({ isOpen, onClose, dates, patientName }) => {
        return (
          <Modal isOpen={isOpen} onClose={onClose} size="md">
            <ModalOverlay />
            <ModalContent overflowY="auto">
              <ModalHeader> Liste d'absences pour {patientName}</ModalHeader>
              <ModalCloseButton />
              <ModalBody maxHeight="300px" overflowY="auto">
                <Text fontWeight="bold" mb={2}>
                  Dates d'absence :
                </Text>
                <ul>
                  {dates.map((date, index) => (
                    <li key={index}>
                      <Text>{date}</Text>
                    </li>
                  ))}
                </ul>
              </ModalBody>
            </ModalContent>
          </Modal>
        );
      };
      
      
      
      
      
	return (
		<Card flexDirection='column' w='100%' px='0px' overflowX={{ sm: 'scroll', lg: 'hidden' }} height='100%' minHeight='70vh'> 
			<Flex px='25px' mb="8px" justifyContent='space-between' align='center'>
				<Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%'>
		Liste d'absence
				</Text>
				<Flex justify='center'>
				<Text as="span" display="inline-block" _hover={{ cursor: 'pointer' , color: 'blue.500' }} ml="2" position="relative">
    
        <Tooltip label="Précedent" fontSize="sm">
          <Button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            mr='2'
          >
            <FaArrowLeft />
          </Button>
          </Tooltip>
		  </Text>
          <Text marginTop={"5%"}>{currentPage} / {totalPages}</Text>
          <Text as="span" display="inline-block" _hover={{ cursor: 'pointer' , color: 'blue.500' }} ml="2" position="relative">
          <Tooltip label="Suivant" fontSize="sm">
            <Button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <FaArrowRight />
            </Button>
          </Tooltip>

          </Text>
        </Flex>
			</Flex>
			{tableData.length > 0 ? (
			<Box >
				        <InputGroup mb="4">
            <InputLeftElement pointerEvents="none">
              <SearchIcon color="gray.300" />
            </InputLeftElement>
            <Input
              type="text"
              placeholder="Recherche par nom"
              value={filterValue}
              onChange={(e) => setFilterValue(e.target.value)}
            />
          </InputGroup>
				<Table variant='simple' color='gray.500' mb='24px' mt="12px">
					<Thead>
						{table.getHeaderGroups().map((headerGroup) => (
							<Tr key={headerGroup.id}>
								{headerGroup.headers.map((header) => {
									return (
										<Th
											key={header.id}
											colSpan={header.colSpan}
											pe='10px' 
											borderColor={borderColor}
											cursor='pointer'
											onClick={header.column.getToggleSortingHandler()}>
											<Flex
												justifyContent='space-between'
												align='center'
												fontSize={{ sm: '10px', lg: '12px' }}
												color='gray.400'>
												{flexRender(header.column.columnDef.header, header.getContext())}{{
													asc: '',
													desc: '',
												}[header.column.getIsSorted() as string] ?? null}
											</Flex>
										</Th>
									);
								})}
							</Tr>
						))}
					</Thead>
					<Tbody>
						{table.getRowModel().rows.slice(0, 5).map((row) => {
							return (
								<Tr key={row.id}>
									{row.getVisibleCells().map((cell) => {
										return (
											<Td
												key={cell.id}
												fontSize={{ sm: '14px' }}
												minW={{ sm: '150px', md: '200px', lg: 'auto' }}
												borderColor='transparent'>
												{flexRender(cell.column.columnDef.cell, cell.getContext())}
											</Td>
										);
									})}
								</Tr>
							);
						})}
					</Tbody>
				</Table>
			
			</Box> 
			 ) : (
        <Box p="4" borderWidth="1px" borderRadius="lg" borderColor="gray.200" mt="4">
          <Text color={textColor} fontSize='16px' fontWeight='500'>
          Pas de patient dans cette liste.
          </Text>

        </Box>
      )}
      <DateModal
        isOpen={!!selectedDate}
        onClose={() => setSelectedDate(null)}
        dates={(tableData[0]?.schedule || []).map((visit: any) => visit.daysOfWeek[0])}
        patientName={patientName} // Pass patient name to DateModal
      />

		</Card>
	);
    
}
 