/*eslint-disable*/

import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  VStack,
  Radio,
  RadioGroup,
  Grid,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import appclient from 'views/admin/appclient';


type PatientFormProps = {
  onClose: () => void;
  onPatientCreated: () => void;
};

function PatientForm({ onClose , onPatientCreated}: PatientFormProps) {
  const [patientData, setPatientData] = useState({
    fullName: "",
    dob: "",
    adresse : "",
    gender: "M",
    phoneNumber: "",
    email: "",
    job: "",
    socialAssuranceType:"", 
    assuranceNumber:"",
    assuranceKey:"",
    qualite:"",
  });
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});

  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setPatientData({ ...patientData, [name]: value });
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const errors: { [key: string]: string } = {};
    if (!patientData.fullName) {
      errors.fullName = "Le nom complet est requis";
    }

    if (!patientData.phoneNumber) {
      errors.phoneNumber = "Le numéro de téléphone est requis";
    }

  
    if (Object.keys(errors).length > 0) {
      // If there are validation errors, set them and return
      setValidationErrors(errors);
      
      return;
    }
  
    // Clear validation errors if there are none
    setValidationErrors({});
    setIsLoading(true);
  
    // Proceed with the submission
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token is missing. Please log in to get the token.");
      return;
    }
  
    const headers = {
      Authorization: `Bearer ${token}`,
    };
  
    appclient
      .patch("/api/patient", patientData, {
        headers,
      })
      .then((response) => {
        console.log("Patient created successfully:", response.data);
        onPatientCreated();
        onClose();
        toast({
          title: "Patient created successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((error) => {
        console.error("Error creating patient:", error);
      });
  };
  
  
  return (
    <Grid gap={2}>
 <Box   display="flex"
  maxWidth="100%"
  overflow="auto"
  borderWidth="1px"
  boxShadow="5px 14px 10px 0px  rgba(128, 128, 128, 0.5)"  
  padding="4"
  borderRadius="xl" >

        <form style={{ width: '100%' }} onSubmit={handleSubmit}>
          <Grid templateColumns="repeat(2, 1fr)" gap={4}>
            <FormControl gridColumn="span 1">
              <FormLabel>Nom complet</FormLabel>
              <Input
                type="text"
                name="fullName"
                placeholder="Nom complet"
                value={patientData.fullName}
                onChange={handleInputChange}
                isInvalid={!!validationErrors.fullName}
              />
            </FormControl>
            <FormControl gridColumn="span 1">
              <FormLabel>Date de naissance</FormLabel>
              <Input
                type="Date"
                name="dob"
                placeholder="Date de naissance "
                value={patientData.dob}
                onChange={handleInputChange}
            
              />
            </FormControl>
            <FormControl gridColumn="span 1">
              <FormLabel>Tél</FormLabel>
              <Input
                type="number"
                name="phoneNumber"
                placeholder="Tél"
                value={patientData.phoneNumber}
                onChange={handleInputChange}
                isInvalid={!!validationErrors.phoneNumber}
              />
            </FormControl>
            <FormControl gridColumn="span 1">
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                name="email"
                value={patientData.email}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl gridColumn="span 1">
              <FormLabel>Profession</FormLabel>
              <Input
                type="text"
                name="job"
                value={patientData.job}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl gridColumn="span 1">
              <FormLabel>Adresse</FormLabel>
              <Input
                type="text"
                name="adresse"
                value={patientData.adresse}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl gridColumn="span 1">
              <FormLabel>Num assurance social</FormLabel>
              <Input
                type="text"
                name="assuranceNumber"
                value={patientData.assuranceNumber}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl gridColumn="span 1">
              <FormLabel>Clé</FormLabel>
              <Input
                type="text"
                name="assuranceKey"
                value={patientData.assuranceKey}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl gridColumn="span 1">
              <FormLabel>Qualité</FormLabel>
              <RadioGroup
                name="qualite"
                value={patientData.qualite}
                onChange={(value) => setPatientData({ ...patientData, qualite: value })}
              >
                <HStack spacing="24px">
                  <Radio value="CNAM">CNAM</Radio>
                  <Radio value="Assurance">Assurance</Radio>
                  <Radio value="autre">Autre</Radio>
                </HStack>
              </RadioGroup>
            </FormControl>
            <FormControl gridColumn="span 1">
              <FormLabel>Assurance Social</FormLabel>
              <RadioGroup
                name="socialAssuranceType"
                value={patientData.socialAssuranceType}
                onChange={(value) => setPatientData({ ...patientData, socialAssuranceType: value })}
              >
                <HStack spacing="24px">
                  <Radio value="CNSS">CNSS</Radio>
                  <Radio value="CNRPS">CNRPS</Radio>
                  <Radio value="autre">Autre</Radio>
                </HStack>
              </RadioGroup>
            </FormControl>
            <FormControl gridColumn="span 1">
              <FormLabel>Gender</FormLabel>
              <RadioGroup
                name="gender"
                value={patientData.gender}
                onChange={(value) => setPatientData({ ...patientData, gender: value })}
              >
                <HStack spacing="24px">
                  <Radio value="M">Male (M)</Radio>
                  <Radio value="F">Female (F)</Radio>
                </HStack>
              </RadioGroup>
            </FormControl>
          </Grid>
          {/* Display validation errors */}
          <Box color="red" mt={2}>
            {Object.values(validationErrors).map((error, index) => (
              <div key={index}>{error}</div>
            ))}
          </Box>
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Button type="submit" colorScheme="blue" isLoading={isLoading}>
              Ajouter
            </Button>
            <Button
              type="button"
              onClick={onClose}
              colorScheme="red"
              ml="2"
            >
              Annuler
            </Button>
          </div>
        </form>
      </Box>
    </Grid>
  );
  
}


export default PatientForm;
