// Chakra imports
import { Box, Divider, Flex, useColorModeValue } from '@chakra-ui/react';

// Custom components
import { HorizonLogo } from 'components/icons/Icons';


export function SidebarBrand() {
	//   Chakra color mode
	let logoColor = useColorModeValue('navy.700', 'white');

	return (
		<Box>
		<Flex alignItems='center' flexDirection='column'>
			<HorizonLogo h='200%' w='200%' my='1%' color={logoColor} />
			<Divider my='0%' w='100%' borderColor='blue.500'  borderBottomWidth='8px'/>
		</Flex>
		</Box>
		
	);
}

export default SidebarBrand;
