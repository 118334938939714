// MedcinEditModal.tsx

import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Button,
  Spinner, // Import Spinner component from Chakra UI
  useToast,
} from '@chakra-ui/react';
import apiClient from 'views/admin/appclient';

interface MedcinEditModalProps {
  isOpen: boolean;
  onClose: () => void;
  medcinId: string; // Pass the medcinId to identify the record to edit
  onEditMedcin: () => void; // Callback function to fetch updated data after editing
}

const MedcinEditModal: React.FC<MedcinEditModalProps> = ({ isOpen, onClose, medcinId, onEditMedcin }) => {
  const [loading, setLoading] = useState(true); // Loading state
  const [nom, setNom] = useState('');
  const [tel, setTel] = useState('');
  const [email, setEmail] = useState('');
  const [spécialité, setSpécialité] = useState('');
  const [adresse, setAdresse] = useState('');
  const [hop, setHop] = useState('');

  const toast = useToast();

  useEffect(() => {
    // Fetch medcin details based on medcinId and populate the form
    const fetchMedcinDetails = async () => {
      try {
        const accessToken = localStorage.getItem('token');

        if (!accessToken) {
          console.error('Access token not found in local storage');
          return;
        }

        const response = await apiClient.get(`/api/medecin/${medcinId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const { nom, tel, email, spécialité, adresse, hop } = response.data;
        setNom(nom);
        setTel(tel);
        setEmail(email);
        setSpécialité(spécialité);
        setAdresse(adresse);
        setHop(hop);
      } catch (error) {
        console.error('Error fetching Medcin details for edit:', error);
      } finally {
        setLoading(false); // Set loading to false when done fetching
      }
    };

    if (isOpen) {
      setLoading(true); // Set loading to true when modal is opened
      fetchMedcinDetails();
    }
  }, [isOpen, medcinId]);

  const handleEditMedcin = async () => {
    try {
      const accessToken = localStorage.getItem('token');

      if (!accessToken) {
        console.error('Access token not found in local storage');
        return;
      }

      const response = await apiClient.patch(
        `/api/medecin/${medcinId}`,
        {
          nom,
          tel,
          email,
          spécialité,
          adresse,
          hop,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        toast({
          title: 'Médecin modifié avec succès',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });

        // Close the modal and trigger a fetch of updated data
        onClose();
        onEditMedcin();
      } else {
        console.error('Error editing Médecin. Status:', response.status);
      }
    } catch (error) {
      console.error('Error editing Médecin:', error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Modifier Médecin</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {loading ? (
            <Spinner size="lg" color="blue.500" />
          ) : (
            <>
              <FormControl mb="4">
                <FormLabel>Nom</FormLabel>
                <Input value={nom} onChange={(e) => setNom(e.target.value)} />
              </FormControl>
              <FormControl mb="4">
                <FormLabel>Téléphone</FormLabel>
                <Input value={tel} onChange={(e) => setTel(e.target.value)} />
              </FormControl>
              <FormControl mb="4">
                <FormLabel>Email</FormLabel>
                <Input value={email} onChange={(e) => setEmail(e.target.value)} />
              </FormControl>
              <FormControl mb="4">
                <FormLabel>Spécialité</FormLabel>
                <Input value={spécialité} onChange={(e) => setSpécialité(e.target.value)} />
              </FormControl>
              <FormControl mb="4">
                <FormLabel>Adresse</FormLabel>
                <Input value={adresse} onChange={(e) => setAdresse(e.target.value)} />
              </FormControl>
              <FormControl mb="4">
                <FormLabel>Hôpital</FormLabel>
                <Input value={hop} onChange={(e) => setHop(e.target.value)} />
              </FormControl>
            </>
          )}
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" onClick={handleEditMedcin}>
            Enregistrer
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Annuler
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default MedcinEditModal;
