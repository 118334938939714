import React, { useState, useEffect } from 'react';
import apiClient from '../appclient';
import { Icon, Grid, GridItem, Box, Button, Flex, FormLabel, Input, Spinner } from '@chakra-ui/react';
import { MdAddTask, MdPerson, MdBarChart, MdAttachMoney, MdAccountBalance, MdTask, MdTaskAlt, MdAccountBalanceWallet, MdNoAccounts, MdOutlineMoney } from 'react-icons/md';
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';

const formatDate = (date: string) => {
  if (!date) return '';
  const d = new Date(date);
  const year = d.getFullYear();
  const month = d.getMonth() + 1; // Months are 0-based
  const day = d.getDate();
  return `${year}-${month}-${day}`;
};

const CnamStats = () => {
  const [cnamData, setCnamData] = useState<any>(null);
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');

  useEffect(() => {
    const fetchCnamData = async () => {
      try {
        const accessToken = localStorage.getItem('token');
        if (!accessToken) {
          throw new Error('Access token not found in local storage');
        }

        // Format dates to YYYY-M-D
        const formattedStartDate = formatDate(startDate);
        const formattedEndDate = formatDate(endDate);

        const params = {
          startDate: formattedStartDate,
          endDate: formattedEndDate
        };

        const response = await apiClient.get('api/caisse/kabinet-payment-stats', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params
        });

        const data = response.data;
        setCnamData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchCnamData();
  }, [startDate, endDate]);

  // Reset function to clear date inputs and fetch default data
  const handleReset = () => {
    setStartDate('');
    setEndDate('');
    // Optionally, you might want to fetch default data here
    // e.g., fetchCnamData(); but without params to get default data
  };

  if (!cnamData) return    <Flex justifyContent="center" alignItems="center" height="200px">
  <Spinner color="blue.500" size="xl" />
</Flex>;

  const {
    totalAmountCnam,
    totalScpaiedCNAM,
    patientCount,
    totalNbcCnam,
    totalPayout,
    NbcPayout,
    scPayoutTotal,
    scAccCnamTotal,
    patientcountPeriod,
    totalCnamPeriod
  } = cnamData;

  return (
    <div style={{ marginTop: "20px" }}>
      <Flex
        direction={{ base: 'column', md: 'row' }}
        gap={4}
        mb={4}
      >          <FormLabel htmlFor="startDate">Date de début:</FormLabel>

        <Flex direction={{ base: 'row', md: 'row' }} gap={8}>
          <Input
            id="startDate"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            size="sm"
          />
        </Flex>
        <FormLabel htmlFor="endDate">Date de fin:</FormLabel>

        <Flex direction={{ base: 'row', md: 'row' }} gap={2}>
          <Input
            id="endDate"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            size="sm"
          />
        </Flex>
      
      </Flex>
      <Button onClick={handleReset} colorScheme="blue" size="sm">
          Reset
        </Button>
      <div style={{ marginTop: '20px' }}>
        <Grid
          templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(auto-fill, minmax(250px, 1fr))' }}
          gap={{ base: 2, md: 4 }}
          autoRows="minmax(200px, auto)"
        >
          {[
            { name: 'Montant Total CNAM', value: totalAmountCnam, icon:MdAccountBalance  },
            { name: 'Montant payé CNAM pour la période sélectionnée', value: totalCnamPeriod, icon: MdAttachMoney },
            { name: 'Nombre total des séances payées CNAM', value: totalNbcCnam, icon: MdTask },
            { name: 'Montant total payé accomplement CNAM', value: totalScpaiedCNAM, icon: MdAttachMoney },
            { name: 'Montant total des paiements', value: scPayoutTotal, icon: MdAccountBalanceWallet },
            { name: 'Total des paiements pour la période sélectionnée', value: totalPayout, icon: MdAttachMoney },
            { name: 'Nombre totale des séances payées pour la période sélectionnée', value: NbcPayout, icon: MdOutlineMoney  },
            { name: 'Nombre total de patients', value: patientCount, icon: MdPerson },
            { name: 'Nombre total des séances CNAM', value: scAccCnamTotal, icon: MdAddTask }
          ].map(({ name, value, icon }, index) => (
            <GridItem key={index}>
              <Box borderWidth="1px" borderRadius="md" boxShadow="md">
                <MiniStatistics
                  startContent={
                    <IconBox
                      w='15svh'
                      h='15vh'
                      bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
                      icon={<Icon w='28px' h='28px' as={icon} color='white' />}
                    />
                  }
                  name={name}
                  value={value}
                />
              </Box>
            </GridItem>
          ))}
        </Grid>
      </div>
    </div>
  );
}

export default CnamStats;
