import React, { useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Spinner,
  useColorModeValue,
  Box,
  Flex,
  Button,
  useToast,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import {
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  CellContext,
} from "@tanstack/react-table";
import Card from "components/card/Card";

import Pagination from "./pagination";
import {  EditIcon, HamburgerIcon } from "@chakra-ui/icons";
import { CheckIcon, CloseIcon, DeleteIcon } from "@chakra-ui/icons";
import EditPECModal from "./EditPEC";
import SearchBarPEC from "./SearchPEC";
import NewPECModal from "./AddPEC";
import apiClient from "views/admin/appclient";
import appclient from "views/admin/appclient";
import { Skeleton } from "@chakra-ui/react";

type Doctor = {
    _id: string;
    nom: string;
  };
  
  type Physiotherapist = {
    _id: string;
    firstName: string;
  };
  interface PageState {
    currentPage: number;
    totalPages: number;
  }
  
type RowObj = {
    _id : any ; 
  NbSc: number;
  NbScperweek: number;
  createdAt: string;
  dateLastVisit: string | null;
  datefirstVisit: string | null;
  doctor: Doctor[]; 
  isPaid: boolean;
  otherCosts: number;
  patient: any[]; 
  kine: Physiotherapist[];
  pricePerVisit: number;
  updatedAt: string;
  actions: any ; 
  PECExist: boolean ; 
  NbScCnam: number ;
};

interface PECTableProps {
  pecRecords: RowObj[];
}


const PECTable: React.FC<PECTableProps> = ({ pecRecords }) => {
  const columnHelper = createColumnHelper<RowObj>();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
const [data , setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState<PageState>({ currentPage: 1, totalPages: 1 });
  const token = localStorage.getItem("token");
  const toast = useToast();
  const [originalData, setOriginalData] = useState([]);

  const handlePayedClick = async (medicalCareId: string) => {
    const currentIsPaidValue = data.find((record) => record._id === medicalCareId)?.isPaid;

    try {
      console.log("done")
      await apiClient.put('/api/charges/updateIsPaid',  { medicalCareId, isPaid: !currentIsPaidValue } ,  {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      } );
    console.log("done1")
      fetchData(`/api/charges?page=${currentPage}`);
    } catch (error) {
      console.error('Error updating isPaid property:', error);
      // Handle error (show a toast or any other UI feedback)
    }
  };

  const handlePECClick = async (medicalCareId: string) => {
    const currentPECExistValue = data.find((record) => record._id === medicalCareId)?.PECExist;

    try {
      // Call the backend API to update the "PECExist" property
      await apiClient.put('/api/charges/updatePECExist',
      { medicalCareId, PECExist: !currentPECExistValue }  ,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      } );
      // Refresh data after the update
      fetchData(`/api/charges?page=${currentPage}`);
    } catch (error) {
      console.error('Error updating PECExist property:', error);
      // Handle error (show a toast or any other UI feedback)
    }
  };

  const fetchData = (apiUrl: string) => {
    apiClient
      .get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setIsLoading(false);
        if (!(apiUrl.includes('getAll'))) {
        setData(response.data[0].items);
       
        setPage({
          currentPage: response.data[0].page,
          totalPages: response.data[0].totalPages,
        });
      }
        if (apiUrl.includes('getAll')) {
          setOriginalData(response.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching PEC records 2 :", error);
      });
  };
  
  const handlePageChange = (selectedPage: number) => {
    setIsLoading(true);
    const adjustedPage = Math.min(selectedPage, page.totalPages);
    const apiUrl = `api/charges?page=${adjustedPage}`;

    apiClient
      .get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setIsLoading(false);
        setData(response.data[0].items);
setCurrentPage(adjustedPage)
        setPage({
          currentPage: adjustedPage, 
          totalPages: response.data[0].totalPages, 
        });
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching PEC records  :", error);
      });
  };
  const onDelete = (medicalCareId: string) => {
    appclient
      .delete("/api/charges/", {
      data: { _id: medicalCareId },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        toast({
          title: "MedicalCare deleted",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        fetchData(`/api/charges?page=${currentPage}`);
      })
      .catch((error) => {
        console.error("Error deleting MedicalCare:", error);
        toast({
          title: "Error deleting MedicalCare",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };
  React.useEffect(() => {
    // Fetch data with page=all
    fetchData(`/api/charges/getAll`);
  }, []);

  React.useEffect(() => {
    // Fetch data with the current page
    fetchData(`/api/charges?page=${currentPage}`);
  }, [currentPage]);
  const [selectedPEC, setSelectedPEC] = useState({});
  const handleEditClick = (PEC: any) => {
    setSelectedPEC(PEC);
    console.log(selectedPEC)
    setPECModalOpen(true);
  };

  const columns = [
    columnHelper.accessor("_id", {
      id: "_id",
      header: () => (
        <Text justifyContent="space-between" align="center" fontSize={{ sm: "10px", lg: "16px" }} color='blue.500'  >
          ID
        </Text>
      ),
      cell: (props: CellContext<RowObj, any>) => (
        <Text color={textColor} fontSize="16px" fontWeight="700" textAlign={"center"}>
          {props.row.index + 1}
        </Text>
      ),
    }),

  
    columnHelper.accessor("NbSc", {
        id: "NbSc",
        header: () => (
          <Text  textAlign={"center"} fontSize={{ sm: "10px", lg: "16px" }} color='blue.500'  >
            Nbr sc totale
          </Text>
        ),
        cell: (info) => (
          <Text color={textColor} fontSize="16px" fontWeight="700" textAlign={"center"} >
            {info.getValue()}
          </Text>
        ),
      }),
        columnHelper.accessor("NbScCnam", {
        id: "NbScCnam",
        header: () => (
          <Text  textAlign={"center"} fontSize={{ sm: "10px", lg: "16px" }} color='blue.500'  >
            Nb sc accordé
          </Text>
        ),
        cell: (info) => (
          <Text color={textColor} fontSize="16px" fontWeight="700" textAlign={"center"} >
            {info.getValue()}
          </Text>
        ),
      }),
      columnHelper.accessor("NbScperweek", {
        id: "NbScperweek",
        header: () => (
          <Text justifyContent="space-between" align="center" fontSize={{ sm: "10px", lg: "16px" }} color='blue.500'   >
            Nb sc/semaine 
          </Text>
        ),
        cell: (info) => (
          <Text color={textColor} fontSize="16px" fontWeight="700" textAlign={"center"} >
            {info.getValue()}
          </Text>
        ),
      }),
     
      columnHelper.accessor("patient", {
        id: "patient",
        header: () => (
          <Text justifyContent="space-between" align="center" fontSize={{ sm: "10px", lg: "16px" }} color='blue.500' >
            Patient
          </Text>
        ),
        cell: (info) => (
          <Text color={textColor} fontSize="16px" fontWeight="700" textAlign={"center"}>
     {info.row.original.patient && info.row.original.patient[0]?.fullName ? info.row.original.patient[0].fullName : "N/A"}
         </Text>
        ),
      }),
      columnHelper.accessor("doctor", {
        id: "doctor",
        header: () => (
          <Text justifyContent="space-between" align="center" fontSize={{ sm: "10px", lg: "16px" }} color='blue.500' >
            Medecin
          </Text>
        ),
        cell: (info) => (
          <Text color={textColor} fontSize="16px" fontWeight="700" textAlign={"center"}> 
       {info.row.original.doctor && info.row.original.doctor[0]?.nom ? info.row.original.doctor[0].nom : "N/A"}
         </Text>
        ),
      }),
      columnHelper.accessor("kine", {
        id: "kine",
        header: () => (
          <Text justifyContent="space-between" align="center" fontSize={{ sm: "10px", lg: "16px" }} color='blue.500' >
            kiné
          </Text>
        ),
        cell: (info) => (
          <Text color={textColor} fontSize="16px" fontWeight="700" textAlign={"center"}>
               {info.row.original.kine && info.row.original.kine[0]?.firstName ? info.row.original.kine[0].firstName : "N/A"}

          </Text>
        ),
      }),
      columnHelper.accessor("isPaid", {
        id: "isPaid",
        header: () => (
          <Text justifyContent="space-between" align="center" fontSize={{ sm: "10px", lg: "16px" }} color='blue.500'>
      Payé
          </Text>
        ),
        cell: (info) => (
          <Button
        
          textAlign="center"
          onClick={() => handlePayedClick(info.row.original._id)}
        >
            {info.getValue() ? (
              <CheckIcon color="green.500" textAlign="center" />
            ) : (
              <CloseIcon color="red.500" textAlign="center" />
            )}
        </Button>
        ),
      }),
      columnHelper.accessor("PECExist", {
        id: "PECExist",
        header: () => (
          <Text justifyContent="space-between" align="center" fontSize={{ sm: "10px", lg: "16px" }} color='blue.500'>
        PEC 
          </Text>
        ),
        cell: (info) => (
          <Button
       
          onClick={() => handlePECClick(info.row.original._id)}
        >
            <Text fontSize={{ sm: '10px', lg: '20px' }} color={textColor}></Text>
            {info.getValue() ? (
              <CheckIcon color="green.500" textAlign="center" />
            ) : (
              <CloseIcon color="red.500" textAlign="center" />
            )}
        </Button>
      
        ),
      }),
      columnHelper.accessor('actions', {
        id: 'actions',
        header: () => (
          <Text justifyContent='space-between' fontSize={{ sm: '10px', lg: '16px' }} color='blue.500'>
            Actions
          </Text>
        ),
        cell: (info: any) =>  (
          <Box display="flex" justifyContent="center" alignItems="center">
          <Menu>
            <MenuButton
              as={Button}
           
              fontSize={{ sm: '10px', lg: '16px' }}
              colorScheme="gray"
            >
              {/* Hamburger icon */}
              <HamburgerIcon />
            </MenuButton>
            <MenuList>
              <MenuItem
                fontSize={{ sm: '10px', lg: '16px' }}
                onClick={() => handleEditClick(info.row.original)}
              >
                {/* Edit icon */}
                <EditIcon mr={2} />
                Modifer
              </MenuItem>
              <MenuItem
                fontSize={{ sm: '10px', lg: '16px' }}
               
                onClick={() => onDelete(info.row.original._id)}
              >
                {/* Delete icon */}
                <DeleteIcon mr={2} />
                Supprimer
              </MenuItem>
            </MenuList>
          </Menu>
          </Box>
        ),
      }),

  ];
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });
  const [selectedCategory, setSelectedCategory] = useState('patient');
  const handleSearch = (searchQuery: string, searchCategory: string) => {
    if (!originalData) {
      fetchData('/api/charges/getAll');
      return;
    }
    if (searchQuery.trim() === '') {
      fetchData(`/api/charges?page=${currentPage}`);
      return;
    }
    const filteredData = originalData.filter((pec) => {
      const fieldToSearch =
        searchCategory === 'patient' ? pec?.patient?.[0]?.fullName?.toLowerCase() :
        searchCategory === 'doctor' ? pec.doctor?.[0]?.nom.toLowerCase() :
        searchCategory === 'kiné' ? pec?.kine?.[0]?.firstName?.toLowerCase() :
          ''; 
  
      return fieldToSearch?.includes(searchQuery.toLowerCase());
    });
  
    setData(filteredData);
  };
  
  
  
  const refreshData = () => {
    fetchData(`/api/charges?page=${currentPage}`);
  };
  const [isPECModalOpen, setPECModalOpen] = useState(false);

  const openPECModal = () => {
    setPECModalOpen(true);
  };

  const closePECModal = () => {
    setSelectedPEC(null);

    setPECModalOpen(false);
  };
  
  return (
    <><Card flexDirection="column" w="100%" px="0px"  h="100%">
      <Flex px="25px" mb="8px" justifyContent="space-between" align="center" width="100%">
        <Text color={textColor} fontSize="22px" mb="4px" fontWeight="700" lineHeight="100%">
         Liste des prises en charge
        </Text>
        <SearchBarPEC onSearch={handleSearch} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} />

<Button colorScheme="blue" onClick={openPECModal}>
     Nouv Prise en Charge
   </Button>

   <NewPECModal isOpen={isPECModalOpen} pecData={selectedPEC}
 onClose={closePECModal} refreshData={refreshData} />

      </Flex>
      <Box >
        {isLoading ? (
          <Flex justifyContent="center" align="center" height="200px" fontSize="20px">
               
            <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" marginRight={"2%"}/> Loading
          </Flex>
        ) : (
          
          <Table variant="simple" color="gray.500" mb="24px" mt="12px">
            
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th
                    key={header.id}
                    colSpan={header.colSpan}
                    pe="10px"
                    borderColor={borderColor}
                    cursor="pointer"
                    whiteSpace="nowrap"
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    <Flex
                      justifyContent="center"
                      align="center"
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color="gray.400"
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: "",
                        desc: "",
                      }[header.column.getIsSorted() as string] ?? null}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row, rowIndex) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Td
                    key={cell.id}
                    fontSize={{ sm: "14px" }}
                    minW={{ sm: "auto", md: "auto", lg: "auto" }} 
                    borderColor="transparent"
                    backgroundColor={`rgba(127, 204, 252, ${
                      rowIndex % 2 === 0 ? 0 : 0.1
                    })`}
             
                
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
        
        )}
      </Box>
      <Flex  justify="center">
  <Box>
    <Pagination currentPage={page.currentPage} totalPages={page.totalPages} onPageChange={handlePageChange} />
  </Box>
  </Flex>
    </Card>
    </>
  );
};

export default PECTable;