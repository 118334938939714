import React, { useState } from 'react';
import { Box, Tab, TabList, TabPanel, TabPanels, Tabs, useColorModeValue } from '@chakra-ui/react';
import ClientTable from './components/tables/clientTable';
import PackTable from './components/tables/packTable';
import ServiceTable from './components/tables/ServiceTable';
import ArrousaTable from './components/tables/arrousaTable';
import SpaTable from './components/tables/spatable';

export default function Spa() {
  const [selectedTable, setSelectedTable] = useState<'Service' | 'Pack' | 'Client'  | 'Arrousa' | 'SPA'>('SPA');
  const textColor = useColorModeValue('blue.500', 'blue.200');

  const handleTabChange = (index: number) => {
    switch (index) {
      case 0:
        setSelectedTable('SPA');
        break;
      case 1:
        setSelectedTable('Pack');
        break;
      case 2:
        setSelectedTable('Client');
        break;
        case 3:
          setSelectedTable('Arrousa');
        break;
        case 4:
          setSelectedTable('Service');
        break;
      default:
        break;
    }
  };

  return (
    <Box marginTop="80px">
      <Tabs onChange={handleTabChange}>
        <TabList>
       
        <Tab id="SPA" _selected={{ color: textColor, bg: useColorModeValue('gray.100', 'gray.700') }}>
            SPA Agenda
          </Tab>
          <Tab id="Pack" _selected={{ color: textColor, bg: useColorModeValue('gray.100', 'gray.700') }}>
            Pack
          </Tab>
          <Tab id="Client" _selected={{ color: textColor, bg: useColorModeValue('gray.100', 'gray.700') }}>
            Client
          </Tab>
          <Tab id="Arrousa" _selected={{ color: textColor, bg: useColorModeValue('gray.100', 'gray.700') }}>
            Pack Arrousa
          </Tab>
          <Tab id="Service" _selected={{ color: textColor, bg: useColorModeValue('gray.100', 'gray.700') }}>
            Service
          </Tab>
        </TabList>

        <TabPanels>
        <TabPanel>
          {selectedTable === 'SPA' && <SpaTable />}

          </TabPanel>
          <TabPanel>
            {selectedTable === 'Pack' && <PackTable />}
          </TabPanel>
          <TabPanel>
            {selectedTable === 'Client' && <ClientTable />}
          </TabPanel>
          <TabPanel>
          {selectedTable === 'Arrousa' && <ArrousaTable />}

          </TabPanel>
        
          <TabPanel>
            {selectedTable === 'Service' && <ServiceTable />}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}
