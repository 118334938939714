// src/components/DatePicker.tsx
import React from 'react';
import styled from 'styled-components';

interface DatePickerProps {
  selectedDate: string;
  onDateChange: (date: string) => void;
}

const Container = styled.div`
  display: flex;

`;
const StyledLabel = styled.label`
  font-size: 1.2rem;
  font-weight: 600;
  color: #333;
  margin-right: 12px;
`;

const StyledInput = styled.input`
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: auto;
  box-sizing: border-box;

  &:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
`;

const DatePicker: React.FC<DatePickerProps> = ({ selectedDate, onDateChange }) => {
  return (
    <Container>
    <StyledLabel htmlFor="datePicker">Sélectionner une date:</StyledLabel>
    <StyledInput
      type="date"
      id="datePicker"
      value={selectedDate}
      onChange={(e) => onDateChange(e.target.value)}
    />
  </Container>
  );
};

export default DatePicker;
