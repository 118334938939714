import React, { useState, useMemo } from 'react';
import {
  Box,
  Flex,
  Text,
  useColorModeValue,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  InputGroup,
  InputLeftElement,
  Button,
  Tooltip,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import Card from 'components/card/Card';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { createColumnHelper, flexRender, getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from '@tanstack/react-table';

type RowObj = {
  fullName?: string | null; // Allow null
  status: string;
  createdAt?: string | null; // Allow null
};

const columnHelper = createColumnHelper<RowObj>();

export default function ComplexTable(props: { tableData: any; displayDataset: string }) {
  const { tableData, displayDataset } = props;
  const [sorting, setSorting] = useState<SortingState>([]);
  const [filterValue, setFilterValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const tableTitle = displayDataset === 'listeAttente'
    ? 'Liste d\'attente'
    : displayDataset === 'patientnonpayes'
      ? 'Patient non payé'
      : displayDataset === 'patientsabsent'
        ? 'Patients Absent'
        : displayDataset === 'patientnonpayesencours'
          ? 'Patient non payé en cours'
          : displayDataset === 'patientnonpayesterminé'
            ? 'Patient non payé terminé'
            : 'Liste des patients';

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  // Filter and paginate the data using useMemo to prevent infinite loops
  const filteredData = useMemo(() => {
    return tableData.filter((row: RowObj) => 
      row && row.fullName && row.createdAt && 
      row.fullName.toLowerCase().includes(filterValue.toLowerCase())
    );
  }, [tableData, filterValue]);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return filteredData.slice(startIndex, startIndex + itemsPerPage);
  }, [filteredData, currentPage, itemsPerPage]);

  const columns = [
    columnHelper.accessor('fullName', {
      id: 'fullName',
      header: () => (
        <Text fontSize={{ base: '10px', sm: '12px', md: '14px' }} color='gray.400' fontWeight='medium'>
          NAME
        </Text>
      ),
      cell: (info) => {
        const value = info.getValue();
        return (
          <Text color={textColor} fontSize={{ base: '10px', sm: '12px', md: '14px' }} fontWeight='medium'>
            {value || 'N/A'} {/* Handle null or undefined fullName */}
          </Text>
        );
      },
    }),
    columnHelper.accessor('createdAt', {
      id: 'createdAt',
      header: () => (
        <Text fontSize={{ base: '10px', sm: '12px', md: '14px' }} color='gray.400' fontWeight='medium'>
          DATE
        </Text>
      ),
      cell: (info) => {
        const rawDate = info.getValue();
        const formattedDate = rawDate ? new Date(rawDate).toLocaleDateString() : 'N/A'; // Format or default to 'N/A'

        return (
          <Text color={textColor} fontSize={{ base: '10px', sm: '12px', md: '14px' }} fontWeight='medium'>
            {formattedDate}
          </Text>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: paginatedData,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  return (
    <Card flexDirection='column' w='full' px={{ base: '2', md: '4' }} overflowX='auto' minHeight='70vh'>
      <Flex px={{ base: '2', md: '6' }} mb='4' justifyContent='space-between' align='center' flexDirection={{ base: 'column', sm: 'row' }}>
        <Text color={textColor} fontSize={{ base: 'lg', md: 'xl' }} fontWeight='bold' mb={{ base: '2', sm: '0' }}>
          {tableTitle}
        </Text>
        <Flex align='center'>
          <Tooltip label="Previous Page" fontSize="sm">
            <Button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              mr='2'
              variant='outline'
              fontSize={{ base: 'sm', md: 'md' }}
            >
              <FaArrowLeft />
            </Button>
          </Tooltip>
          <Text mx='2' fontSize={{ base: 'sm', md: 'md' }}>
            {currentPage} / {totalPages}
          </Text>
          <Tooltip label="Next Page" fontSize="sm">
            <Button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              variant='outline'
              fontSize={{ base: 'sm', md: 'md' }}
            >
              <FaArrowRight />
            </Button>
          </Tooltip>
        </Flex>
      </Flex>

      {filteredData.length > 0 ? (
        <Box>
          <InputGroup mb='4'>
            <InputLeftElement pointerEvents='none'>
              <SearchIcon color='gray.300' />
            </InputLeftElement>
            <Input
              type='text'
              placeholder='Search by name'
              value={filterValue}
              onChange={(e) => setFilterValue(e.target.value)}
              fontSize={{ base: 'sm', md: 'md' }}
            />
          </InputGroup>
          <Table variant='simple' color='gray.500' fontSize={{ base: 'sm', md: 'md' }}>
            <Thead>
              <Tr>
                {table.getHeaderGroups().map(headerGroup => (
                  <Tr key={headerGroup.id}>
                    {headerGroup.headers.map(header => (
                      <Th key={header.id} fontSize={{ base: '10px', sm: '12px', md: '14px' }} color='gray.600'>
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {table.getRowModel().rows.map(row => (
                <Tr key={row.id}>
                  {row.getVisibleCells().map(cell => (
                    <Td
                      key={cell.id}
                      fontSize={{ base: '10px', sm: '12px', md: '14px' }}
                      minW='150px'
                      borderColor='transparent'
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      ) : (
        <Box p='4' borderWidth='1px' borderRadius='md' borderColor='gray.200'>
          <Text color={textColor} fontSize={{ base: 'sm', md: 'lg' }} fontWeight='medium'>
            No patients in this list.
          </Text>
        </Box>
      )}
    </Card>
  );
}
