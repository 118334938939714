import { Button, Stack } from '@chakra-ui/react'; // Import Stack component for better alignment
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import apiClient from 'views/admin/appclient';
import SpaAddModal from '../addspa/addAgenda';
import AddArrousaRdvModal from '../addspa/addArrousaRdv';
import SchedulerTable from './SchedulerTable';
import { v4 as uuidv4 } from 'uuid';
import DatePickerCos from '../DatePicker';

const AppContainer = styled.div`
  padding: 20px;
`;

const SpaTable: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState<string>(new Date().toISOString().split('T')[0]);
  const [resources, setResources] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchDataAndTransform = async () => {
      try {
        const accessToken = localStorage.getItem('token');
        if (!accessToken) {
          throw new Error('Access token not found in local storage');
        }

        const serviceResponse = await apiClient.get('/api/service', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const serviceData = serviceResponse.data;
        const spas = await fetchSpas();
        const combinedData = mergeSpaWithServices(serviceData, spas);
        console.log("combiner",combinedData)
        setResources(combinedData);
      } catch (error) {
        console.error('Error fetching and transforming resources:', error);
      }
    };

    fetchDataAndTransform();
  }, []);
  const closeAddModal = () => {
    setIsModalOpen(false);
  };

  const onAddSpa = () => {
    fetchSpas();
    closeAddModal();
  };

  const handleEventSubmit = (updatedEvent: any) => {
    fetchSpas();
  };

  const fetchSpas = async () => {
    try {
      const accessToken = localStorage.getItem('token');
      if (!accessToken) {
        throw new Error('Access token not found in local storage');
      }

      const response = await apiClient.get('/api/spa', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const data = response.data;
      return data;
    } catch (error) {
      console.error('Error fetching spas:', error);
      return [];
    }
  };
  const calculateEndDate = (startDate: string, duration: number) => {
    const startDateTime = new Date(startDate);
    const endDateTime = new Date(startDateTime.getTime() + duration * 60000); 
    return endDateTime.toISOString();
  };
  const mergeSpaWithServices = (services: any[], spas: any[]) => {
    const serviceMap = new Map();
  
    // Initialize the map for each service type and arrousa
    services.forEach((service) => {
      if (!serviceMap.has(service.type)) {
        serviceMap.set(service.type, { type: service.type, events: [] });
      }
    });
    if (!serviceMap.has('arrousa')) {
      serviceMap.set('arrousa', { type: 'Arrousa', events: [] });
    }
  
    spas.forEach((spa) => {
      const isArrousa = Array.isArray(spa?.arrousa) && spa.arrousa.length > 0;
      const isPack = Array.isArray(spa?.packs) && spa.packs.length > 0;
      const isService = Array.isArray(spa.services) && spa.services.length > 0;
      const client = `${spa.client[0].fullName}${spa.numDeposit ? ` - ${spa.numDeposit}` : ''}`;
      const servicesNames = spa.services.map((service: any) => service.nom_service).join(', ');
   
      if (isService) {
        spa.services.forEach((service: any) => {
          const uniqueKey = uuidv4();
          const eventType = service.type;
          console.log("service.duration", service.duration)
          const event = {
            _id: spa._id,
            client: client,
            servicesNames: servicesNames,
            key: service._id,
            services: service,
            start: formatDate(service.startDate),
            end: calculateEndDate(service.startDate, service.duration),
            description: service.nom_service,
            color: spa.color ? spa.color : '#FF6347',
            prix_avec_remise: service.prix,
            comment: spa.comment,
            extraService: spa.extraService,
            duration: service.duration,
            deposit: spa.deposit,
            reste: spa.reste,
            paid: spa.paid,
            extraTotal: spa.extraTotal,
            isService: isService
          };
  
          // Add to serviceMap only if not an arrousa event
          if (!isArrousa) {
            const existingService = serviceMap.get(eventType);
            if (existingService) {
              existingService.events.push(event);
              existingService.duration += service.duration;
            }
          }
        });
      }
  
      if (spa.packs && spa.packs.services?.length > 0) {
        spa.packs.services.forEach((service: any) => {
          const uniqueKey = uuidv4();
          const eventType = service.type;
          const event = {
            _id: spa._id,
            key: service._id,
            client: client,
            servicesNames: servicesNames,
            start: formatDate(service.startDate),
            end: calculateEndDate(service.startDate, service.duration),
            description: service.nom_service,
            color: spa.color ? spa.color : '#FF6347',
            prix: service.prix,
            comment: spa.comment,
            duration: service.duration,
            prix_avec_remise: spa.packs?.price || 0,
            deposit: spa?.deposit ? spa.deposit : 0,
            reste: spa.reste,
            paid: spa.paid,
            extraTotal: spa.extraTotal,
            extraService: spa.extraService,
            isPack: isPack
          };
  
          // Add to serviceMap only if not an arrousa event
          if (!isArrousa) {
            const existingService = serviceMap.get(eventType);
            if (existingService) {
              existingService.events.push(event);
              existingService.duration += service.duration;
            }
          }
        });
      }
  
      // Handle arrousa events separately
      if (isArrousa) {
        spa.services.forEach((service: any) => {
          const uniqueKey = uuidv4();
          const description = spa.services.map((service: { nom_service: any; duration: any; startDate: any }) => {
            const startTime = new Date(service.startDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
            return `${service.nom_service}: ${service.duration} min - ${startTime}`;
          }).join(', ');
            
          const event = {
            _id: spa._id,
            key: service._id,
            start: formatDate(service.startDate),
            end: calculateEndDate(service.startDate, service.duration),
            description: `${spa.client[0].fullName} - ${spa.numDeposit}`,
            client: description,
            nbrAtt: spa.arrousa[0].nbrAtt,
            color: spa.color ? spa.color : '#FF6347',
            prix: service.prix,
            comment: spa.comment,
            deposit: spa?.deposit ? spa.deposit : 0,
            duration: service.duration,
            prix_avec_remise: spa.arrousa[0].prix_avec_remise,
            remise: spa.arrousa.remise > 0,
            isArrousaEvent: isArrousa,
            prix_par_personne: spa.arrousa[0].prix_par_personne,
            extraService: spa.extraService,
            extraNbrAtt: spa.extraNbrAtt,
            extraTotal: spa.extraTotal,
            reste: spa.reste,
            paid: spa.paid
          };
  
          const arrousaService = serviceMap.get('arrousa');
          if (arrousaService) {
            arrousaService.events.push(event);
            arrousaService.duration += service.duration;
          }
        });
      }
    });
  
    return Array.from(serviceMap.values());
  };
  
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };
const [isModalArrousaOpen, setIsModalArrousaOpen] = useState(false);
const closeAddModalArrousa = () => {
  setIsModalArrousaOpen(false);
};
const handleAddArrousa = () => {
fetchSpas()

}
  return (
    <AppContainer>
      <Stack direction="row" spacing={4} alignItems="center">
        <DatePickerCos selectedDate={selectedDate} onDateChange={setSelectedDate} />
        <Button onClick={() => setIsModalOpen(true)}>Ajouter RDV</Button>
        <Button onClick={() => setIsModalArrousaOpen(true)}>Ajouter RDV Arrousa</Button>
      </Stack>
      <AddArrousaRdvModal isOpen={isModalArrousaOpen} onClose={closeAddModalArrousa} onAddArrousa={handleAddArrousa} />
      <SpaAddModal isOpen={isModalOpen} onClose={closeAddModal} onAddSpa={onAddSpa} />
      <SchedulerTable resources={resources} selectedDate={selectedDate} onSubmit={handleEventSubmit} />
    </AppContainer>
  );
};

export default SpaTable;

