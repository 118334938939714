import { Box, Button, Flex, Input, useToast, VStack , Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import PatientsWithProlongationTable from '../default/components/PatientsWithProlongationTable';
import CnamStats from './cnam_stats';
import SpaStats from './spa_stats';

export default function Caisse() {
  const [pin, setPin] = useState("");
  const [isPinCorrect, setIsPinCorrect] = useState(false);
  const toast = useToast();
    const getMarginTop = () => {
        if (window.innerWidth <= 768) {
          return '110px'; // Margin for mobile devices
        }
        return '70px'; // Default margin
      };
  const containerStyle: React.CSSProperties = {
    marginTop: getMarginTop(),
    padding: '0 15px',
    display: 'flex',
    flexDirection: 'column', // Ensure this is a valid flexDirection value
    alignItems: 'center',
  };
  const handlePinSubmit = () => {
    if (pin === '09383929') {
      setIsPinCorrect(true);
    } else {
      toast({
        title: "Code PIN incorrect",
        description: "Veuillez réessayer.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  const handleDelete = () => {
    setPin((prevPin) => prevPin.slice(0, -prevPin.length));
  };

  const responsiveStyle: React.CSSProperties = {
    width: '100%',
 
  };
 
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
    {!isPinCorrect ? (
      <VStack spacing="4" align="center">
        <Box
          border="1px solid"
          borderColor="black"
          color={"gray.500"}
          borderRadius="md"
          p="4"
          maxW="100%"
        >
          <Text fontSize="xl" fontWeight="bold" mb="2" textAlign={"center"}>
            Ce continu est protégé par un code PIN
          </Text>
          <Input
            type="password"
            placeholder="PIN"
            value={pin}
            onChange={(e) => setPin(e.target.value)}
            textAlign="center"
            fontSize="2xl"
            letterSpacing="4px"
            width="100%"
            mb="2"
          />
          <Flex justifyContent="space-around">
       
            <Button
              onClick={handlePinSubmit}
              variant="outline"
              colorScheme="teal"
            >
              Entrer
            </Button>
            <Button
              onClick={handleDelete}
              variant="outline"
              colorScheme="red"
            >
              Supp
            </Button>
          </Flex>
        </Box>
      </VStack>
    ) : (
    <div style={containerStyle}>
      <div style={responsiveStyle}>
        <CnamStats />
        <SpaStats />
        <PatientsWithProlongationTable />
      </div>
    </div>
      )}
    </Box>
    
  );
}
