import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Box,
  Button,
  useColorModeValue,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Spinner,
  Center,
  HStack,
  Flex,
} from '@chakra-ui/react';
import apiClient from 'views/admin/appclient';
import ScheduleTable from './ScheduleTable-Patient';
import { FiPhone, FiMail, FiUser, FiCalendar, FiKey, FiBriefcase } from 'react-icons/fi'; // Import icons as needed

interface PatientDetailProps {
  isOpen: boolean;
  onClose: () => void;
  patientData: any; // Replace 'any' with the actual type of your patient data
}

const PatientDetail: React.FC<PatientDetailProps> = ({ isOpen, onClose, patientData }) => {
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const [visits, setVisits] = useState([]);
  const [editedScheduleId, setEditedScheduleId] = useState<string | null>(null);
  const [scheduledata , setScheduledata] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const visitsPerPage = 5; 
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    // Fetch visits by patient ID when the component mounts
    if (patientData && patientData._id) {
        const headers = {
            Authorization: `Bearer ${token}`,
          };
        
      const fetchVisits = async () => {
        try {
          setLoading(true);
          
          const response = await apiClient.get(`/api/visits/${patientData._id}` , { headers }); // Replace with your actual API endpoint
        
          const data = await response.data ; 
       
          setVisits(data);
          setScheduledata(data[0].schedule);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching visits:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchVisits();
    }
}, [isOpen, patientData]);
console.log("visits", visits)
  if (!isOpen || !patientData) {
    return null;
  }
  const { fullName, dob, gender, phoneNumber, email, job, qulite , assuranceKey , assuranceNumber , socialAssuranceType , isInWaitingList , qualite
  } = patientData;
  // Calculate the index range for the current page
  const indexOfLastVisit = currentPage * visitsPerPage;
  const indexOfFirstVisit = indexOfLastVisit - visitsPerPage;
  const currentVisits = scheduledata.slice(indexOfFirstVisit, indexOfLastVisit);
  const totalPages = Math.ceil(visits[0]?.schedule?.length / visitsPerPage)
  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };


  //zid el edit w el recherche w zid el get medical care bech tekhou el link mtaa el file walla orbtou bel user zeda 
  const FlexItem = ({ icon , text }: { icon : any, text : any }) => (
    <Box display="flex" margin="1%" className='flex'>
      {icon}
      <Text ml={2} color={textColor}>
        {text}
      </Text>
    </Box>
  );
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Patient Details
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
        <Box>
      <Text color={textColor} fontWeight="bold" fontSize="lg">
        Informations personnelles :
      </Text>
      <Flex wrap="wrap" justify="start">
  <FlexItem  icon={<FiUser size={20} />} text={`Nom complet : ${fullName}`} />
  {dob && <FlexItem icon={<FiCalendar size={20} />} text={`Date de naissance : ${dob}`} />}
  <FlexItem icon={<FiUser size={20} />} text={`Genre : ${gender}`} />
  {assuranceKey && <FlexItem icon={<FiKey size={20} />} text={`Clé assurance : ${assuranceKey}`} />}
  {assuranceNumber && (
    <FlexItem icon={<FiKey size={20} />} text={`Numéro assurance : ${assuranceNumber}`} />
  )}
  {socialAssuranceType && (
    <FlexItem icon={<FiUser size={20} />} text={`Type assurance sociale : ${socialAssuranceType}`} />
  )}
  {qualite && <FlexItem icon={<FiUser size={20} />} text={`Qualité : ${qualite}`} />}
  <FlexItem icon={<FiPhone size={20} />} text={`Téléphone : ${phoneNumber}`} />
  {email && <FlexItem icon={<FiMail size={20} />} text={`Email : ${email}`} />}
  {job && <FlexItem icon={<FiBriefcase size={20} />} text={`Profession : ${job}`} />}
</Flex>
    </Box>
          {loading && 
           <Center>
            <Spinner size="lg" color="blue.500" />
            </Center>
           }

{!loading && (
  <Box>
    {/* {visits.length === 0 && (
      <Text color={textColor} mt={4}>
        Pas de rendez-vous pour le moment.
      </Text>
    )} */}

{/* {visits.length > 0 && (
  <ScheduleTable
    visitsId={visits[0].id
    }
    currentVisits={currentVisits}
    currentPage={currentPage}
    visitsPerPage={visitsPerPage}
    handlePageChange={handlePageChange}

  />
)} */}

  {!loading && (
  <HStack spacing={4} mt={4} justifyContent="center">
    {Array.from({ length: totalPages }, (_, index) => {
      // Display only the current page and its neighbors
      const isWithinRange = Math.abs(currentPage - (index + 1)) <= 1;
      
      if (isWithinRange || index === 0 || index === totalPages - 1) {
        return (
          <Button
            key={index + 1}
            colorScheme={index + 1 === currentPage ? 'blue' : 'gray'}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </Button>
        );
      }
      
      // Display ellipsis for pages that are not within the range
      if (!isWithinRange && index === 1) {
        return <Text key="ellipsis-start">...</Text>;
      }
      if (!isWithinRange && index === totalPages - 2) {
        return <Text key="ellipsis-end">...</Text>;
      }
      
      return null;
    })}
  </HStack>
)}

  </Box>
)}
</ModalBody>
<ModalFooter>
<Button colorScheme="blue" onClick={onClose}>
  Fermer
</Button>
</ModalFooter>
</ModalContent>
</Modal>
  );
};

export default PatientDetail;
