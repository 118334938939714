import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Spinner,
  Flex,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useToast,
  Input,
} from "@chakra-ui/react";
import { createColumnHelper, flexRender, getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from '@tanstack/react-table';
import { DeleteIcon, EditIcon, HamburgerIcon } from '@chakra-ui/icons';
import apiClient from 'views/admin/appclient';
import ServiceAddModal from '../addspa/addservice';
import EditService from '../editspa/editservice';

interface PageState {
  currentPage: number;
  totalPages: number;
}

type Service = {
  _id: string;
  nom_service: string;
  prix: number;
  duration: string;
  type: string; 
  actions: JSX.Element;
};

const ServiceTable: React.FC = () => {
  const [services, setServices] = useState<Service[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [originalData, setOriginalData] = useState<Service[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Number of items per page

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const columnHelper = createColumnHelper<Service>();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedServiceId, setSelectedServiceId] = useState<string>('');

  const fetchServices = async () => {
    try {
      const accessToken = localStorage.getItem('token');
      if (!accessToken) {
        console.error('Access token not found in local storage');
        return;
      }
      const response = await apiClient.get('api/service', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setServices(response.data);
      setOriginalData(response.data);
    } catch (error) {
      console.error('Error fetching Services:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchServices();
  }, []);

  const filteredData = originalData.filter(service =>
    service.nom_service.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleAddService = () => {
    setIsAddModalOpen(true);
  };

  const closeAddModal = () => {
    setIsAddModalOpen(false);
  };

  const onAddService = () => {
    fetchServices();
    closeAddModal(); // Close the modal after adding a service
  };

  const handleEditService = (serviceId: string) => {
    setSelectedServiceId(serviceId);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setSelectedServiceId("");
    setIsEditModalOpen(false);
  };

  const columns = [
    columnHelper.accessor("nom_service", {
      id: "nom_service",
      header: "Nom de Service",
    }),
    columnHelper.accessor("prix", {
      id: "prix",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "20px" }}
          color='blue.500'
        >
          Prix
        </Text>
      ),
    }),
    columnHelper.accessor("duration", {
      id: "duration",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "20px" }}
          color='blue.500'
        >
          Durée
        </Text>
      ),
    }),
    columnHelper.accessor("type", {
      id: "type",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "20px" }}
          color='blue.500'
        >
          Type
        </Text>
      ),
    }),
    columnHelper.accessor("actions", {
      id: "actions",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "20px" }}
          color='blue.500'
        >
          Actions
        </Text>
      ),
      cell: (info: any) => (
        <Box
          display="flex"
          alignItems="center"
          marginLeft="10%"
        >
          <Menu>
            <MenuButton
              as={Button}
              fontSize={{ sm: '10px', lg: '16px' }}
              colorScheme="gray"
            >
              <HamburgerIcon />
            </MenuButton>
            <MenuList>
              <MenuItem
                fontSize={{ sm: '10px', lg: '16px' }}
                onClick={() => handleEditService(info.row.original._id)}>
                <EditIcon mr={2} />
                Modifier
              </MenuItem>
              <MenuItem
                fontSize={{ sm: '10px', lg: '16px' }}
                onClick={() => onDelete(info.row.original._id)}
              >
                <DeleteIcon mr={2} />
                Supprimer
              </MenuItem>
            </MenuList>
          </Menu>
          <EditService
            isOpen={isEditModalOpen}
            onClose={closeEditModal}
            onEditService={fetchServices}
            serviceId={selectedServiceId || ''}
          />
        </Box>
      ),
    }),
  ];

  const table = useReactTable({
    data: currentItems,
    columns: columns as any,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  const toast = useToast();

  const onDelete = async (serviceId: string) => {
    const accessToken = localStorage.getItem('token');
    if (!accessToken) {
      console.error('Access token not found in local storage');
      return;
    }

    try {
      const response = await apiClient.delete(`/api/service/${serviceId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 200) {
        toast({
          title: "Service supprimé avec succès",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        await fetchServices();
      } else {
        console.error('Error deleting service. Status:', response.status);
      }
    } catch (error) {
      console.error('Error deleting service:', error);
    }
  };

  return (
    <Box
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
      display="flex"
      maxWidth="100%"
      overflow="auto"
      borderWidth="1px"
      boxShadow="5px 14px 10px 0px rgba(128, 128, 128, 0.5)"
      padding="4"
      borderRadius="xl"
      flexDirection="column"
      bg={"white"}
    >
      {isLoading ? (
        <Flex justifyContent="center" align="center" height="200px" fontSize="20px">
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" /> Chargement ...
        </Flex>
      ) : (
        <>
          <Flex justifyContent="space-between" alignItems="center" mb="4">
            <Text color={textColor} fontSize="24px" mb="4px" fontWeight="700" lineHeight="100%">
              Liste des services
            </Text>
            <Button onClick={handleAddService} colorScheme="blue" size="lg" mb="4">
              Ajouter Service
            </Button>
            <ServiceAddModal isOpen={isAddModalOpen} onClose={closeAddModal} onAddService={onAddService} />
          </Flex>
          <Input
            placeholder="Rechercher un service..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            mb="4"
          />
          <Table variant="simple" color="gray.500" mb="24px" mt="12px">
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      pe="10px"
                      borderColor={borderColor}
                      cursor="pointer"
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <Flex
                        justifyContent="space-between"
                        align="center"
                        fontSize={{ sm: '10px', lg: '20px' }}
                        color="gray.400"
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted() as string] ?? null}
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table.getRowModel().rows?.map((row, rowIndex) => (
                <Tr key={row?.id}>
                  {row?.getVisibleCells().map((cell) => (
                    <Td
                      key={cell.id}
                      fontSize={{ sm: '20px', lg: "20px" }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      borderBottomWidth="1px"
                      backgroundColor={`rgba(127, 204, 252, ${rowIndex % 2 === 0 ? 0 : 0.1})`}
                      borderStyle="solid"
                      fontWeight="bold"
                      color={textColor}
                      verticalAlign="middle">
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Flex justifyContent="space-between" align="center" mt="4">
            <Button
              onClick={() => handlePageChange(currentPage - 1)}
              isDisabled={currentPage === 1}
              colorScheme="blue"

            >
              Précédent
            </Button>
            <Text fontSize="lg">
              Page {currentPage} sur {totalPages}
            </Text>
            <Button
              onClick={() => handlePageChange(currentPage + 1)}
              isDisabled={currentPage === totalPages}
              colorScheme="blue"

            >
              Suivant
            </Button>
          </Flex>
        </>
      )}
    </Box>
  );
};

export default ServiceTable;
