import { Box, Tab, TabList, TabPanel, TabPanels, Tabs, useColorModeValue } from '@chakra-ui/react';
import React, { useState } from 'react';
import KineTable from './components/tables/kinetable';
import Medtable from './components/tables/medtable';
import TreatmentTable from './components/tables/treatment';

export default function Params() {
  const [selectedTable, setSelectedTable] = useState<'kine' | 'med' | 'treatment'>('kine');
  const textColor = useColorModeValue('blue.500', 'blue.200');

  const handleTabChange = (index: number) => {
    switch (index) {
      case 0:
        setSelectedTable('kine');
        break;
      case 1:
        setSelectedTable('med');
        break;
      case 2:
        setSelectedTable('treatment');
        break;
      default:
        break;
    }
  };

  return (
    <Box marginTop="80px">
      <Tabs onChange={handleTabChange}>
        <TabList>
          <Tab _selected={{ color: textColor, bg: useColorModeValue('gray.100', 'gray.700') }}>
            {`Kinés`}
          </Tab>
          <Tab _selected={{ color: textColor, bg: useColorModeValue('gray.100', 'gray.700') }}>
            {`Médecins`}
          </Tab>
          <Tab _selected={{ color: textColor, bg: useColorModeValue('gray.100', 'gray.700') }}>
            {`Traitements`}
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            {selectedTable === 'kine' && <KineTable />}
          </TabPanel>
          <TabPanel>
            {selectedTable === 'med' && <Medtable />}
          </TabPanel>
          <TabPanel>
            {selectedTable === 'treatment' && <TreatmentTable />}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}
  