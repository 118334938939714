import React from 'react';
import { Input, InputGroup, InputRightElement, Select, Box } from '@chakra-ui/react';

type SearchBarProps = {
  onSearch: (searchQuery: string, searchCategory: string) => void;
  selectedCategory: string;
  setSelectedCategory: (category: string) => void;
};

const SearchBarPEC: React.FC<SearchBarProps> = ({
  onSearch,
  selectedCategory,
  setSelectedCategory,
}) => {
  const handleCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCategory(event.target.value);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchQuery = event.target.value;
    onSearch(searchQuery, selectedCategory);
  };

  return (
    <form>
      <InputGroup marginLeft="-50%" >
      <Box width="150%" marginRight={12} >
        <Input
          width="80%"
          type="text"
          name="searchField"
          placeholder={`Recherche par`}
          onChange={handleInputChange}
          opacity="1"
        />
        </Box>
        <Box width="50px" > 
        <InputRightElement width="40%" marginLeft={2}>
          <Select
            value={selectedCategory}
            onChange={handleCategoryChange}
            
          >
            <option value="fullName">Nom</option>
             <option value="email">Mail</option>
            <option value="role">Role</option>

          </Select>
        </InputRightElement>
        </Box>
      </InputGroup>
    </form>
  );
};

export default SearchBarPEC;
