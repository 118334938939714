import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  useToast,
} from '@chakra-ui/react';
import apiClient from 'views/admin/appclient';

interface ServiceAddModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAddService: () => void;
}

const ServiceAddModal: React.FC<ServiceAddModalProps> = ({ isOpen, onClose, onAddService }) => {
  const [nomService, setNomService] = useState('');
  const [prix, setPrix] = useState('');
  const [duree, setDuree] = useState('');
  const [type, setType] = useState('');
  const [types, setTypes] = useState<string[]>([]);
  const [newType, setNewType] = useState('');

  useEffect(() => {
    const fetchTypes = async () => {
      try {
        const response = await apiClient.get('/api/type', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setTypes(response.data.map((typeObj: { value: string }) => typeObj.value));
      } catch (error) {
        console.error('Error fetching types:', error);
      }
    };

    fetchTypes();
  }, [isOpen]);
  const toast = useToast();

  const handleAddService = async () => {
    const accessToken = localStorage.getItem('token');

    if (!accessToken) {
      console.error('Access token not found in local storage');
      return;
    }
    if (!nomService) {
      toast({
        title: 'Le nom est requis.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
      if (!prix) {
        toast({
          title: 'Le prix est requis.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      return;
    }
    if (!duree) {
      toast({
        title: 'Durée est requis.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    return;
  }
    if (!type) {
      toast({
        title: 'Le type est requis.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    return;
  
    }
    const apiUrl = 'api/service';
    try {
      const response = await apiClient.post(
        apiUrl,
        {
          nom_service: nomService,
          prix: Number(prix),
          duration: duree,
          type,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      onAddService();
      if (response.status === 200) {
        setNomService('');
        setPrix('');
        setDuree('');
        setType('');
        setIsInputVisible(false);
        onClose();
      } else {
        console.error('Error adding service. Status:', response.status);
      }
    } catch (error) {
      console.error('Error adding service:', error);
    }
  };

  const handleAddType = async () => {
    if (!newType) return;

    try {
      const response = await apiClient.post(
        '/api/type',
        { value: newType },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      if (response.status === 201) {
        setTypes([...types, newType]);
        setNewType('');
        setIsInputVisible(false);
      } else {
        console.error('Error adding new type. Status:', response.status);
      }
    } catch (error) {
      console.error('Error adding new type:', error);
    }
  };

  const [password, setPassword] = useState('');
  const [isPasswordPromptOpen, setIsPasswordPromptOpen] = useState(false);
  const [isInputVisible, setIsInputVisible] = useState(false);

  const handlePasswordSubmit = () => {
    if (password === '09383929') {
      setIsInputVisible(true);
      setIsPasswordPromptOpen(false);
      setPassword('');
    } else {
      alert('Incorrect password');
    }
  };

  const handleAjoutTypeChange = (e: { target: { value: React.SetStateAction<string> } }) => {
    if (!isInputVisible) {
      setIsPasswordPromptOpen(true);
    } else {
      setNewType(e.target.value);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={() => {
      setIsInputVisible(false);
      setNewType("")
      onClose();
    }}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Ajouter un nouveau Service</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Nom du Service</FormLabel>
            <Input type="text" value={nomService} onChange={(e) => setNomService(e.target.value)} />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Prix</FormLabel>
            <Input
              type="number"
              value={prix}
              onChange={(e) => setPrix(e.target.value)}
              placeholder="Entrez le prix"
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Durée</FormLabel>
            <Input
              type="number"
              value={duree}
              onChange={(e) => setDuree(e.target.value)}
              placeholder="Entrez la durée"
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Type</FormLabel>
            <Select placeholder="Sélectionnez le type" value={type} onChange={(e) => setType(e.target.value)}>
              {types.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Ajouter un nouveau type</FormLabel>
            <Input
              type="text"
              value={newType}
              onChange={handleAjoutTypeChange}
              placeholder="Entrez un nouveau type"
            />
            <Button mt={2} colorScheme="blue" onClick={handleAddType}>
              Ajouter Type
            </Button>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleAddService}>
            Ajouter Service
          </Button>
          
        </ModalFooter>
      </ModalContent>
      <Modal isOpen={isPasswordPromptOpen} onClose={() => setIsPasswordPromptOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Enter Password</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Password</FormLabel>
              <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handlePasswordSubmit}>Submit</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Modal>

  );
};

export default ServiceAddModal;
