import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Modal from './event';

interface Event {
  key: any;
  start: string;
  end: string;
  description: string;
  color: string;
  services: string[];
}

interface Resource {
  arrousa: string;
  nom_service: string;
  events: Event[];
  type: any;
}

interface SchedulerTableProps {
  resources: Resource[];
  onSubmit: (updatedEvent: Event) => void;
  selectedDate: string;
}

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`;

const Th = styled.th<{ width?: number }>`
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
  background-color: #f2f2f2;
  position: relative;
  width: ${(props) => props.width || 'auto'};
`;

const Td = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
  position: relative;
  height: 60px; /* Adjusted to give enough space for events */
`;

const EventBlock = styled.div<{ color: string; top: number; height: number }>`
  position: absolute;
  width: 100%;
  background-color: ${(props) => props.color};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
`;

const ResizeHandle = styled.div`
  cursor: col-resize;
  height: 100%;
  width: 5px;
  background-color: #ddd;
  position: absolute;
  right: 0;
  top: 0;
`;

const ResetIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M22 12h-4l3-4-3-4h4l-3 4 3 4z" />
    <path d="M2 12h4l-3 4 3 4H2l3-4-3-4z" />
  </svg>
);
const ResetButton = styled.button`
background: none;
border: none;
cursor: pointer;
outline: none;
`;

const SchedulerTable: React.FC<SchedulerTableProps> = ({ resources, selectedDate, onSubmit }) => {
  const hours = Array.from({ length: 13 }, (_, i) => `${i + 8}:00`);
  const uniqueServiceTypes = Array.from(new Set(resources.flatMap(resource => resource.type)));
  const [modalEvent, setModalEvent] = useState<Event | null>(null);
  const [columnWidths, setColumnWidths] = useState<{ [key: number]: number }>({});
  const resizingColumnIndex = useRef<number | null>(null);
  const tableRef = useRef<HTMLTableElement | null>(null);

  const openModal = (event: Event) => {
    setModalEvent(event);
  };

  const closeModal = () => {
    setModalEvent(null);
  };

  const getEventsForHour = (events: Event[], hour: string) => {
    return events.filter(event => {
      const eventDate = event.start.split('T')[0];
      const eventHourStart = parseInt(event.start.split('T')[1].split(':')[0], 10);
      return eventDate === selectedDate && eventHourStart.toString() === hour.split(':')[0];
    });
  };

  const getPositionAndHeight = (start: string, end: string) => {
    const startHour = parseInt(start.split(':')[2], 10);
    const startMinutes = parseInt(start.split(':')[2], 10);
    const endHour = parseInt(end.split(':')[2], 10);
    const endMinutes = parseInt(end.split(':')[2], 10);
    const adjustedStartMinutes = (startHour - 8) * 60 + startMinutes;
    const adjustedEndMinutes = (endHour - 8) * 60 + endMinutes;
    const height = Math.abs(adjustedEndMinutes - adjustedStartMinutes);
    const top = Math.max(adjustedStartMinutes, 0);

    return { top, height };
  };
  useEffect(() => {
    const initialWidths = uniqueServiceTypes.reduce<{ [key: number]: number }>((acc, _, index) => ({
      ...acc,
      [index]: 150, // Default width for each column
    }), {});
    setColumnWidths(initialWidths);
  }, [resources])

  const handleMouseMove = (e: MouseEvent) => {
    if (resizingColumnIndex.current !== null && tableRef.current) {
      const newWidth = e.clientX - tableRef.current.getBoundingClientRect().left - columnWidths[resizingColumnIndex.current];
      setColumnWidths(prevWidths => ({
        ...prevWidths,
        [resizingColumnIndex.current!]: Math.max(newWidth, 50) // Minimum width for a column
      }));
  
      // Prevent text selection
      e.preventDefault();
    }
  };
  const resetColumnWidths = () => {
    const initialWidths = uniqueServiceTypes.reduce<{ [key: number]: number }>((acc, _, index) => ({
      ...acc,
      [index]: 150, // Default width for each column
    }), {});
    setColumnWidths(initialWidths);
  };
  const handleMouseDown = (index: number) => (e: React.MouseEvent) => {
    resizingColumnIndex.current = index;
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  
    // Prevent text selection
    document.body.style.userSelect = 'none';
  };
  
  const handleMouseUp = () => {
    resizingColumnIndex.current = null;
    window.removeEventListener('mousemove', handleMouseMove);
    window.removeEventListener('mouseup', handleMouseUp);
  
    // Re-enable text selection
    document.body.style.userSelect = '';
  };
  return (
    <>
   <ResetButton onClick={() => resetColumnWidths()}>
        <ResetIcon />
      </ResetButton>

      <Table ref={tableRef}>
        <thead>
          <tr>
            <Th width={50}>Time</Th> {/* Set the width of the time column */}
            {uniqueServiceTypes.map((serviceType, index) => (
              <Th key={serviceType} width={columnWidths[index]}>
                {serviceType}
                <ResizeHandle onMouseDown={handleMouseDown(index)} />
              </Th>
            ))}
          </tr>
        </thead>
        <tbody>
          {hours.map((hour) => (
            <tr key={hour}>
              <Td>{hour}</Td>
              {resources.map((resource) => {
                const events = getEventsForHour(resource.events, hour);
                return (
                  <Td key={resource.nom_service} style={{ position: 'relative' }}>
                    {events.map((event, index) => {
                      const { top, height } = getPositionAndHeight(event.start.split('T')[1], event.end.split('T')[1]);
                      const width = 100 / events.length;
                      const left = index * width;
                      return (
                        <EventBlock
                          key={event.key}
                          color={event.color}
                          top={top}
                          onClick={() => openModal(event)}
                          height={height}
                          style={{ width: `${width}%`, left: `${left}%`, position: 'absolute' }}
                        >
                          {event.description}
                        </EventBlock>
                      );
                    })}
                  </Td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </Table>
      {modalEvent && <Modal event={modalEvent} onClose={closeModal} onSubmit={onSubmit} />}
    </>
  );
};

export default SchedulerTable;
