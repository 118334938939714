import React, { useEffect, useState } from "react";
import { Box, Button, Flex, SimpleGrid } from "@chakra-ui/react";
import UsersTable from "./components/userstable";
export default function Users() {
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
      
        <UsersTable users={[]}  /> 
      
      </SimpleGrid>
    </Box>
  );
}