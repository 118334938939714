import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Textarea,
  Radio,
  RadioGroup,
  Stack,
} from '@chakra-ui/react';
import apiClient from 'views/admin/appclient';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

interface SpaAddModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAddSpa: () => void;
}

interface Client {
  _id: string;
  fullName: string;
}

interface Service {
  _id: string;
  nom_service: string;
  prix?: any;
  type?:string ;
}
interface ServiceDetail {
  _id?: string;
  nom_service: string;
  type: string;
  startDate: string;
  duration: number;
  prix: number;
  nbrClient : number ; 
}
interface PackDetail {
  _id:string ;
  name: string;
  price: number;
  description?: string;
  remise?: number;
  image?: string;
  services: ServiceDetail[];
  
}

const SpaAddModal: React.FC<SpaAddModalProps> = ({ isOpen, onClose, onAddSpa }) => {
  const [deposit , setDeposit]= useState('');
  const [prix, setPrix] = useState('');
  const [comment, setComment] = useState('');
  const [clientId, setClientId] = useState('');
  const [color, setColor] = useState('#FF6347');
  const [clients, setClients] = useState<Client[]>([]);
  const [services, setServices] = useState<Service[]>([]);
  const [packs, setPacks] = useState<PackDetail[]>([]);
  const [selectedPackDetails, setSelectedPackDetails] = useState<PackDetail | null>(null);
  const [selectedOption, setSelectedOption] = useState<any>('');

  const toast = useToast();
  const now = new Date().toISOString().slice(0, 16);

  useEffect(() => {
    const fetchClientsAndServices = async () => {
      try {
        const accessToken = localStorage.getItem('token');
        if (!accessToken) {
          throw new Error('Access token not found in local storage');
        }

        const responses = await Promise.all([
          apiClient.get('/api/client', {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }),
          apiClient.get('/api/service', {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }),
          apiClient.get('/api/pack', {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }),
        ]);

        const clientsData: Client[] = responses[0].data;
        const servicesData: Service[] = responses[1].data;
        const packsData: any[] = responses[2].data;
        setPacks(packsData);
        setClients(clientsData);
        setServices(servicesData);
      } catch (error) {
        console.error('Error fetching clients and services:', error);
      }
    };

    if (isOpen) {
      fetchClientsAndServices();
    }
  }, [isOpen]);



  const handleAddSpa = async () => {
    const accessToken = localStorage.getItem('token');
  
    if (!accessToken) {
      console.error('Access token not found in local storage');
      return;
    }
  
    // Check if user selected either Pack or Services
    if (!selectedOption) {
      toast({
        title: 'Veuillez sélectionner un pack ou un service !',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
  
    // Check if client is selected
    if (!clientId) {
      toast({
        title: 'Le client est requis !',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
  
    let dataToSend = {};
  
    if (selectedOption === 'pack') {
      // Ensure pack details are selected
      if (!selectedPackDetails) {
        toast({
          title: 'Veuillez sélectionner un pack !',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }
  
      // Ensure all start dates are filled
      const missingDates = selectedPackDetails.services.filter(service => !service.startDate);
      if (missingDates.length > 0) {
        toast({
          title: 'Veuillez entrer les dates de début pour tous les services dans le pack',
          status: 'warning',
          duration: 3000,
          isClosable: true,
        });
        return;
      }
  
      dataToSend = {
        packs: selectedPackDetails,
        prix: selectedPackDetails.price,
        comment,
        client: clientId,
        color: color ? color : "#FF6347",
        deposit: deposit,
      };
  
    } else if (selectedOption === 'service') {
      // Ensure service details are selected
      if (selectedServiceDetails.length === 0) {
        toast({
          title: 'Veuillez sélectionner au moins un service !',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }
  
      // Ensure all start dates are filled
      const missingDates = selectedServiceDetails.filter(service => !service.startDate);
      if (missingDates.length > 0) {
        toast({
          title: 'Veuillez entrer les dates de début pour tous les services sélectionnés',
          status: 'warning',
          duration: 3000,
          isClosable: true,
        });
        return;
      }
  
      const formattedServices = selectedServiceDetails.map(service => ({
        type: service.type,
        startDate: new Date(service.startDate).toISOString(),
        duration: service.duration,
        prix: service.prix,
        nom_service: service.nom_service,
        nbrClient: service.nbrClient ? service.nbrClient : 1,
      }));
  
      dataToSend = {
        services: formattedServices,
        prix: parseFloat(prix),
        comment,
        client: clientId,
        color: color ? color : "#FF6347",
        deposit: deposit,
      };
  
    } else {
      toast({
        title: 'Sélection invalide !',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
  
    try {
      console.log({ dataToSend });
      const response = await apiClient.post(
        '/api/spa',
        dataToSend,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
  
      if (response.status === 201) {
        onAddSpa();
        resetForm();
        onClose();
        toast({
          title: 'Spa ajouté avec succès',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } else {
        console.error('Error adding spa. Status:', response.status);
      }
    } catch (error) {
      console.error('Error adding spa:', error);
    }
  };
  
  

  const resetForm = () => {
    setPrix('');
    setComment('');
    setClientId('');
    setSelectedServiceDetails([]);
    setColor('#FF6347');
   setSelectedOption(null) 
setSelectedPackDetails(null)
  };
  
  const handleClose = () => {
    resetForm();
    onClose();
  };

  const clientOptions = clients.map(client => ({
    value: client._id,
    label: client.fullName,
  }));

  const serviceOptions = services.map(service => ({
    value: service._id,
    label: service.nom_service,
  }));
  const [selectedServiceDetails, setSelectedServiceDetails] = useState<ServiceDetail[]>([]);

  const handleServiceSelect = (selectedOptions: any) => {
    const newSelectedServiceDetails: any[] = selectedOptions.map((option: any) => {
      const service = services.find(s => s._id === option.value);
      return {
        nom_service: service?.nom_service || '',
        type: service?.type || '',
        startDate: '',
        duration: 0,
        prix: service?.prix || 0 ,
        nbrClient :  1,
      };
    });
    setSelectedServiceDetails(newSelectedServiceDetails);
  };
  const handleServiceDetailsChange = (type: string, field: string, value: string | number) => {
    setSelectedServiceDetails(prevDetails => {
      return prevDetails.map(detail => {
        if (detail.type === type) {
          return {
            ...detail,
            [field]: typeof value === 'number' ? value.toString() : value // Convert duration to string
          };
        }
        return detail;
      });
    });
  };
  
  

  useEffect(() => {
    let totalPrice;
  
    if (selectedOption === 'pack' && selectedPackDetails) {
      // If a pack is selected, calculate its total price
      totalPrice = selectedPackDetails.services.reduce((acc, detail) => acc + detail.prix, 0);
    } else {
      // If individual services are selected, calculate their total price
      totalPrice = selectedServiceDetails.reduce((acc, detail) => {
        return acc + (detail.prix * (detail.nbrClient || 1)); // Multiply price by number of clients
      }, 0);
    }
    setPrix(totalPrice.toString());
  }, [selectedServiceDetails, selectedPackDetails, selectedOption]);
  
  
  const groupBy = <T, K extends keyof T>(array: T[], key: K) => {
    return array.reduce<Record<string, T[]>>((result, currentValue) => {
      const groupKey = String(currentValue[key]);
      if (!result[groupKey]) {
        result[groupKey] = [];
      }
      result[groupKey].push(currentValue);
      return result;
    }, {});
  };
  
  const addNewClient = async (fullName: string) => {
    const accessToken = localStorage.getItem('token');
    
    if (!accessToken) {
      console.error('Access token not found in local storage');
      toast({
        title: 'Access token is missing!',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
  
    try {
      const response = await apiClient.post(
        '/api/client',
        { fullName },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
  
      if (response.status === 201) {
        const newClient = response.data;
        setClients(prevClients => [...prevClients, newClient]);
        setClientId(newClient._id);
        toast({
          title: 'New client added successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } else {
        console.error('Error adding new client. Status:', response.status);
        toast({
          title: 'Error adding client',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error adding new client:', error);
      toast({
        title: 'Error adding client',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };
  
  const groupByPack = (array: any[], key: string) => {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
      return result;
    }, {});
  };
 
  const handlePackSelect = (selectedOption: any) => {
    const pack = packs.find(p => p._id === selectedOption.value);
    
    if (pack) {
      const packDetails: PackDetail = {
        _id :pack._id , 
        name: pack.name, // Use the pack name
        price: pack.price, // Use the pack price
        description: pack.description || '', // Use the pack description, if available
        remise: pack.remise || 0, // Use the pack remise, if available
        image: pack.image || '', // Use the pack image, if available
        services: pack.services.map((service: any) => ({
          nom_service: service.nom_service,
          type: service.type,
          startDate:service.startDate,  // Initial empty value for the start date
          duration: service.duration,  // Default duration to 0
          prix: service.prix || 0 ,
          nbrClient : service.nbClient || 1 
        }))
      };
  
      setSelectedPackDetails(packDetails);
    }
  };
  
  const handleServicePackDetailsChange = (type: string, field: string, value: string | number) => {
    if (!selectedPackDetails) {
      return;
    }
      const updatedServices = selectedPackDetails.services.map(service => {
      if (service.type === type) {
        return {
          ...service,
          [field]: value
        };
      }
      return service;
    });
    setSelectedPackDetails(prevDetails => {
      const updated = prevDetails ? { ...prevDetails, services: updatedServices } : null;
      return updated;
    });
    console.log({updatedServices})
  };
  
  const packOptions = packs.map(pack => ({
    value: pack._id,
    label: pack.name, 
  }));
    

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Ajouter un nouveau spa</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
        <FormControl mt={4} isRequired>
  <FormLabel>Client</FormLabel>
  <CreatableSelect
    options={clientOptions}
    value={clientOptions.find(option => option.value === clientId)}
    onChange={option => setClientId(option?.value || '')}
    onCreateOption={label => addNewClient(label)}
    placeholder="Saisissez un nom de client"
    noOptionsMessage={() => "Aucune option disponible"}
    formatCreateLabel={inputValue => `Créer nouveau client : "${inputValue}"`}
  />
</FormControl>

<FormControl mt={4} isRequired>
  <FormLabel>Choisissez l'option</FormLabel>
  <RadioGroup onChange={setSelectedOption} value={selectedOption}>
    <Stack direction="row">
      <Radio value="pack">Pack</Radio>
      <Radio value="service">Service</Radio>
    </Stack>
  </RadioGroup>
</FormControl>

{selectedOption === 'service' && (
  <>
    <FormControl mt={4}>
      <FormLabel>Services</FormLabel>
      <Select
        isMulti
        options={serviceOptions}
        noOptionsMessage={() => "Aucune option disponible"}
        placeholder="Entrez nom de Service"

        onChange={handleServiceSelect}
      />
    </FormControl>
    {Object.entries(groupBy(selectedServiceDetails, 'type')).map(([type, services], index) => (
      <div key={type}>
        <FormLabel mt={4}>{type}</FormLabel>
        {services.length > 0 && (
          <div>
                <FormControl mt={4}>
              <FormLabel>Nombre de clients</FormLabel>
              <Input
                type="number"
                value={services[0].nbrClient}
                onChange={e => handleServiceDetailsChange(type, 'nbrClient', e.target.value)}
                onWheel={e => e.currentTarget.blur()}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Date de début</FormLabel>
              <Input
                type="datetime-local"
                value={services[0].startDate} // Use the startDate of the first service in the group
                onChange={e => handleServiceDetailsChange(type, 'startDate', e.target.value)}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Durée (minutes)</FormLabel>
              <Input
    type="number"
    value={services[0].duration || ''}
    onChange={e => {
        const value = parseInt(e.target.value);
        if ( value === 0) {
            console.warn('Duration must be a non-zero number.');
            e.target.style.borderColor = 'red';
          } else { 
            e.target.style.borderColor = '';
            handleServiceDetailsChange(type, 'duration', value);
        }
    }}
    
    onBlur={e => {
        if (e.target.value.trim() === '') {
            console.warn('Duration is required.');
            e.target.style.borderColor = 'red'; 
        } else {
            e.target.style.borderColor = ''; 
        }
    }}
    onWheel={e => e.preventDefault()}

/>

            </FormControl>
          </div>
        )}
      </div>
    ))}
  </>
)}
{selectedOption === 'pack' && (
  <>
    <FormControl mt={4}>
      <FormLabel>Packs</FormLabel>
      <Select
        options={packOptions}
        onChange={handlePackSelect}
        noOptionsMessage={() => "Aucune option disponible"}
        placeholder="Entrez Pack"


      />
    </FormControl>
    {selectedPackDetails && Object.entries(groupByPack(selectedPackDetails.services, 'type')).map(([type, services], index) => {
      const serviceArray = services as ServiceDetail[];
      return (
        <div key={type}>
          <FormLabel mt={4}>{type}</FormLabel>
          {serviceArray.length > 0 && (
            <div>
                 <FormControl mt={4}>
              <FormLabel>Nombre de clients</FormLabel>
              <Input
                type="number"
                value={serviceArray[0].nbrClient}
                onChange={e => handleServiceDetailsChange(type, 'nbrClient', e.target.value)}
                onWheel={e => e.currentTarget.blur()}
              />
            </FormControl>
              <FormControl mt={4}>
                <FormLabel>Date de début</FormLabel>
                <Input
                  type="datetime-local"
                  value={serviceArray[0].startDate} 
                  onChange={e => handleServicePackDetailsChange(type, 'startDate', e.target.value)}
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Durée (minutes)</FormLabel>
                <Input
                   onWheel={e => e.preventDefault()}
                   onBlur={e => {
                    if (e.target.value.trim() === '') {
                        console.warn('Duration is required.');
                        e.target.style.borderColor = 'red'; 
                    } else {
                        e.target.style.borderColor = ''; // Reset border color
                    }
                }}
                  type="number"
                  value={serviceArray[0].duration} 
                  onChange={e => {
                    const value = parseInt(e.target.value);
                    if ( value === 0) {
                        console.warn('Duration must be a non-zero number.');
                        e.target.style.borderColor = 'red'; 
                      } else {
                        e.target.style.borderColor = '';
                        handleServicePackDetailsChange(type, 'duration', value);
                    }
                }}
                />
              </FormControl>
            </div>
          )}
        </div>
      );
    })}
  </>
)}

         
          <FormControl mb={4}>
            <FormLabel>Couleur</FormLabel>
            <Input
              type="color"
              name="color"
              value={color}
              onChange={e => setColor(e.target.value)}
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Avance</FormLabel>
            <Input
              type="number"
              name="deposit"
              value={deposit}
              onChange={e => setDeposit(e.target.value)}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Prix</FormLabel>
            <Input type="text" value={prix} readOnly />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Commentaire</FormLabel>
            <Textarea  value={comment} onChange={e => setComment(e.target.value)} />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleAddSpa}>
            Ajouter Spa
          </Button>
          <Button colorScheme="gray" onClick={handleClose}>
            Annuler
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SpaAddModal;
