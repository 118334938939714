import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  Icon,
  Text,
  Box,
  Flex,
  Heading,
  Checkbox,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import apiClient from "../../admin/appclient"; // Adjust the path according to your structure
import { useHistory } from "react-router-dom"; // Import useHistory if you're using react-router

interface SignInModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const SignInModal: React.FC<SignInModalProps> = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const toast = useToast();
  const history = useHistory(); // Use useHistory to redirect after login

  const handleSignIn = async () => {
    try {
      const response = await apiClient.post("/api/user/login", {
        email,
        password,
      });

      if (response.status === 200) {
        if (response.data.accessToken) {
          localStorage.setItem("token", response.data.accessToken);
          history.push("/admin/default"); // Redirect after successful login
        } else {
          console.log("Token not found in the response");
        }
      } else if (response.status === 442 && Array.isArray(response.data.errors)) {
        const errorMessages = response.data.errors.map((error: any) => error.msg);
        toast({
          title: "Échec de la connexion",
          description: errorMessages.join("\n"),
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: response.data.message || "Échec de la connexion",
          description: "Vous avez été déconnecté avec succès.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        console.log("Échec de la connexion");
      }
    } catch (error: any) {
      console.error(error);
      toast({
        title: `Échec de la connexion : ${error.response?.data?.message}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      console.log("Échec de la connexion");
    }
  };

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!email || !password) {
      toast({
        title: "Échec de la connexion",
        description: "L'email et le mot de passe sont requis.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setIsLoading(true);
    try {
      await handleSignIn();
    } finally {
      setIsLoading(false);
    }
  };

  const handleClick = () => setShowPassword(!showPassword);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent mt={5} p={5}>
        <ModalHeader>Connexion</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box mb={4}>
            <Heading fontSize="lg" mb={2}>
              Vous pouvez vous connecter uniquement si vous avez déjà un compte administrateur ou d'équipe !
            </Heading>
            <Text mb={4}>
              Veuillez contacter l'administrateur pour créer un nouveau compte
            </Text>
          </Box>
          <form onSubmit={handleFormSubmit}>
            <FormControl mb={4}>
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Entrez votre email"
                required
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Mot de passe</FormLabel>
              <InputGroup>
                <Input
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Entrez votre mot de passe"
                  required
                />
                <InputRightElement>
                  <Icon as={showPassword ? RiEyeCloseLine : MdOutlineRemoveRedEye} onClick={handleClick} />
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <Flex justifyContent="space-between" align="center" mb={4}>
              <FormControl display="flex" alignItems="center">
                <Checkbox id="remember-login" colorScheme="teal" />
                <FormLabel htmlFor="remember-login" mb="0" fontSize="sm">
                  Rester connecté
                </FormLabel>
              </FormControl>
            </Flex>
            {isLoading ? (
              <Flex align="center" justify="center" direction="column">
                <Spinner thickness="3px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
                <Text mt="4" fontWeight="bold">En cours...</Text>
              </Flex>
            ) : (
              <Button
                colorScheme="teal"
                type="submit"
                width="100%"
                mt={4}
              >
                Se Connecter
              </Button>
            )}
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SignInModal;
