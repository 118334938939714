import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Spinner,
  Flex,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useToast,
  Input,
} from "@chakra-ui/react";
import { createColumnHelper, flexRender, getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from '@tanstack/react-table';
import { DeleteIcon, EditIcon, HamburgerIcon } from '@chakra-ui/icons';
import apiClient from 'views/admin/appclient';
import PackAddModal from '../addspa/addpack';
import EditPack from '../editspa/editpack';

type Pack = {
  _id: string;
  name: string;
  price: number;
  description: string;
  remise?: number;
  image: string;
  services: string[];
  actions: JSX.Element;
};

const PackTable: React.FC = () => {
  const [packs, setPacks] = useState<Pack[]>([]);
  const [filteredPacks, setFilteredPacks] = useState<Pack[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const columnHelper = createColumnHelper<Pack>();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedPackId, setSelectedPackId] = useState<string>('');
  const accessToken = localStorage.getItem('token');

  useEffect(() => {
    fetchPacks();
  }, []);

  useEffect(() => {
    if (packs.length > 0) {
      // Apply search filter and update pagination
      const filtered = packs.filter(pack =>
        pack.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredPacks(filtered);
      setTotalPages(Math.ceil(filtered.length / itemsPerPage));
    }
  }, [packs, searchTerm, itemsPerPage]);

  const fetchPacks = async () => {
    try {
      if (!accessToken) {
        console.error('Access token not found in local storage');
        return;
      }
      const response = await apiClient.get('api/pack', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      
      // Fetch details of each service by its ID
      const packsWithServices = await Promise.all(
        response.data.map(async (pack: { services: any[]; }) => {
          const serviceDetails = await Promise.all(
            pack.services.map(async (serviceId) => {
              try {
                const accessToken = localStorage.getItem('token');
                if (!accessToken) {
                  console.error('Access token not found in local storage');
                  return;
                }
             
                const serviceResponse = await apiClient.get(`api/service/${serviceId}`, 
                { headers: { Authorization: `Bearer ${accessToken}` } });
                return serviceResponse.data;
              } catch (error) {
                console.error(`Error fetching service ${serviceId}:`, error);
                return null;
              }
            })
          );
          return {
            ...pack,
            services: serviceDetails.filter(Boolean),
          };
        })
      );
      
      setPacks(packsWithServices);
    } catch (error) {
      console.error('Error fetching packs:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddPack = () => {
    setIsAddModalOpen(true);
  };

  const closeAddModal = () => {
    setIsAddModalOpen(false);
  };

  const onAddPack = () => {
    fetchPacks();
    closeAddModal();
  };

  const handleEditPack = (packId: string) => {
    setSelectedPackId(packId);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setSelectedPackId('');
    setIsEditModalOpen(false);
  };

  const toast = useToast();

  const onDelete = async (packId: string) => {
    const accessToken = localStorage.getItem('token');
    if (!accessToken) {
      console.error('Access token not found in local storage');
      return;
    }
    try {
      const response = await apiClient.delete(`/api/pack/${packId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
  
      if (response.status === 200) {
        await fetchPacks();
        toast({
          title: "Pack supprimé avec succès",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        console.error('Error deleting pack. Status:', response.status);
      }
    } catch (error) {
      console.error('Error deleting pack:', error);
    }
  };

  const columns = [
    columnHelper.accessor("name", {
      id: "name",
      header: "Nom",
    }),
    columnHelper.accessor("price", {
      id: "price",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "20px" }}
          color='blue.500'
        >
          Prix
        </Text>
      ),
    }),
    columnHelper.accessor("description", {
      id: "description",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "20px" }}
          color='blue.500'
        >
          Description
        </Text>
      ),
    }),
    columnHelper.accessor("remise", {
      id: "remise",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "20px" }}
          color='blue.500'
        >
          Remise
        </Text>
      ),
    }),
    columnHelper.accessor("image", {
      id: "image",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "20px" }}
          color='blue.500'
        >
          Image
        </Text>
      ),
      cell: (info: any) => (
        <Box>
          <img src={info.getValue()} alt="pack image" width="100" />
        </Box>
      ),
    }),
    columnHelper.accessor("services", {
      id: "service",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "20px" }}
          color='blue.500'
        >
          Service
        </Text>
      ),
      cell: (info: any) => (
        <Box>
          <Text fontSize="16px" textAlign="center">
            {info.row.original.services.map((service: any, index: number) => (
              <span key={service._id}>
                {service.nom_service}{index < info.row.original.services.length - 1 ? ' - ' : ''}
              </span>
            ))}
          </Text>
        </Box>
      ),
    }),
    columnHelper.accessor("actions", {
      id: "actions",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "20px" }}
          color='blue.500'
        >
          Actions
        </Text>
      ),
      cell: (info: any) => (
        <Box display="flex" alignItems="center" marginLeft="10%">
          <Menu>
            <MenuButton as={Button} fontSize="16px" colorScheme="gray">
              <HamburgerIcon />
            </MenuButton>
            <MenuList>
              <MenuItem fontSize="16px" onClick={() => handleEditPack(info.row.original._id)}>
                <EditIcon mr={2} />
                Modifier
              </MenuItem>
              <MenuItem fontSize="16px" onClick={() => onDelete(info.row.original._id)}>
                <DeleteIcon mr={2} />
                Supprimer
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      ),
    }),
  ];

  const table = useReactTable({
    data: filteredPacks.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage),
    columns: columns as any,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset to first page when searching
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  return (
    <Box w="100%" px="0px" overflowX="scroll" display="flex" maxWidth="100%" overflow="auto" borderWidth="1px" boxShadow="5px 14px 10px rgba(128, 128, 128, 0.5)" padding="4" borderRadius="xl" flexDirection="column" bg="white">
      {isLoading ? (
        <Flex justifyContent="center" align="center" height="200px" fontSize="20px">
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" /> Loading
        </Flex>
      ) : (
        <>
          <Flex justifyContent="space-between" alignItems="center" mb="4">
            <Text color={textColor} fontSize="24px" mb="4px" fontWeight="700" lineHeight="100%">
              Liste des Pack
            </Text>
            <Button onClick={handleAddPack} colorScheme="blue" size="lg" mb="4">
              Ajouter pack
            </Button>
            <PackAddModal isOpen={isAddModalOpen} onClose={closeAddModal} onAddPack={onAddPack} />
          </Flex>
          <Input
            placeholder="Search packs..."
            mb="4"
            value={searchTerm}
            onChange={handleSearch}
          />
          <Table variant="simple" color="gray.500" mb="24px" mt="12px">
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Th key={header.id} colSpan={header.colSpan} pe="10px" borderColor={borderColor} cursor="pointer" onClick={header.column.getToggleSortingHandler()}>
                      <Flex justifyContent="space-between" align="center" fontSize="20px" color="gray.400">
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted() as string] ?? null}
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table.getRowModel().rows?.map((row, rowIndex) => (
                <Tr key={row?.id}>
                  {row?.getVisibleCells().map((cell) => (
                    <Td key={cell.id} fontSize="20px" minW="150px" borderBottomWidth="1px" backgroundColor={`rgba(127, 204, 252, ${rowIndex % 2 === 0 ? 0 : 0.1})`} borderStyle="solid" fontWeight="bold" color={textColor}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Flex justifyContent="space-between" align="center" mt="4">
            <Button
              onClick={() => handlePageChange(currentPage - 1)}
              isDisabled={currentPage === 1}
              colorScheme="blue"
            >
              Précédent
            </Button>
            <Text fontSize="lg">
              Page {currentPage} sur {totalPages}
            </Text>
            <Button
              onClick={() => handlePageChange(currentPage + 1)}
              isDisabled={currentPage === totalPages}
              colorScheme="blue"
            >
              Suivant
            </Button>
          </Flex>
        </>
      )}
      <EditPack
        isOpen={isEditModalOpen}
        onClose={closeEditModal}
        onEditPack={fetchPacks}
        packId={selectedPackId || ''}
      />
    </Box>
  );
};

export default PackTable;
