import React, { useState } from 'react';
import { Button, Flex } from '@chakra-ui/react';
import { render } from 'react-dom';

interface PlanningProps {
  planning: string[];
  startHour: string;
  startSamedi: string; // New prop for startSamedi
  endSamedi: string;
  endHour: string;
}

const Planning: React.FC<PlanningProps> = ({ planning, startHour, endHour, startSamedi, endSamedi }) => {
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedDate, setEditedDate] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Number of items to display per page
// console.log("aa", planning)
  const handleDateChange = (date: Date) => {
    const dateStr = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    setSelectedDate(dateStr);
  };

  const handleEditClick = (date: string) => {
    setIsEditing(true);
    setEditedDate(date);
  };

  const handleSaveClick = () => {
    setSelectedDate(editedDate);
    setIsEditing(false);
  };

  const pageCount = Math.ceil(planning.length / itemsPerPage);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const nextPage = () => {
    if (currentPage < pageCount) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };   

  const renderedRows = planning
  .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
  .map((day, index) => {
    const dayOfWeek = day.substring(0, 6); // Extract the first 6 characters

    return (
      <tr key={index}>
      <td style={{ border: '1px solid black' }}>
        {isEditing && editedDate === day ? (
          <input
            type="text"
            value={editedDate}
            onChange={(e) => setEditedDate(e.target.value)}
          />
        ) : (
          // Render different style for "samedi"
          <span style={{ color: dayOfWeek === 'Samedi' ? 'red' : 'inherit' }}>
            {day}
          </span>
        )}
      </td>
      <td style={{ border: '1px solid black' }}>
        {dayOfWeek === 'Samedi' ? startSamedi : startHour}
      </td>
      <td style={{ border: '1px solid black' }}>
        {dayOfWeek === 'Samedi' ? endSamedi : endHour}
      </td>
    </tr>
    );
  });


  return (
    <>
      <h2>Planning:</h2>
      <table style={{ borderCollapse: 'collapse', width: '100%' }}>
        <thead>
          <tr>
            <th style={{ border: '1px solid black' }}>Day</th>
            <th style={{ border: '1px solid black' }}>Start Hour</th>
            <th style={{ border: '1px solid black' }}>End Hour</th>
          </tr>
        </thead>
        <tbody>
      {renderedRows}

        </tbody>
      </table>
      <Flex justifyContent="center" my={4} position="relative" top={4}>
        <Button
          onClick={prevPage}
          disabled={currentPage === 1}
        >
          Previous
        </Button>
        {Array.from({ length: pageCount }, (_, i) => (
          <Button
            key={i}
            onClick={() => handlePageChange(i + 1)}
            className={i + 1 === currentPage ? 'active-button' : ''}
          >
            {i + 1}
          </Button>
        ))}
        <Button
          onClick={nextPage}
          disabled={currentPage === pageCount}
        >
          Next
        </Button>
      </Flex>
      <style>
        {`
          .active-button {
            background-color: blue;
            color: white;
          }
        `}
      </style>
    </>
  );
};

export default Planning;
