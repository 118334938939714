import * as React from 'react';
import {
  Flex,
  Box,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  useToast,
  Spinner,
  MenuButton,
  MenuList,
  MenuItem,
  Menu
} from '@chakra-ui/react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
// Custom components
import Card from 'components/card/Card';
import { useState } from 'react';
import Pagination from './pagination';
import EditPatient from './EditPatient';
import Search from './search';
import { DeleteIcon, EditIcon, HamburgerIcon } from '@chakra-ui/icons';
import appclient from 'views/admin/appclient';
import PatientDetail from './patientDetail';



// Define the RowObj type
type RowObj = {
	fullName: [string, boolean];
  dob: string;
  gender: string;
  phoneNumber: string;
  email: string;
  socialAssuranceType?: string;
  assuranceNumber?: string;
  assuranceKey?: string;
  job?: string;
  profileImage?: string; // Change this to string
  notes?: string;
  createdAt: Date;
  updatedAt: Date;
  medicalCareList: string[]; // Change this to string[]
  actions: JSX.Element;
};

const PatientsTable = (props: {  page:any ; currentPage : number ; isadded : boolean }) => {
  const {  page  , currentPage , isadded } = props;
  const columnHelper = createColumnHelper<RowObj>();
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const toast = useToast();
  const [isLoading, setIsLoading] = React.useState(true); // State for loading
  const [originalData, setOriginalData] = useState([]);

  const handlePageChange = (selectedPage: number) => {
    const newPage = selectedPage ;
    const adjustedPage = Math.min(newPage, page.totalPages);
    page.page = adjustedPage;

    // You may want to make an API request here using the updated page number
    const apiUrl = `https://www.centre-bennahia.tn/api/patient/all?page=${adjustedPage}`;
    fetchData(apiUrl);
  };

  const onDelete = (patientId: string) => {
    appclient
    .delete("/api/patient", {
      data: { _id: patientId }, 
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(() => {
        // If the deletion is successful, show a success message
        toast({
          title: "Patient supprimer avec succès",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
  
        // Update the local data or fetch updated data from the server
        fetchData(`/api/patient/all?page=${currentPage}`);
      })
      .catch((error) => {
        // If there's an error, show an error message
        console.error("Error deleting patient:", error);
        toast({
          title: "Error deleting patient",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState({
    fullName: "",
    dob: "",
    gender: "M", // Default to Male (M)
    phoneNumber: "",
    email: "",
    job: "",
  });

  const handleEditClick = (patient: any) => {
    setSelectedPatient(patient);
    setIsEditModalOpen(true);
  };
  const handleEditSave = (editedPatient : any) => {
    setIsLoading(true);
    const updatedData = {
      _id: editedPatient._id,
      fullName: editedPatient.fullName,
      dob: editedPatient.dob ? editedPatient.dob : undefined,
      gender: editedPatient.gender ? editedPatient.gender : undefined,
      phoneNumber: editedPatient.phoneNumber ? editedPatient.phoneNumber : undefined,
      email: editedPatient.email ? editedPatient.email : undefined,
      job: editedPatient.job ? editedPatient.job : undefined,

    };
    appclient
    .patch(`/api/patient/`, updatedData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },

    })
    
      .then((response) => {
      
        if (response.status === 201 || response.status === 202 || response.status === 200)  {
     
          const updatedData = data.map((patient) =>
            patient._id === editedPatient._id ? { ...patient, ...editedPatient } : patient
          );
          setData(updatedData);
          setIsEditModalOpen(false);
          fetchData(`https://getways.centrebennahia.tn/api/patient/all?page=${currentPage}`);
          toast({
            title: "Patient modifier avec succes!!",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        } else {
          console.error("Failed to update the patient.");
        }
      })
      .catch((error) => {
        console.error("Error updating patient:", error);
      })
      .finally(() => {
        setIsLoading(false); // Reset loading state after the API call
      });
  };
  const [isPatientDetailOpen, setIsPatientDetailOpen] = useState(false);
  const handleFullNameClick = (patient : any) => {
    setSelectedPatient(patient);
    setIsPatientDetailOpen(true);
  };
  const columns = [
	columnHelper.accessor('fullName', {
	  id: 'fullName',
	  header: () => (
		<Text justifyContent='space-between' textAlign={"center"}align='center' fontSize={{ sm: '10px', lg: '20px' }} color='blue.500'>
		  Nom complet 
		</Text>
	  ),
	  cell: (info) => (
      <Button
        color={textColor}
        fontSize='20px'
        fontWeight='700'
        cursor="pointer"
        onClick={() => handleFullNameClick(info.row.original)}
      >
        {info.getValue()}
      </Button>
    ),
  }),
  columnHelper.accessor('dob', {
	  id: 'dob',
	  header: () => (
		<Text justifyContent='space-between' align='center' fontSize={{ sm: '10px', lg: '20px' }} color='blue.500' >
		  Date de naissance
		</Text>
	  ),
	  cell: (info) => (
		
		  <Text color={textColor} fontSize='20px' fontWeight='700'>
			{info.getValue()}
		  </Text>
	  ),
	}),
  
	
		columnHelper.accessor('phoneNumber', {
	  id: 'phoneNumber',
	  header: () => (
		<Text justifyContent='space-between' align='center' fontSize={{ sm: '10px', lg: '20px' }} color='blue.500' >
		  Numéro
		</Text>
	  ),
	  cell: (info: any) => (
		  <Text color={textColor} fontSize='20px' fontWeight='700'>
			 {info.getValue()}
		  </Text>
	
	  ),
	}),
	  columnHelper.accessor('actions',{
      id: 'actions',
 
      header: () => (
        <Text justifyContent='space-between' fontSize={{ sm: '10px', lg: '20px' }} color='blue.500' >
          Actions
        </Text>
      ),
      cell: (info: any) =>  (
        <Box
        display="flex"
    
        alignItems="center"
        marginLeft="10%"
      >
        <Menu>
          <MenuButton
            as={Button}
         
            fontSize={{ sm: '10px', lg: '16px' }}
            colorScheme="gray"
          >
            {/* Hamburger icon */}
            <HamburgerIcon />
          </MenuButton>
          <MenuList>
            <MenuItem
              fontSize={{ sm: '10px', lg: '16px' }}
              onClick={() => handleEditClick(info.row.original)}
            >
              {/* Edit icon */}
              <EditIcon mr={2} />
              Modify
            </MenuItem>
            <MenuItem
              fontSize={{ sm: '10px', lg: '16px' }}
             
              onClick={() => onDelete(info.row.original._id)}
            >
              {/* Delete icon */}
              <DeleteIcon mr={2} />
              Delete
            </MenuItem>
          </MenuList>
        </Menu>
        </Box>
      ),
    }),
  ];
  const [data, setData] = useState([]);
  const token = localStorage.getItem("token");
  const fetchData = (apiUrl: string) => {
    if (!token) {
      console.error("Token is missing. Please log in to get the token.");
      return;
    }

    appclient
      .get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (!(apiUrl.includes('getAll'))) {
          const fetchedData = response.data.items;
          setData(fetchedData);
       setIsLoading(false);
        }
        if (apiUrl.includes('getAll')) {
          const fetchedData = response.data;
          setOriginalData(fetchedData);
          setIsLoading(false);
        }
      //  const fetchedData = response.data.items;
      //   setData(fetchedData);
      //   setOriginalData(fetchedData);
      //   setIsLoading(false);;
      })
      .catch((error) => {
        console.error("Error fetching patient data:", error);
      });
  };
  if (isadded)
  {
  
    fetchData(`/api/patient/all?page=${currentPage}`);
  }
  React.useEffect(() => {
    fetchData(`/api/patient/getAll`);
  }, []);
  React.useEffect(() => {
    fetchData(`/api/patient/all?page=${currentPage}`);
  }, []);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });
 
  const [selectedCategory, setSelectedCategory] = useState('name');
  const handleSearch = (searchQuery: string, searchCategory: string) => {
    if (!originalData) {
      fetchData('/api/patient/getAll');
      return;
    }
    if (searchQuery.trim() === '') {
      fetchData(`/api/patient/all?page=${currentPage}`);
      return;
    }
    const filteredData = originalData.filter((patient) => {
      const fieldToSearch =
        searchCategory === 'name' ? patient.fullName.toLowerCase() :
        searchCategory === 'phone' ? patient.phoneNumber.toLowerCase() :
        '';
  
      return fieldToSearch.includes(searchQuery.toLowerCase());
    });
    setData(filteredData);
  };
 
  return (
    <>
          <Card flexDirection="column" w="100%" px="0px" overflowX={{ sm: 'scroll', lg: 'hidden' }}>
     <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
          <Text color={textColor} fontSize="22px" mb="4px" fontWeight="700" lineHeight="100%">
            Liste Des Patients 
          </Text>
          <Flex mb="16px" justifyContent="space-between">
            
          <Search onSearch={handleSearch} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} />
  

          </Flex>
        </Flex>
        <Box>
      
          {isLoading ? (
            <Flex justifyContent="center" align="center" height="200px" fontSize="20px">
           <Spinner
  thickness='4px'
  speed='0.65s'
  emptyColor='gray.200'
  color='blue.500'
  size='xl'
/> Loading
            </Flex>
          ) : (
            <Table variant="simple" color="gray.500" mb="24px" mt="12px">
               <Thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <Tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <Th
                          key={header.id}
                          colSpan={header.colSpan}
                          pe="10px"
                          borderColor={borderColor}
                          cursor="pointer"
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          <Flex
                            justifyContent="space-between"
                            align="center"
                            fontSize={{ sm: '10px', lg: '20px' }}
                            color="gray.400"
                          >
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {{
                              asc: '',
                              desc: '',
                            }[header.column.getIsSorted() as string] ?? null}
                          </Flex>
                        </Th>
                      ))}
                    </Tr>
                  ))}
                </Thead>
              <Tbody>
                {table.getRowModel().rows.map((row , rowIndex) => {
                  return (
                    <Tr key={row.id}>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <Td
                            key={cell.id}
                            fontSize={{ sm: '14px' , lg :"20px" }}
                            minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                            borderBottomWidth="1px"
                            backgroundColor={`rgba(127, 204, 252, ${rowIndex % 2 === 0 ? 0 : 0.1})`} 
                            borderStyle="solid"
                          >
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          )}
        </Box>
      </Card>
      <Pagination currentPage={page.page} totalPages={page.totalPages} onPageChange={handlePageChange} />
      <PatientDetail
        isOpen={isPatientDetailOpen}
        onClose={() => setIsPatientDetailOpen(false)}
        patientData={selectedPatient}
      />
      <EditPatient
        patientData={selectedPatient}
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        onSave={handleEditSave}
      />
    </>
  );
};


export default PatientsTable;
