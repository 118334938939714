import React, { useState } from 'react';
import {
  Box,
  Flex,
  Text,
  Icon,
  useColorModeValue,
  Checkbox,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Tooltip,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import Card from 'components/card/Card';
import {  MdDragIndicator } from 'react-icons/md';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import Editpec from './Editpec';


interface Patient {
  _id: string;
  fullName: string;
  phoneNumber: string;
  medicalCare:[any]
}

interface TasksProps {
  patientsToCall: Patient[];
}

const Tasks: React.FC<TasksProps> = ({ patientsToCall }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const itemsPerPage = 6;
  const [selectedPEC, setselectedPEC] = useState<string | null>(null); // State to track the selected patient's ID
  const [showPopup, setShowPopup] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
const [patientName , setPatientName] = useState('');
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const filteredPatients = patientsToCall.filter(
    (patient) =>
      patient?.fullName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      patient?.phoneNumber?.includes(searchTerm)
  );

  const totalPages = Math.ceil(filteredPatients.length / itemsPerPage);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset page when the search term changes
  };

  const handleIconClick = (patient: any) => {
    setShowPopup(true);
    setIsOpen(true);
    setselectedPEC(patient.medicalCare[0]);
    setPatientName(patient.fullName);
   
  };

  const handleClosePopup = () => {
    setselectedPEC(null);
    setShowPopup(false);
    setIsOpen(false);
  };
 
  return (
    <Card p='20px' alignItems='center' flexDirection='column' w='100%' minHeight='70vh'>
      <Flex alignItems='center' w='100%' mb='30px'>
        <Text color={textColor} fontSize='lg' fontWeight='700'>
          Patient sans prise en charge
        </Text>
        <Flex justify='center'>
          <Text as="span" display="inline-block" _hover={{ cursor: 'pointer', color: 'blue.500' }} ml="2" position="relative">
            <Tooltip label="Précedent" fontSize="sm">
              <Button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} mr='2'>
                <FaArrowLeft />
              </Button>
            </Tooltip>
          </Text>
          <Text marginTop={"5%"}>{currentPage} / {totalPages}</Text>
          <Text as="span" display="inline-block" _hover={{ cursor: 'pointer', color: 'blue.500' }} ml="2" position="relative">
            <Tooltip label="Suivant" fontSize="sm">
              <Button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                <FaArrowRight />
              </Button>
            </Tooltip>
          </Text>
        </Flex>
      </Flex>
      <Box px='11px' w='100%' h='100%'>
        <InputGroup mb="4">
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray.300" />
          </InputLeftElement>
          <Input type="text" placeholder="Recherche par nom ou numéro" value={searchTerm} onChange={handleSearchChange} />
        </InputGroup>

        {filteredPatients.slice(indexOfFirstItem, indexOfLastItem).map((patient) => (
          <Flex key={patient._id} w='100%' justify='space-between' mb='20px'>
            <Checkbox me='16px' colorScheme='brandScheme' />
            <Text fontWeight='bold' color={textColor} fontSize='md' textAlign='start'>
              {patient.fullName} - {patient.phoneNumber}
            </Text>
            <Icon ms='auto' as={MdDragIndicator} color='secondaryGray.600' w='24px' h='24px' onClick={() => handleIconClick(patient)} />
          </Flex>
        ))}
        {showPopup && selectedPEC && (
          <Editpec id_pec={selectedPEC}  fullName = {patientName} isOpen={isOpen}  onClose={handleClosePopup} />
        )}
      </Box>
    </Card>
  );
};

export default Tasks;
