import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Textarea,
  Input,
  Select,
  Box,
  FormControl,
  FormLabel,
  Spinner,
} from '@chakra-ui/react';

type EditPatientProps = {
  patientData: any; 
  isOpen: boolean;
  onClose: () => void;
  onSave: (editedData: any) => void;
};
const EditPatient: React.FC<EditPatientProps> = ({ patientData, isOpen, onClose, onSave }) => {
    const [editedData, setEditedData] = useState(patientData);
    const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setEditedData(patientData);
  }, [patientData]);
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const { name, value } = e.target;
      setEditedData({
        ...editedData,
        [name]: value,
      });
    };
  
    const handleGenderChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      const { name, value } = e.target;
      setEditedData({
        ...editedData,
        [name]: value,
      });
    };
  
    const handleSaveClick = (e: React.FormEvent) => {
      e.preventDefault();
      setIsLoading(true);
      console.log("editedData", editedData);
    
      onSave(editedData);
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    };
    
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Modifier Patient</ModalHeader>
            <ModalCloseButton />
            
            <ModalBody>
              <Box p="4" borderWidth="1px" borderRadius="md">
                <form onSubmit={handleSaveClick}>
                  <FormControl>
                    <FormLabel>Nom et prénom</FormLabel>
                    <Input
                      type="text"
                      name="fullName"
                      value={editedData.fullName}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Date de naissance</FormLabel>
                    <Input
                      type="date"
                      name="dob"
                      value={editedData.dob}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Genre</FormLabel>
                    <Select
                      name="gender"
                      value={editedData.gender}
                      onChange={handleGenderChange}
                    >
                      <option value="M">Male</option>
                      <option value="F">Female</option>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel> Numéro </FormLabel>
                    <Input
                      type="number"
                      name="phoneNumber"
                      value={editedData.phoneNumber}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Email</FormLabel>
                    <Input
                      type="email"
                      name="email"
                      value={editedData.email}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Emploi</FormLabel>
                    <Input
                      type="text"
                      name="job"
                      value={editedData.job}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                  <div style={{ marginTop: '10px' }}>
                    <Button type="submit" colorScheme="blue" isLoading={isLoading}>
                    Enregistrer 
                    </Button>
                    <Button type="button" onClick={onClose} colorScheme="gray" ml="2">
                    Annuler
                    </Button>
                  </div>
                </form>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      );
    };
    
    export default EditPatient;