import React from 'react';
import { Input, InputGroup, InputRightElement, Select, Box, useColorModeValue } from '@chakra-ui/react';

type SearchBarMedcinsProps = {
  onSearch: (searchQuery: string, searchCategory: string) => void;
  selectedCategory: string;
  setSelectedCategory: (category: string) => void;
};

const SearchBarMedcins: React.FC<SearchBarMedcinsProps> = ({
  onSearch,
  selectedCategory,
  setSelectedCategory,
}) => {
  const handleCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCategory(event.target.value);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchQuery = event.target.value;
    onSearch(searchQuery, selectedCategory);
  };

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  return (
    <form>
           <InputGroup>
        <Box width="150%" marginRight={12}>
          <Input
            width="80%"
            type="text"
            name="searchField"
            placeholder={`Recherche par`}
            onChange={handleInputChange}
            opacity="1"
            color={textColor}
          />
        </Box>
        <Box width="50px">
          <InputRightElement width="40%" marginLeft={2}>
            <Select value={selectedCategory} onChange={handleCategoryChange}>
              <option value="nom">Nom</option>
              <option value="spécialité">Spécialité</option>
              <option value="mobile">Mobile</option>
              {/* Add more options as needed */}
            </Select>
          </InputRightElement>
        </Box>
      </InputGroup>
    </form>
  );
};

export default SearchBarMedcins;
