import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { createColumnHelper, flexRender, getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from "@tanstack/react-table";
import { HamburgerIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons';

import AddMed from '../addparam/addmed';
import Pagination from 'views/admin/Users/pagination';
import SearchBarMedcins from './searches/SearchBarMedcins';
import MedcinEditModal from '../editparam/MedcinEditModal';
import apiClient from 'views/admin/appclient';

type RowObj = {
  _id: string;
  nom: string;
  tel: string;
  email: string;
  spécialité: string;
  adresse: string;
  hop: string;
  patients: any[]; // Adjust the type based on the actual structure
  medicalCare: any[]; // Adjust the type based on the actual structure
  actions : string
};
interface PageState {
  currentPage: number;
  totalPages: number;
}

const MedTable: React.FC = () => {
  const columnHelper = createColumnHelper<RowObj>();
  const [medicals, setMedicals] = useState<RowObj[]>([]);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const [isLoading, setIsLoading] = useState(true);
  const [sorting, setSorting] = useState<SortingState>([]);
  const toast = useToast();
  const [page, setPage] = useState<PageState>({ currentPage: 1, totalPages: 1 });
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const onDelete = async (kineId : any) => {
    const accessToken = localStorage.getItem('token');
    if (!accessToken) {
      console.error('Access token not found in local storage');
      return;
    }
  
    const response = await apiClient.delete(`/api/medecin/${kineId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.status === 200) {
      toast({
        title: "Médcin supprimer avec succès",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      fetchMedicals(); // Call fetchMedicals directly
    } else {
      console.error('Error deleting kine. Status:', response.status);
    }
  };
const [isdata , setIsdata ] = useState(false) ; 
const accessToken = localStorage.getItem('token');
useEffect (() => {
  fetchMedicals();
}, [accessToken])
  const fetchMedicals = async () => {

    if (!accessToken) {
      console.error('Access token not found in local storage');
      return;
    }

    try {
      const response = await apiClient.get('api/medecin', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    //  if ( response.data.items.length === 0) {
    //     setIsdata(false);
    //   }
    setOriginalData(response.data.items);    
    setPage({
      currentPage: response.data.page,
      totalPages: response.data.totalPages,
    });
    
      setIsLoading(false);
      setMedicals(response.data.items);


    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching Medicals:', error);
    } finally {
      setIsLoading(false);
    }
  };
  const openAddModal = () => {
    setIsAddModalOpen(true);
  };

  const closeAddModal = () => {
    setIsAddModalOpen(false);
  };
  const handleAddMedicalRecord = async () => {
  closeAddModal();
  await fetchMedicals();

    
  };

  const columns = [
    columnHelper.accessor("nom", {
      id: "nom",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "20px" }}
          color='blue.500'
        >
          Nom complet 
        </Text>
      ),
    }),
    columnHelper.accessor("tel", {
      id: "tel",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "20px" }}
          color='blue.500'
        >
          Téléphone
        </Text>
      ),
    }),

    columnHelper.accessor("email", {
      id: "email",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "20px" }}
          color='blue.500'
        >
          Email
        </Text>
      ),
    }),
    columnHelper.accessor("spécialité", {
      id: "spécialité",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "20px" }}
          color='blue.500'
        >
          Spécialité
        </Text>
      ),
    }),
  
    columnHelper.accessor("adresse", {
      id: "adresse",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "20px" }}
          color='blue.500'
        >
          Adresse
        </Text>
      ),
    }),
    columnHelper.accessor("hop", {
      id: "hop",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "20px" }}
          color='blue.500'
        >
          Hôpital
        </Text>
      ),
    }),
    columnHelper.accessor("actions", {
      id: "actions",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "20px" }}
          color='blue.500'
        >
         Actions
        </Text>
      ),
      cell: (info: any) =>  (
        <Box
        display="flex"
        justifyContent="ceter"
        alignItems="center"
        marginLeft="10%"
      >
        <Box>
          <Menu>
            <MenuButton as={Button} fontSize={{ sm: '10px', lg: '16px' }} colorScheme="gray">
              {/* Hamburger icon */}
              <HamburgerIcon />
            </MenuButton>
            <MenuList>
            <MenuItem fontSize={{ sm: '10px', lg: '16px' }} onClick={() => handleEdit(info.row.original._id)}>
               <EditIcon mr={2} />
                    Modifier
             </MenuItem>
              <MenuItem fontSize={{ sm: '10px', lg: '16px' }} onClick={() => onDelete(info.row.original._id)}>
                {/* Delete icon */}
                <DeleteIcon mr={2} />
                Supprimer
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      </Box>
    ),
    }),
  ];
  const handlePageChange = (selectedPage: number) => {
    const adjustedPage = Math.min(selectedPage, page.totalPages);
    const apiUrl = `/api/medecin/?page=${adjustedPage}`; // Corrected endpoint

    apiClient
      .get(apiUrl, { headers: { Authorization: `Bearer ${accessToken}` } })
      .then((response) => {
        setIsLoading(false);
        setMedicals(response.data.items);
  
        setPage({
          currentPage: adjustedPage,
          totalPages: response.data.totalPages,
        });
      })
      .catch((error) => {
        setIsLoading(false);
        console.error('Error fetching Medical records:', error);
        console.log(adjustedPage);
      });
  };
  
  const table = useReactTable({
    data: medicals,
    columns: columns as any,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });
  const [selectedCategory, setSelectedCategory] = useState('nom');
  const [originalData, setOriginalData] = useState([]);

  const handleSearch = (searchQuery: string, searchCategory: string) => {
    const filteredData = originalData.filter((medcin: any) => {
      const fieldToSearch =
        searchCategory === 'nom' ? (medcin && medcin.nom || '').toString().toLowerCase() :
        searchCategory === 'spécialité' ? (medcin && medcin.spécialité || '').toString().toLowerCase() :
        searchCategory === 'mobile' ? (medcin && medcin.mobile || '').toString().toLowerCase() :
          '';
  
 
      const includesQuery = fieldToSearch.includes(searchQuery?.toLowerCase());
  
      return includesQuery;
    });
  
  
    setMedicals(filteredData);
  };
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedMedcinId, setSelectedMedcinId] = useState<string | null>(null);

  // ... existing code

  const handleEdit = (medcinId: string) => {
    setSelectedMedcinId(medcinId);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setSelectedMedcinId(null);
    setIsEditModalOpen(false);
  };

  const handleEditMedicalRecord = async () => {
    closeEditModal();
    await fetchMedicals();
  };

  
  return (
    <>
<Box w="100%" px="0px" overflowX={{ sm: "scroll", lg: "hidden" }} display="flex"
  maxWidth="100%"
  overflow="auto"
  borderWidth="1px"
  boxShadow="5px 14px 10px 0px  rgba(128, 128, 128, 0.5)"  
  padding="4"
  borderRadius="xl" 
  flexDirection="column"
  bg={"white"}>
       {isLoading ? (
          <Flex justifyContent="center" align="center" height="200px" fontSize="20px">
            <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" /> Loading
          </Flex>
        ) : (
          <>
            <Flex justifyContent="space-between" alignItems="center" mb="4">
            <Text
              color={textColor}
              fontSize="24px"
              mb="4px"
              fontWeight="700"
              lineHeight="100%"
            >
              Liste des medecins
            </Text>
        <SearchBarMedcins
          onSearch={handleSearch}  // Replace with your actual search function
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />
           <Button onClick={openAddModal} colorScheme="blue">
                Ajouter médecin 
              </Button>

              <AddMed
                isOpen={isAddModalOpen}
                onClose={closeAddModal}
                onAddMedicalRecord={handleAddMedicalRecord} />
         
            </Flex><Table variant="simple" color="gray.500" mb="24px" mt="12px">
                <Thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <Tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <Th
                          key={header.id}
                          colSpan={header.colSpan}
                          pe="10px"
                          borderColor={borderColor}
                          cursor="pointer"
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          <Flex
                            justifyContent="space-between"
                            align="center"
                            fontSize={{ sm: '10px', lg: '20px' }}
                            color="gray.400"
                          >
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {{
                              asc: "",
                              desc: "",
                            }[header.column.getIsSorted() as string] ?? null}
                          </Flex>
                        </Th>
                      ))}
                    </Tr>
                  ))}
                </Thead>
                <Tbody>
                  {table.getRowModel().rows?.map((row, rowIndex) => (
                    <Tr key={row?.id}>
                      {row?.getVisibleCells().map((cell) => (
                        <Td
                          key={cell.id}
                          fontSize={{ sm: '20px', lg: "20px" }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderBottomWidth="1px"
                          backgroundColor={`rgba(127, 204, 252, ${rowIndex % 2 === 0 ? 0 : 0.1})`}
                          borderStyle="solid"
                          fontWeight="bold"
                          color={textColor}
                          
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </Td>
                      ))}
                    </Tr>
                  ))}
                </Tbody>
              </Table></>)}
              <Flex justify="center">
            <Box>
              <Pagination currentPage={page.currentPage} totalPages={page.totalPages} onPageChange={handlePageChange} />
            </Box>
          </Flex>
      </Box>
      <MedcinEditModal
        isOpen={isEditModalOpen}
        onClose={closeEditModal}
        medcinId={selectedMedcinId || ''}
        onEditMedcin={handleEditMedicalRecord}
      />
    </>
  );
};

export default MedTable;
