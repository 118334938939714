/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Box, Button, Flex, SimpleGrid } from "@chakra-ui/react";
import { useToast } from '@chakra-ui/react';
import PatientsTable from "./components/patientstable"; // Corrected component name
import CheckTable from "../dataTables/components/CheckTable";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton } from "@chakra-ui/react";
import PatientForm from "./components/patientForm";
import NewPECModal from "../PEC/Components/AddPEC";
import { Redirect } from "react-router-dom";
import appclient from 'views/admin/appclient';

export default function Patients() {
  const [patientsPage, setPatientsPage] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const token = localStorage.getItem("token");
  const [isadded , setIsadded] = useState(Boolean)
  const toast = useToast();
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  React.useEffect(() => {
      const token = localStorage.getItem("token");

      if (!token) {
        setIsAuthenticated(false);
        return;
      
    }
    
    fetchData();
  }, [currentPage]);
    const fetchData = () => {
      if (!isAuthenticated) {
        window.location.href = "/signin";
      }

    appclient
      .get(`/api/patient/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
      
        setPatientsPage(response.data);
        console.log(response.data)
      })
      .catch((error) => {
        console.error("Error fetching patient data:", error);
      });
  }
  const [isPatientFormOpen, setIsPatientFormOpen] = useState(false);
const onClose = () => {
  setIsPatientFormOpen(false);
};
const handleClosePatientForm = () => {
    setIsPatientFormOpen(false);
  };
  const handlePatientCreated = () => {
  setIsadded(true);
    setIsPatientFormOpen(false);
    fetchData();
    setIsadded(false);
  };

  const [isPECModalOpen, setPECModalOpen] = useState(false);

  const openPECModal = () => {
    setPECModalOpen(true);
  };

  const closePECModal = () => {
    setPECModalOpen(false);
  };

  return (
  <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
    <SimpleGrid
      mb="20px"
      columns={{ sm: 1, md: 1 }}
      spacing={{ base: "20px", xl: "20px" }}
    > 
       <Flex mt="20px"  justifyContent="space-between" >
   
   
   <Button colorScheme="teal" size="lg" onClick={() => setIsPatientFormOpen(true)}>
Nouv Patient
</Button>

<Button colorScheme="blue" size="lg" onClick={openPECModal}>
     Nouv Prise en Charge
   </Button>

   <NewPECModal isOpen={isPECModalOpen} onClose={closePECModal} refreshData={() => {}}  />
</Flex>
      <PatientsTable  page={patientsPage} currentPage={currentPage} isadded={isadded} />
   
  <Modal isOpen={isPatientFormOpen} onClose={onClose}>
  <ModalOverlay />
  <ModalContent maxW={{ base: '100%', sm: '100%', md: '100%', lg: '80%' }}>
        <ModalHeader color="blue.500" fontSize={"32px"}>Ajouter un patient</ModalHeader>
    
    <ModalCloseButton />
    <ModalBody>
      <PatientForm onClose={handleClosePatientForm} onPatientCreated={handlePatientCreated} />
    </ModalBody>
  </ModalContent>
</Modal>

    </SimpleGrid>
  </Box>
  );
}
