// KineLegend.tsx
import React from 'react';

interface KineLegendProps {
  team: any[];
}

const KineLegend: React.FC<KineLegendProps> = ({ team }) => {
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '10px' }}>
      {team.map(kine => (
        <div key={kine.value} style={{ display: 'flex', alignItems: 'center', marginRight: '15px' }}>
          <div
            style={{
              width: '12px',
              height: '12px',
              borderRadius: '50%',
              backgroundColor: kine.color,
              marginRight: '5px',
            }}
          />
          <span>{kine.label}</span>
        </div>
      ))}
    </div>
  );
};

export default KineLegend;
