import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import Select, { MultiValue } from 'react-select';
import apiClient from 'views/admin/appclient';

type ServiceOption = { value: string, label: string };

type EditPackProps = {
  isOpen: boolean;
  onClose: () => void;
  onEditPack: () => void;
  packId: string;
};

const EditPack: React.FC<EditPackProps> = ({ isOpen, onClose, onEditPack, packId }) => {
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    name: '',
    price: '',
    description: '',
    remise: '',
    image: '',
    services: [] as ServiceOption[],
  });
  const [servicesOptions, setServicesOptions] = useState<ServiceOption[]>([]);
  const toast = useToast();

  const closeEditModal = () => {
    setFormData({
      name: '',
      price: '',
      description: '',
      remise: '',
      image: '',
      services: [],
    });
    onClose();
  };

  const fetchPackDetails = async () => {
    const apiUrl = `/api/pack/${packId}`;
    const servicesUrl = `/api/service`;

    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No token found');

      const [packResponse, servicesResponse] = await Promise.all([
        apiClient.get(apiUrl, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
        }),
        apiClient.get(servicesUrl, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
        })
      ]);

      if (packResponse.status === 200 && servicesResponse.status === 200) {
        const packDetails = packResponse.data;
        const services = servicesResponse.data.map((service: { _id: string; nom_service: string; }) => ({
          value: service._id,
          label: service.nom_service,
        }));

        const selectedServices = packDetails.services.map((serviceId: string) => {
          const service = services.find((s: { value: string; }) => s.value === serviceId);
          return service ? service : { value: serviceId, label: '' };
        });

        setFormData({
          name: packDetails.name,
          price: packDetails.price.toString(),
          description: packDetails.description,
          remise: packDetails.remise ? packDetails.remise.toString() : '',
          image: packDetails.image,
          services: selectedServices,
        });

        setServicesOptions(services);
      } else {
        console.error('Failed to fetch pack details or services');
      }
    } catch (error) {
      console.error('Error fetching pack details or services:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const uploadData = new FormData();
      uploadData.append('file', file);

      try {
        const token = localStorage.getItem('token');
        if (!token) throw new Error('No token found');

        const response = await apiClient.post('/upload', uploadData, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.status === 201) {
          setFormData((prevData) => ({
            ...prevData,
            image: response.data.cloudinaryUrl,
          }));
        } else {
          console.error('Error uploading file:', response.status);
        }
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      setLoading(true);
      fetchPackDetails();
    }
  }, [isOpen, packId]);

  const handleEdit = async () => {
    const apiUrl = `/api/pack/${packId}`;

    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No token found');

      const response = await apiClient.patch(apiUrl, {
        ...formData,
        price: parseFloat(formData.price),
        remise: formData.remise ? parseFloat(formData.remise) : undefined,
        services: formData.services.map(service => service.value),
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });

      if (response && response.status === 200) {
        onEditPack();
        closeEditModal();
        toast({
          title: 'Pack modifié avec succès',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } else {
        console.error('Failed to update pack');
      }
    } catch (error) {
      toast({
        title: 'Pack non modifié',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      console.error('Error updating pack:', error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Modifier Pack</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {loading ? (
            <Spinner size="lg" color="blue.500" />
          ) : (
            <>
              <FormControl>
                <FormLabel>Nom</FormLabel>
                <Input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Prix</FormLabel>
                <Input
                  type="number"
                  name="price"
                  value={formData.price}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Description</FormLabel>
                <Textarea
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Remise</FormLabel>
                <Input
                  type="number"
                  name="remise"
                  value={formData.remise}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Image</FormLabel>
                <Input
                  type="file"
                  name="image"
                  onChange={handleFileChange}
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Image Actuelle</FormLabel>
                {formData.image && <img src={formData.image} alt="Pack Image" style={{ maxWidth: '100%' }} />}
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Services</FormLabel>
                <Select
                  isMulti
                  options={servicesOptions}
                  value={formData.services}
                  onChange={(selectedOptions: MultiValue<ServiceOption>) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      services: selectedOptions as ServiceOption[],
                    }))
                  }
                />
              </FormControl>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleEdit}>
            Modifier
          </Button>
          <Button colorScheme="gray" onClick={closeEditModal}>
            Annuler
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditPack;
