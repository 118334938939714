import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Spinner,
  Flex,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useToast,
  Input
} from "@chakra-ui/react";
import { createColumnHelper, flexRender, getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from '@tanstack/react-table';
import { DeleteIcon, EditIcon, HamburgerIcon } from '@chakra-ui/icons';
import apiClient from 'views/admin/appclient';
import ArrousaAddModal from '../addspa/addarrousa';
import EditArrousa from '../editspa/ediarrousa';

interface IArrousa {
  _id: string;
  name: string;
  price: number;
  description?: string;
  remise?: number;
  services?: string[];
  nbrAtt: number;
  prix_avec_remise: number;
  actions: JSX.Element;
}

const ArrousaTable: React.FC = () => {
  const [arrousas, setArrousas] = useState<IArrousa[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const columnHelper = createColumnHelper<IArrousa>();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [originalData, setOriginalData] = useState<IArrousa[]>([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedArrousaId, setSelectedArrousaId] = useState<string>('');
  const toast = useToast();

  useEffect(() => {
    fetchArrousas();
  }, []);

  const fetchArrousas = async () => {
    try {
      const accessToken = localStorage.getItem('token');
      if (!accessToken) {
        console.error('Access token not found in local storage');
        return;
      }
      const response = await apiClient.get('api/arrousa', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      setArrousas(response.data);
      setOriginalData(response.data);
    } catch (error) {
      console.error('Error fetching Arrousas:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const onDelete = async (serviceId: string) => {
    const accessToken = localStorage.getItem('token');
    if (!accessToken) {
      console.error('Access token not found in local storage');
      return;
    }
    try {
      const response = await apiClient.delete(`/api/arrousa/${serviceId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 200) {
        await fetchArrousas();
        toast({
          title: "Arrousa supprimé avec succès",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        console.error('Error deleting treatment. Status:', response.status);
      }
    } catch (error) {
      console.error('Error deleting treatment:', error);
    }
  };

  const handleAddArrousa = () => {
    setIsAddModalOpen(true);
  };

  const closeAddModal = () => {
    setIsAddModalOpen(false);
  };

  const onAddArrousa = () => {
    fetchArrousas();
    closeAddModal();
  };

  const handleEditArrousa = (arrousaId: string) => {
    setSelectedArrousaId(arrousaId);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setSelectedArrousaId('');
    setIsEditModalOpen(false);
  };

  const filteredData = originalData.filter(arrousa =>
    arrousa.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const columns = [
    columnHelper.accessor("name", {
      id: "name",
      header: () => (
        <Text
          textAlign="center" // Center text horizontally
          fontSize={{ sm: "10px", lg: "20px" }}
          color='blue.500'
        >
          Name
        </Text>
      ),
    }),
    columnHelper.accessor("prix_avec_remise", {
      id: "prix_avec_remise",
      header: () => (
        <Text
          textAlign="center" // Center text horizontally
          fontSize={{ sm: "10px", lg: "20px" }}
          color='blue.500'
        >
          Prix
        </Text>
      ),
    }),
    columnHelper.accessor("description", {
      id: "description",
      header: () => (
        <Text
          textAlign="center" // Center text horizontally
          fontSize={{ sm: "10px", lg: "20px" }}
          color='blue.500'
        >
          Description
        </Text>
      ),
    }),
    columnHelper.accessor("services", {
      id: "services",
      header: () => (
        <Text
          textAlign="center" // Center text horizontally
          fontSize={{ sm: "10px", lg: "20px" }}
          color='blue.500'
        >
          Services
        </Text>
      ),
      cell: (info: any) => (
        <Box>
          <Text fontSize="16px" textAlign="center">
            {info.row.original.services.map((service: any, index: number) => (
              <span key={service._id}>
                {service.nom_service}{index < info.row.original.services.length - 1 ? ' - ' : ''}
              </span>
            ))}
          </Text>
        </Box>
      ),
    }),
    columnHelper.accessor("nbrAtt", {
      id: "nbrAtt",
      header: () => (
        <Text
          textAlign="center" // Center text horizontally
          fontSize={{ sm: "10px", lg: "20px" }}
          color='blue.500'
        >
          Nombre accompagnant
        </Text>
      ),
    }),
    columnHelper.accessor("actions", {
      id: "actions",
      header: () => (
        <Text
          textAlign="center" // Center text horizontally
          fontSize={{ sm: "10px", lg: "20px" }}
          color='blue.500'
        >
          Actions
        </Text>
      ),
      cell: (info: any) => (
        <Box display="flex" justifyContent="center" alignItems="center"> {/* Center content horizontally and vertically */}
          <Menu>
            <MenuButton as={Button} fontSize={{ sm: '10px', lg: '16px' }} colorScheme="gray">
              <HamburgerIcon />
            </MenuButton>
            <MenuList>
              <MenuItem
                fontSize={{ sm: '10px', lg: '16px' }}
                onClick={() => handleEditArrousa(info.row.original._id)}
              >
                <EditIcon mr={2} />
                Modifier
              </MenuItem>
              <MenuItem
                fontSize={{ sm: '10px', lg: '16px' }}
                onClick={() => onDelete(info.row.original._id)}
              >
                <DeleteIcon mr={2} />
                Supprimer
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      ),
    }),
  ];

  const table = useReactTable({
    data: paginatedData,
    columns: columns as any,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Box
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
      display="flex"
      maxWidth="100%"
      overflow="auto"
      borderWidth="1px"
      boxShadow="5px 14px 10px 0px rgba(128, 128, 128, 0.5)"
      padding="4"
      borderRadius="xl"
      flexDirection="column"
      bg={"white"}
    >
      {isLoading ? (
        <Flex justifyContent="center" align="center" height="200px" fontSize="20px">
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" /> Loading
        </Flex>
      ) : (
        <>
          <Flex justifyContent="space-between" alignItems="center" mb="4">
            <Text color={textColor} fontSize="24px" mb="4px" fontWeight="700" lineHeight="100%">
              Pack Arrousa
            </Text>
            <Button onClick={handleAddArrousa} colorScheme="blue" size="lg" mb="4">
              Ajouter Pack Arrousa
            </Button>
          </Flex>
          <Input
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            mb="4"
          />
          <Table variant="simple" color="gray.500" mb="24px" mt="12px">
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      pe="10px"
                      borderColor={borderColor}
                      cursor="pointer"
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <Flex
                        justifyContent="center" // Center the text horizontally
                        align="center"
                        fontSize={{ sm: '10px', lg: '20px' }}
                        color="gray.400"
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted() as string] ?? null}
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table.getRowModel().rows?.map((row, rowIndex) => (
                <Tr key={row?.id}>
                  {row?.getVisibleCells().map((cell) => (
                    <Td
                      key={cell.id}
                      fontSize={{ sm: '20px', lg: "20px" }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      borderBottomWidth="1px"
                      backgroundColor={`rgba(127, 204, 252, ${rowIndex % 2 === 0 ? 0 : 0.1})`}
                      borderStyle="solid"
                      fontWeight="bold"
                      color={textColor}
                      textAlign="center" // Center the text horizontally
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Flex justifyContent="space-between" align="center" mt="4">
            <Button
              onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
              isDisabled={currentPage === 1}
              colorScheme="blue"

            >
              Précédent
            </Button>
            <Text>
              Page {currentPage} sur {totalPages}
            </Text>
            <Button
              onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
              isDisabled={currentPage === totalPages}
              colorScheme="blue"

            >
              Suivant
            </Button>
          </Flex>
        </>
      )}
      <ArrousaAddModal isOpen={isAddModalOpen} onClose={closeAddModal} onAddArrousa={onAddArrousa} />
      <EditArrousa
        isOpen={isEditModalOpen}
        onClose={closeEditModal}
        onEditArrousa={fetchArrousas}
        arrousaId={selectedArrousaId || ''}
      />
    </Box>
  );
};

export default ArrousaTable;
