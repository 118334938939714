    import React, { useState, useEffect } from 'react';
    import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input,
    Switch,
    useToast,
    Spinner,
    } from "@chakra-ui/react";
    import apiClient from 'views/admin/appclient';

    type EditClientProps = {
    isOpen: boolean;
    onClose: () => void;
    onEditClient: () => void;
    clientId: string; // Assuming clientId is a string, adjust as needed
    };

    const EditClient: React.FC<EditClientProps> = ({ isOpen, onClose, onEditClient, clientId }) => {
    const [loading, setLoading] = useState(true);
    const [IsPaid, setIsPaid]= useState(false);

    const [formData, setFormData] = useState({
        fullName: '',
        facebook: '',
        instagram: '',
        email: '',
        phoneNumber: '',
        paymentStatus: false,
    });
    const toast = useToast();

    const closeEditModal = () => {
        setFormData({
        fullName: '',
        facebook: '',
        instagram: '',
        email: '',
        phoneNumber: '',
        paymentStatus: false,
        });
        onClose();
    };

    const fetchClientDetails = async () => {
        const apiUrl = `/api/client/${clientId}`;

        try {
        const token = localStorage.getItem('token');
        const response = await apiClient.get(apiUrl, {
            headers: {
            'Authorization': `Bearer ${token}`
            },
        });

        if (response && response.status === 200) {
            setIsPaid(response.data.paymentStatus); 
            const clientDetails = response.data;
            console.log("clientDetails", clientDetails)
            setFormData({
            fullName: clientDetails.fullName,
            facebook: clientDetails.facebook,
            instagram: clientDetails.instagram,
            email: clientDetails.email,
            phoneNumber: clientDetails.phoneNumber,
            paymentStatus: IsPaid,
            });
          
            console.log("ispaid", IsPaid)
        } else {
            console.error(`Failed to fetch client details. Status: ${response.status}`);
        }
        } catch (error) {
        console.error('Error fetching client details:', error);
        } finally {
        setLoading(false);
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: type === 'checkbox' ? checked : value,
        }));
      };
      
      const handleSwitchChange = () => {
        setIsPaid((prevValue) => !prevValue);
        setFormData((prevData) => ({
          ...prevData,
          paymentStatus: prevData.paymentStatus,
        }));
      };
      

    useEffect(() => {
        if (isOpen) {
        setLoading(true);
        fetchClientDetails();
        }
    }, [isOpen, clientId]);

    const handleEdit = async () => {
        const apiUrl = `/api/client/${clientId}`;

        try {
        const token = localStorage.getItem('token');
        const response = await apiClient.patch(apiUrl, formData, {
            headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
            },
        });

        if (response && response.status === 200) {
            onEditClient();
            onClose();
            toast({
            title: 'Client modifié avec succès',
            status: 'success',
            duration: 3000,
            isClosable: true,
            });
        }
        } catch (error) {
        toast({
            title: 'Client non modifié',
            status: 'error',
            duration: 3000,
            isClosable: true,
        });
        console.error('Error updating client:', error);
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>Modifier Client</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
            {loading ? (
                <Spinner size="lg" color="blue.500" />
            ) : (
                <>
                <FormControl>
                    <FormLabel>Nom complet</FormLabel>
                    <Input
                    type="text"
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleChange}
                    />
                </FormControl>
                <FormControl mt={4}>
                    <FormLabel>Facebook</FormLabel>
                    <Input
                    type="text"
                    name="facebook"
                    value={formData.facebook}
                    onChange={handleChange}
                    />
                </FormControl>
                <FormControl mt={4}>
                    <FormLabel>Instagram</FormLabel>
                    <Input
                    type="text"
                    name="instagram"
                    value={formData.instagram}
                    onChange={handleChange}
                    />
                </FormControl>
                <FormControl mt={4}>
                    <FormLabel>Email</FormLabel>
                    <Input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    />
                </FormControl>
                <FormControl mt={4}>
                    <FormLabel>Numéro de téléphone</FormLabel>
                    <Input
                    type="text"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    />
                </FormControl>
                <FormControl mt={4} display="flex" alignItems="center">
  <FormLabel htmlFor="paymentStatus" mb="0">
    Statut de paiement
  </FormLabel>
  <Switch
    id="paymentStatus"
    name="paymentStatus"
    isChecked={IsPaid}
    onChange={handleSwitchChange}
  />
</FormControl>

                </>
            )}
            </ModalBody>
            <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleEdit}>
                Modifier
            </Button>
            <Button colorScheme="gray" onClick={closeEditModal}>
                Annuler
            </Button>
            </ModalFooter>
        </ModalContent>
        </Modal>
    );
    };

    export default EditClient;
