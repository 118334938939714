import React from 'react';
import Select from 'react-select';

interface Option {
  value: string;
  label: string;
}

interface AutoSelectProps {
  options: Option[];
  label?: string;
  defaultValue?: Option;
  onChange?: (selectedOption: Option) => void;
}
const AutoSelect: React.FC<AutoSelectProps> = ({ label, options, defaultValue, onChange }) => {
  return (
    <div>
    {label && <label>{label}</label>}
    <Select
      options={options}
      defaultValue={defaultValue}
      onChange={(selectedOption) => {
        if (onChange) {
          onChange(selectedOption as Option);
        }
      
      }}
      placeholder={`Select ${label}`}

    />
    </div>
  );
};

export default AutoSelect;
