import React, {  useEffect, useMemo, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  Box,
  Input,
  Button,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Stack,
  Checkbox,
  NumberInputField,
  NumberInput,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import apiClient from 'views/admin/appclient';
import { toast } from 'react-toastify';

interface PatientDetailsModalProps {
    isOpen: boolean;
    onClose: () => void;
    patient: any;
    onAddSeance: (seance: any) => void;
    onDeleteSeance: (seanceId: string , patientId : any , chambreId : any) => void;
}

const PatientDetailsModal: React.FC<PatientDetailsModalProps> = ({ isOpen, onClose, patient , onAddSeance  , onDeleteSeance }) => {
    const [newSeance, setNewSeance] = useState({ patientid : patient?._id , chambreId : patient?.chambreId , date: '', motrice: false, respiratoire:false, paye: false , price: 35});
    const [seancesKey, setSeancesKey] = useState(0);
    const [isModalClosed, setIsModalClosed] = useState(false); // Track modal closed state

    useEffect(() => {
      // Reset page to 1 when modal is closed
      if (!isOpen && isModalClosed) {
        setCurrentPage(1);
        setIsModalClosed(false); // Reset modal closed state
      }
    }, [isOpen, isModalClosed]);
 

  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 5; // Adjust as needed
  const totalPages = Math.ceil(patient?.seances.length / itemsPerPage);

  const paginatedSeances = useMemo(() => {
    // Memoize the paginated seances data
    return patient?.seances.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );
  }, [patient?.seances, currentPage, itemsPerPage]);
  const token = localStorage.getItem('token');
  const headers = { Authorization: `Bearer ${token}` };
  const handleAddSeance = async () => {
    const chambreId = patient?.chambreId;
    const patientId = patient?._id;
  
    if (!newSeance.date) {
      toast.error('Please select a date.'); // Display error toast
      return;
    }
  
    if (newSeance.motrice && newSeance.respiratoire) {
      newSeance.price = 70;
    } else if (newSeance.motrice && !newSeance.respiratoire) {
      newSeance.price = 35;
    } else if (!newSeance.motrice && newSeance.respiratoire) {
      newSeance.price = 35;
    }
  
    const seanceWithoutIds = { ...newSeance };
    delete seanceWithoutIds.patientid;
    delete seanceWithoutIds.chambreId;
  
    try {
      const response = await apiClient.post(`/api/clinique/${chambreId}/${patientId}/seances`, seanceWithoutIds, { headers });
      setSeancesKey((prevKey) => prevKey + 1);
  
      if (!response) {
        throw new Error('Failed to add seance');
      }
  
      const result = await response.data;
      onAddSeance(newSeance);
      
      setSeancesKey((prevKey) => prevKey + 1);
  
       // Display success toast
      setNewSeance({ patientid: patient?._id, chambreId: patient.chambreId, date: '', motrice: false, respiratoire: false, paye: false, price: 35 });
    } catch (error) {
      console.error('Error adding seance:', error);
    // Display error toast
      // Handle error as needed
    }
  };
  

  const handleDeleteSeance = (seanceId: string) => {
    onDeleteSeance(seanceId, patient?._id, patient?.chambreId);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Détails du patient</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing="4">
            <Text fontSize={{ sm: '14px', lg: '20px' }}>
              <strong>Nom du patient:</strong> {patient?.name}
              {/* Add more patient details as needed */}
            </Text>
            <Text fontSize={{ sm: '14px', lg: '20px' }}>
              <strong>Médecin:</strong> {patient?.med || 'Non spécifié'}
            </Text>
            <Text fontSize={{ sm: '14px', lg: '20px' }}>
              <strong>Montant total:</strong> {patient?.totalPrice}
            </Text>
            <Text fontSize={{ sm: '14px', lg: '20px' }}>
              <strong>Paiement effectué:</strong> {patient?.isPaied ? 'Oui' : 'Non'}
            </Text>
            <Box mt="4">
  <Stack direction="column" spacing="4">
    <Stack direction={{ base: 'column', md: 'row' }} spacing="4" align={{ md: 'center' }}>
      <Input
        type="date"
        value={newSeance.date}
        onChange={(e) => setNewSeance({ ...newSeance, date: e.target.value })}
        placeholder="Date"
      />
    <Stack direction={{ base: 'column', md: 'row' }} spacing="4" align={{ md: 'center' }}>
      <label>Prix</label>
      <NumberInput
        value={newSeance.price}
        onChange={(valueString) => setNewSeance({ ...newSeance, price: parseInt(valueString) || 0 })}
        placeholder="Prix"
        min={0} // Set minimum value
        max={1000000} // Set maximum value as needed
      >
        <NumberInputField />
      </NumberInput>
    </Stack>
    </Stack>
    <Stack direction={{ base: 'column', md: 'row' }} spacing="4" align={{ md: 'center' }}>
    <Checkbox
        isChecked={newSeance.motrice}
        onChange={(e) => setNewSeance({ ...newSeance, motrice: e.target.checked })}
      >
        Motrice
      </Checkbox>
      <Checkbox
        isChecked={newSeance.respiratoire}
        onChange={(e) => setNewSeance({ ...newSeance, respiratoire: e.target.checked })}
      >
        Respiratoire
      </Checkbox>
      <Checkbox
        isChecked={newSeance.paye}
        onChange={(e) => setNewSeance({ ...newSeance, paye: e.target.checked })}
      >
        Confirmation de paiement
      </Checkbox>
    </Stack>
   
    <Button colorScheme="blue" onClick={handleAddSeance}>
      Ajouter Seance
    </Button>
  </Stack>
</Box>

            <Table variant="simple" fontSize={{ sm: '14px', lg: '20px' }}>
              <Thead>
                <Tr>
                  <Th>Date</Th>
                  <Th>Motrice</Th>
                  <Th>Respiratoire</Th>
                  <Th>Prix</Th>
                  <Th>Actions</Th>
                </Tr>
              </Thead>
              <Tbody key={seancesKey}>
              {paginatedSeances?.map((seance: any, index: number) => (
                <Tr key={index}>
                  <Td>{formatDate(new Date(seance.date))}</Td>
                  <Td>{seance.motrice ? 'Oui' : 'Non'}</Td>
                  <Td>{seance.respiratoire ? 'Oui' : 'Non'}</Td>
                  <Td>{seance.price}</Td>
                  <Td>
                    {/* Add a delete button for each seance */}
                    <Button colorScheme="red" size="sm" onClick={() => handleDeleteSeance(seance._id)}>
                    <DeleteIcon />
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          {/* Add pagination controls */}
          <Stack direction="row" spacing="2" justify="flex-end">
            <Button
              onClick={() => setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))}
              disabled={currentPage === 1}
            >
              Précédent
            </Button>
            <Text>
              Page {currentPage} sur {totalPages}
            </Text>
            <Button
              onClick={() => setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))}
              disabled={currentPage === totalPages}
            >
              Suivant
            </Button>
          </Stack>
        </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};


export default PatientDetailsModal;
// Add this function somewhere in your code, ideally near other utility functions
const formatDate = (date: Date): string => {
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  };

  