// PrivateRoute.tsx
import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType<any>;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  ...rest
}: PrivateRouteProps) => {
  // Check if the user is authenticated (you can implement your own logic here)
  const isAuthenticated = localStorage.getItem('token') !== null;

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          // If authenticated, check if the user is trying to access the sign-in page
          rest.path === '/auth/sign-in' ? (
            // If so, redirect to the admin page
            <Redirect to="/admin" />
          ) : (
            // If not, render the component
            <Component {...props} />
          )
        ) : (
          // If not authenticated, redirect to the sign-in page
          <Redirect to="/auth/sign-in" />
        )
      }
    />
  );
};

export default PrivateRoute;
