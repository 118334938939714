import React from 'react';
import { Box, Text, Flex, IconButton, Stack, Link } from '@chakra-ui/react';
import { FaMapMarkerAlt, FaMobileAlt, FaFacebook, FaInstagram } from 'react-icons/fa';
import { AiOutlineWhatsApp } from 'react-icons/ai';

const FooterLanding = () => {
  return (
    <Box as="footer" bg="black" color="white" p="4rem" textAlign="center">
      <Stack spacing={4} mb={4}>
        <Text fontSize="lg" fontWeight="bold">Centre Ben Nahia</Text>
        <Text fontSize="sm">© 2024 Centre Ben Nahia. Tous droits réservés.</Text>
      </Stack>

      <Flex justify="center" mb={4}>
        <Link href="https://www.facebook.com/BenNahiaMedicoSpa" isExternal>
          <IconButton aria-label="Facebook" icon={<FaFacebook />} colorScheme="teal" variant="ghost" />
        </Link>
        <Link href="https://www.instagram.com/centre_ben_nahia" isExternal>
          <IconButton aria-label="Instagram" icon={<FaInstagram />} colorScheme="teal" variant="ghost" ml="1rem" />
        </Link>
        <Link href="https://wa.me/+21698970994" isExternal>
          <IconButton aria-label="WhatsApp" icon={<AiOutlineWhatsApp />} colorScheme="teal" variant="ghost" ml="1rem" />
        </Link>
      </Flex>

      <Flex direction="column" align="center">
        <Flex align="center" mb={2}>
          <FaMapMarkerAlt size={20} />
          <Text ml={2} fontSize={{ base: 'sm', md: 'md' }}>
            Adresse: Immeuble Ben Nahia, près de l'hôpital Tahar Sfar, 5111
          </Text>
        </Flex>
        <Flex align="center">
          <FaMobileAlt size={20} />
          <Text ml={2} fontSize={{ base: 'sm', md: 'md' }}>
            Téléphone: +216 98 709 994
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};

export default FooterLanding;
