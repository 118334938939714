import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  Switch,
  Text,
  useToast,
} from '@chakra-ui/react';
import apiClient from 'views/admin/appclient';

interface ClientAddModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAddClient: () => void;
}

const ClientAddModal: React.FC<ClientAddModalProps> = ({ isOpen, onClose, onAddClient }) => {
  const [fullName, setFullName] = useState('');
  const [facebook, setFacebook] = useState('');
  const [instagram, setInstagram] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [paymentStatus, setPaymentStatus] = useState(false);
  const toast = useToast();

  const handleAddClient = async () => {
    const accessToken = localStorage.getItem('token');

    if (!accessToken) {
      console.error('Access token not found in local storage');
      return;
    }
    if (!fullName) {
      toast({
        title: 'Le nom est requis.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (!phoneNumber) {
      toast({
        title: 'Le numéro est requis.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    const apiUrl = 'api/client';
    try {
      const response = await apiClient.post(
        apiUrl,
        {
          fullName,
          facebook,
          instagram,
          email,
          phoneNumber,
          paymentStatus,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      onAddClient();
      if (response.status === 200) {
        setFullName('');
        setFacebook('');
        setInstagram('');
        setEmail('');
        setPhoneNumber('');
        setPaymentStatus(false);
        onClose();
      } else {
        console.error('Error adding client. Status:', response.status);
      }
    } catch (error) {
      console.error('Error adding client:', error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Ajouter un nouveau client</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Nom complet</FormLabel>
            <Input type="text" value={fullName} onChange={(e) => setFullName(e.target.value)} />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Facebook</FormLabel>
            <Input type="text" value={facebook} onChange={(e) => setFacebook(e.target.value)} />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Instagram</FormLabel>
            <Input type="text" value={instagram} onChange={(e) => setInstagram(e.target.value)} />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Email</FormLabel>
            <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Numéro de téléphone</FormLabel>
            <Input type="text" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
          </FormControl>
          <FormControl mt={4} display="flex" alignItems="center">
            <FormLabel htmlFor="payment-status" mb="0">
              Statut de paiement
            </FormLabel>
            <Switch id="payment-status" isChecked={paymentStatus} onChange={() => setPaymentStatus(!paymentStatus)} />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleAddClient}>
            Ajouter Client
          </Button>
          <Button colorScheme="gray" onClick={onClose}>
            Annuler
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ClientAddModal;
