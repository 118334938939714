import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import apiClient from 'views/admin/appclient';
import Select from 'react-select';

interface EditArrousaProps {
  isOpen: boolean;
  onClose: () => void;
  onEditArrousa: () => void;
  arrousaId: string;
}

interface Service {
  _id: number;
  nom_service: string;
  prix: number;
}



const EditArrousa: React.FC<EditArrousaProps> = ({ isOpen, onClose, onEditArrousa, arrousaId }) => {
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    name: '',
    price: 0,
    description: '',
    remise: '',
    nbrAtt: '',
    services: [] as Service[],
  });
  const [services, setServices] = useState<Service[]>([]);
  const toast = useToast();

  const [isPasswordPromptOpen, setIsPasswordPromptOpen] = useState(false);
  const [password, setPassword] = useState('');
  const [isRemiseEditable, setIsRemiseEditable] = useState(false);

  const closeEditModal = () => {
    setFormData({
      name: '',
      price: 0,
      description: '',
      remise: '',
      nbrAtt: '',
      services: [],
    });
    onClose();
  };

  const fetchArrousaDetails = async () => {
    const apiUrl = `/api/arrousa/${arrousaId}`;
    try {
      const token = localStorage.getItem('token');
      const response = await apiClient.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response && response.status === 200) {
        const arrousaDetails = response.data;
        setFormData({
          name: arrousaDetails.name,
          price: arrousaDetails.price,
          description: arrousaDetails.description,
          remise: arrousaDetails.remise ? arrousaDetails.remise.toString() : '',
          nbrAtt: arrousaDetails.nbrAtt.toString(),
          services: arrousaDetails.services,
        });
      } else {
        console.error(`Failed to fetch arrousa details. Status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error fetching arrousa details:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchServicesAndClients = async () => {
    try {
      const token = localStorage.getItem('token');
      const [servicesResponse] = await Promise.all([
        apiClient.get('/api/service', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
       
      ]);

      setServices(servicesResponse.data);
    } catch (error) {
      console.error('Error fetching services and clients:', error);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    if (name === 'remise' && !isRemiseEditable) {
      setIsPasswordPromptOpen(true);
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleServicesChange = (selectedOptions: any) => {
    setFormData((prevData) => ({
      ...prevData,
      services: selectedOptions ? selectedOptions.map((option: any) => ({
        _id: option.value,
        nom_service: option.label,
      })) : [],
    }));
  };



  useEffect(() => {
    if (isOpen) {
      setLoading(true);
      fetchArrousaDetails();
      fetchServicesAndClients();
    }
  }, [isOpen, arrousaId]);

  const handleEdit = async () => {
    const apiUrl = `/api/arrousa/${arrousaId}`;
    try {
      const token = localStorage.getItem('token');
      const response = await apiClient.patch(
        apiUrl,
        {
          ...formData,
          price: parseFloat(formData.price.toString()),
          remise: formData.remise ? parseFloat(formData.remise) : undefined,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response && response.status === 200) {
        onEditArrousa();
        onClose();
        toast({
          title: 'Arrousa modifié avec succès',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Arrousa non modifié',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      console.error('Error updating arrousa:', error);
    }
  };

  const serviceOptions = services.map(service => ({
    value: service._id,
    label: service.nom_service,
  }));


  const handlePasswordSubmit = () => {
    if (password === '09383929') {
      setIsRemiseEditable(true);
      setIsPasswordPromptOpen(false);
    } else {
      alert('Incorrect password');
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={closeEditModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Modifier Arrousa</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {loading ? (
            <Spinner size="lg" color="blue.500" />
          ) : (
            <>
              <FormControl>
                <FormLabel>Nom</FormLabel>
                <Input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Description</FormLabel>
                <Textarea
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Remise</FormLabel>
                <Input
                  type="number"
                  name="remise"
                  value={formData.remise}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Nombre d'accompagnants</FormLabel>
                <Input
                  type="number"
                  name="nbrAtt"
                  value={formData.nbrAtt}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Services</FormLabel>
                <Select
                  isMulti
                  options={serviceOptions}
                  value={formData.services.map(service => ({
                    value: service._id,
                    label: service.nom_service,
                  }))}
                  onChange={handleServicesChange}
                  placeholder="Sélectionnez les services"
                />
              </FormControl>
             
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleEdit}>
            Modifier
          </Button>
          <Button colorScheme="gray" onClick={closeEditModal}>
            Annuler
          </Button>
        </ModalFooter>
      </ModalContent>

      <Modal isOpen={isPasswordPromptOpen} onClose={() => setIsPasswordPromptOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Enter Password</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Password</FormLabel>
              <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handlePasswordSubmit}>Submit</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Modal>
  );
};

export default EditArrousa;
