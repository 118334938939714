import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import apiClient from 'views/admin/appclient';

type AddTreatmentProps = {
  isOpen: boolean;
  onClose: () => void;
  onAddTreatment: () => void;
};

const AddTreatment: React.FC<AddTreatmentProps> = ({ isOpen, onClose, onAddTreatment }) => {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    price: '',
    // Add more fields as needed
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    // Assuming your backend endpoint for creating a new treatment is '/api/treatment'
    const apiUrl = '/api/treatment';
  
    try {
      const token = localStorage.getItem('token');
      const response = await apiClient.patch(apiUrl, formData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });
  
      if (!response) {
        console.error(`Failed to add treatment. Status: ${response.status}`);
        return;
      }
  
      const createdTreatment = response.data;
      onClose();
      onAddTreatment();
      console.log('Treatment added successfully:', createdTreatment);
    } catch (error) {
      console.error('Error adding treatment:', error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Treatment</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Name</FormLabel>
            <Input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>Description</FormLabel>
            <Input
              type="text"
              name="description"
              value={formData.description}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>Price</FormLabel>
            <Input
              type="number"
              name="price"
              value={formData.price}
              onChange={handleChange}
            />
          </FormControl>
          {/* Add more form controls for additional fields as needed */}
        </ModalBody>
        <ModalFooter>
          <Button style={{ marginRight: "5%" }} colorScheme="blue" onClick={handleSubmit}>
            Ajouter
          </Button>
          <Button mr={3} onClick={onClose}>
            Annnuler
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddTreatment;
