import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import apiClient from "views/admin/appclient";


interface EditUserProps {
  user: {
    _id: string;
    fullName: string;
    email: string;
    role: string;
    // Add other properties as needed
  };
  onUserEdited?: (userData: any) => void;
  onClose: () => void;
}

const EditUser: React.FC<EditUserProps> = ({ user, onUserEdited ,onClose }) => {
  const [formData, setFormData] = useState({
    _id:"",
    fullName: "",
    email: "",
    role: "user",
    password: "",
  });

  useEffect(() => {
    // Set the form data with the passed user object
    setFormData({
        _id: user._id,
      fullName: user.fullName,
      email: user.email,
      role: user.role,
      password: "", // You might choose whether to include the password or not
    });
  }, [user]);

  const toast = useToast();
  const [loading, setLoading] = useState(false); 
  const handleChange = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const nonEmptyFormData = Object.fromEntries(
        Object.entries(formData).filter(([_, value]) => value !== "")
      );
try{
          setLoading(true);
      const response = await apiClient.patch(
        `/api/user/`,
        {
          ...nonEmptyFormData,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
  

      toast({
        title: "User Updated",
        description: "The user has been updated successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      if (onUserEdited) {
        onUserEdited(response.data);
      }
    } catch (error) {
      console.error("Error updating user:", error);

      toast({
        title: "Error",
        description: "An error occurred while updating the user.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
        finally {
      setLoading(false); 
    }
  };
  const handleCancel = () => {
    onClose(); 
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <FormControl>
          <FormLabel>Nom complet</FormLabel>
          <Input
            type="text"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            required
          />
        </FormControl>

        <FormControl mt={4}>
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </FormControl>

        <FormControl mt={4}>
          <FormLabel>Mot de passe</FormLabel>
          <Input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
          />
        </FormControl>

        <FormControl mt={4}>
          <FormLabel>Role</FormLabel>
          <Select name="role" value={formData.role} onChange={handleChange} required>
            <option value="user">User</option>
            <option value="admin">Admin</option>
            <option value="doctor">Doctor</option>
            <option value="PHYSTOTHERAPIST">Kiné</option>
          </Select>
        </FormControl>
        <Flex justifyContent="space-between">
            
        <Button type="submit" mt={4} colorScheme="blue" disabled={loading}>
          {loading ? <Spinner size="sm" color="black.500" /> : "Modifier"}
        </Button>
        <Button type="button" mt={4} colorScheme="red" onClick={handleCancel} ml={2}>
            Annuler
        </Button>
        </Flex>
      </form>
    </Box>
  );
};

export default EditUser;
