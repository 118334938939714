  import React, { useEffect, useState } from 'react';
  import {
    Flex,
    Box,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    Button,
    useToast,
    Spinner,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Icon,
  } from '@chakra-ui/react';
  import { HamburgerIcon, EditIcon, DeleteIcon, CheckIcon, CloseIcon } from '@chakra-ui/icons';
  import {
    SortingState,
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,

  } from '@tanstack/react-table';

  import Card from 'components/card/Card';
  import EditClinique from './Editclinique';
  import AddClinique from './addclinique';
  import SearchBarClinique from './searchBarclinique';
  import Pagination from 'views/admin/Users/pagination';
import PatientDetailsModal from './Patientdeail';

import { error } from 'console';
import PaymentSummaryComponent from './PaymentSummary';
import appclient from 'views/admin/appclient';
  type RowObj = {
    _id: string;
    name: string;
    patients: any[];
    medcin: string;
    price: string[];
    payé: boolean;
    date: string;
    actions: string;
  };

  interface PageState {
    currentPage: number;
    totalPages: number;
  }

  interface CliniquesTableProps {
    cliniques: RowObj[];
  }

  const CliniquesTable: React.FC<CliniquesTableProps> = () => {
    const columnHelper = createColumnHelper<RowObj>();
    const [sorting, setSorting] = useState<SortingState>([]);
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
    const toast = useToast();
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState<PageState>({ currentPage: 1, totalPages: 1 });
    const [originalData, setOriginalData] = useState([]);
    const [isCliniqueFormOpen, setIsCliniqueFormOpen] = useState(false);
    const [isCliniqueEditOpen, setIsCliniqueEditOpen] = useState(false);
    const [editingClinique, setEditingClinique] = useState<RowObj | null>(null);
    const token = localStorage.getItem('token');
    const headers = { Authorization: `Bearer ${token}` };
    const [selectedPatient, setSelectedPatient] = useState(null);
const [isPatientModalOpen, setIsPatientModalOpen] = useState(false);

    const fetchData = (apiUrl: string) => {
  
      setIsLoading(true);
      appclient
      .get(apiUrl, { headers })
        .then((response) => {
          setIsLoading(false);
          if (!(apiUrl.includes('getAll'))) {
          setData(response.data.items);
         
          setPage({
            currentPage: response.data.page,
            totalPages: response.data.totalPages,
          });
        }
          if (apiUrl.includes('getAll')) {
            setOriginalData(response.data);
          }
        })
        
        .catch((error) => {
          setIsLoading(false);
          console.error('Error fetching Clinique records:', error);
        });
    };
     const handlePageChange = (selectedPage: number) => {
      const adjustedPage = Math.min(selectedPage, page.totalPages);
      const apiUrl = `/api/clinique/?page=${adjustedPage}`;
      console.log(selectedPage);
    
      appclient
        .get(apiUrl, { headers })
        .then((response) => {
          setIsLoading(false);
          setData(response.data.items);
          setCurrentPage(adjustedPage);
          setPage({
            currentPage: adjustedPage,
            totalPages: response.data.totalPages,
          });
        })
        .catch((error) => {
          setIsLoading(false);
          console.error('Error fetching Clinique records:', error);
          console.log(adjustedPage);
        });
    };
    
    const apiUrl = `/api/clinique/?page=${currentPage}`;
    useEffect(() => {
  
      fetchData(apiUrl);
    }, []);
    React.useEffect(() => {
      // Fetch data with page=all
      fetchData(`/api/clinique/getAll`);
    }, []);
    const handlePaymentStatusChange = async (chambreId: string, patientId: string,  newStatus: boolean) => {
      try {
        const authToken = localStorage.getItem('token');

        const response = await appclient.patch(`/api/clinique/updatePaymentStatus/${chambreId}/${patientId}}`, {
          isPaied : newStatus,
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
          },
        });
    
        if (response) {
          fetchData(apiUrl);
        } else {
          console.error('Failed to update payment status');
        }
      } catch (error) {
        console.error('Error updating payment status:', error);
      }
    };
    
    
    const columns = [
      columnHelper.accessor('name', {
        id: 'name',
        header: () => (
          <Text fontSize={{ sm: '10px', lg: '20px' }} color="blue.500">
            Chambre
          </Text>
        ),
      }),
      columnHelper.accessor('patients', {
        id: 'patients',
        header: () => (
          <Text fontSize={{ sm: '10px', lg: '20px' }} color="blue.500">
            Patient
          </Text>
        ),
        cell: (info) => (
          <>
            {info.row.original.patients.map((patient: any, index: number) => (
              
              <Button
                key={index}
                fontSize={{ sm: '10px', lg: '20px' }}
                color={textColor}
                onClick={() => {
                  setSelectedPatient({...patient , chambreId: info.row.original._id});
                  setIsPatientModalOpen(true);
                }}
              >
                {patient.name}
              </Button>
            ))}
          </>
        ),
      }),
      columnHelper.accessor('medcin', {
        id: 'medcin',
        header: () => (
          <Text fontSize={{ sm: '10px', lg: '20px' }} color="blue.500">
            Médecin
          </Text>
        ),

        cell: (info) => (
          <>
            {info.row.original.patients.map((patient: any, index: number) => (
              <Text key={index} fontSize={{ sm: '10px', lg: '20px' }}  color={textColor}>
             {patient.med || "non spécifié"}

              </Text>
            ))}
          </>
        ),
      }),
      columnHelper.accessor('price', {
        id: 'prcie',
        header: () => (
          <Text fontSize={{ sm: '10px', lg: '20px' }} color="blue.500">
            Prix
          </Text>
        ),
        cell: (info) => (
          <>
            {info.row.original.patients.map((patient: any, index: number) => (
              <Text key={index} fontSize={{ sm: '10px', lg: '20px' }} color={textColor}>
                {patient.seances.length > 0 ? patient.totalPrice : 'N/A'}
              </Text>
            ))}
          </>
        ),
        // You may not need the sortType property
      }),
      
      columnHelper.accessor('payé', {
        id: 'payé',
        header: () => (
          <Text fontSize={{ sm: '10px', lg: '20px' }} color="blue.500">
            Paiement
          </Text>
        ),
        cell: (info) => {
          const isAnyPatientPaid = info.row.original.patients.map((patient: any) => patient.isPaied);
        
          return (
            <Button onClick={() => {
              const chambreId = info.row.original._id;
              const patientIds = info.row.original.patients.map((patient: any) => patient._id);
            
              // Loop through patientIds and call handlePaymentStatusChange for each patient
              patientIds.forEach((patientId: string) => {
                handlePaymentStatusChange(chambreId, patientId, !isAnyPatientPaid[0]);
                
              });
            }}>
        <Text fontSize={{ sm: '10px', lg: '20px' }} color={textColor}>
  {isAnyPatientPaid[0] === true ? (
    <Icon as={CheckIcon} color="green.500" />
  ) : (
    <Icon as={CloseIcon} color="red.500" />
  )}
</Text>

            </Button>
          );
        },
        
        
        
      }),
      columnHelper.accessor('date', {
        id: 'date',
        header: () => (
          <Text fontSize={{ sm: '10px', lg: '20px' }}  color="blue.500">
            Date
          </Text>
        ),
        cell: (info) => (
          <>
            {info.row.original.patients.map((patient: any, index: number) => (
              <Text key={index} fontSize={{ sm: '10px', lg: '20px' }}  color={textColor}>
                {patient.seances.length > 0 ? formatDate(new Date(patient.seances[0].date), 'dd/MM/yyy') : 'N/A'}

              </Text>
            ))}
          </>
        ),
      }),
      columnHelper.accessor('actions', {
        id: 'actions',
        header: () => (
          <Text fontSize={{ base: '20px', md: '14px', lg: '20px' }} color="blue.500">
            Actions
          </Text>
        ),
        cell: (info: any) => (
          <Box
          display="flex"
          justifyContent="ceter"
          alignItems="center"
          marginLeft="10%"
        >
            <Menu>
              <MenuButton as={Button} fontSize={{ sm: '10px', lg: '16px' }} colorScheme="gray">
                <HamburgerIcon />
              </MenuButton>
              <MenuList>
                <MenuItem
                  fontSize={{ sm: '10px', lg: '16px' }}
                  onClick={() => handleEditClinique(info.row.original)}
                >
                  <EditIcon mr={2} />
                  Modifier
                </MenuItem>
                <MenuItem fontSize={{ sm: '10px', lg: '16px' }} onClick={() => onDelete(info.row.original._id)}>
                  <DeleteIcon mr={2} />
                  Supprimer
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        ),
      }),
    ];

    const onDelete = (cliniqueId: string) => {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
    
      appclient
        .delete(`/api/clinique/${cliniqueId}`, { headers })
        .then(() => {
          toast({
            title: 'Clinique supprimée  avec succés',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
          fetchData(`/api/clinique/?page=${currentPage}`);
        })
        .catch((error) => {
          console.error('Error deleting clinique:', error);
    
          toast({
            title: 'Une erreur s\'est produite lors de la suppression de la clinique',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        });
    };
    
    const handleEditClinique = (clinique: RowObj) => {
      setEditingClinique(clinique);
      setIsCliniqueEditOpen(true);
    };  
    
    const handleAddClinique = (newClinique: any) => {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
    
      appclient
        .post(`/api/clinique/`, newClinique, { headers })
        .then(() => {
          toast({
            title: 'Clinique ajoutée  avec succés',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
    fetchData(`/api/clinique/?page=${currentPage}`);
        })
        .catch((error) => {
          console.error('Une erreur ce produit lors de création de clinique:', error);
          toast({
            title: 'Une erreur s\'est produite lors de l\'ajout de la clinique',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        });
    
    
      setIsCliniqueFormOpen(false);
      fetchData(`/api/clinique/?page=${currentPage}`);
    };

    const handleCloseAddClinique = () => {
      setIsCliniqueFormOpen(false);
    };

    const handleEditCliniqueModalSave = () => {
      setEditingClinique(null);
      setIsCliniqueEditOpen(false);
      fetchData(`/api/clinique/?page=${currentPage}`);
    };

    const handleEditCliniqueClose = () => {
      setEditingClinique(null);
      setIsCliniqueEditOpen(false);
    };
    const [data, setData] = useState([]);
    const table = useReactTable({
      data,
      columns,
      state: {
        sorting,
      },
      onSortingChange: setSorting,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      debugTable: true,
    },
    
    );

    const handleDeleteSeance = async (seanceId: string, patientId: string, chambreId: string) => {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
    
      try  {
      await appclient.delete(`/api/clinique//${chambreId}/${patientId}/${seanceId}/seances`  , { headers });
      fetchData(`/api/clinique/?page=${currentPage}`);
      setIsPatientModalOpen(false)
      toast({
        title: 'Séance supprimée avec succés',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
  } catch(error){
    toast({
      title: 'Erreur s\'est produite avec la supprission de la seance',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });
      }
        }
    const handleAddSeance = async (seance: any) => {
try{
    fetchData(`/api/clinique/?page=${currentPage}`);
    setIsPatientModalOpen(false)
    toast({
      title: 'Séance ajoutée  avec succés',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
} catch(error){
  toast({
    title: 'Erreur s\'est produite avec l\'ajout de la seance',
    status: 'error',
    duration: 3000,
    isClosable: true,
  });
}

    };
    
    
    
    const [selectedCategory, setSelectedCategory] = useState('patient');
    const handleSearch = (searchQuery: string, searchCategory: string) => {
      if (!originalData) {
        fetchData('/api/clinique/getAll');
        return;
      }
      if (searchQuery.trim() === '') {
        fetchData(`/api/clinique/?page=${currentPage}`);
        return;
      }
      const filteredData = originalData.filter((clinique: RowObj) => {
        const fieldToSearch =
          searchCategory === 'chambre' ? (clinique && clinique.name || '').toString().toLowerCase() :
          searchCategory === 'patient' ? (clinique && clinique.patients.map(patient => patient.name).join(' ') || '').toString().toLowerCase() :
            '';
    
        return fieldToSearch.includes(searchQuery?.toLowerCase());
      });
    
      setData(filteredData);
    };
    
    
    return (
      <>
        <Card w="100%" px="0px" overflowX={{ sm: 'scroll', lg: 'hidden' }}>
        <PatientDetailsModal
        isOpen={isPatientModalOpen}
        onClose={() => setIsPatientModalOpen(false)}
        patient={selectedPatient}
        onAddSeance={handleAddSeance}
        onDeleteSeance={handleDeleteSeance} 
      />
      <Flex>



      </Flex>
      <Flex
      px={{ base: '10px', md: '25px' }}
      mb={{ base: '4px', md: '8px' }}
      justifyContent={{ base: 'center', md: 'space-between' }}
      align="center"
      flexDirection={{ base: 'column', md: 'row' }}
      textAlign={{ base: 'center', md: 'left' }}
    >
      <Text
        color={textColor}
        fontSize={{ base: '20px', md: '24px' }}
        mb={{ base: '4px', md: '0' }}
        fontWeight="700"
        lineHeight="100%"
      >
        Liste des chambre
      </Text>

      <Flex mt={{ base: '20px', md: '0' }} justifyContent={{ base: 'center', md: 'space-between' }}>
        </Flex>
  <SearchBarClinique
    onSearch={handleSearch}
    selectedCategory={selectedCategory}
    setSelectedCategory={setSelectedCategory}
  />
      <Button
        colorScheme="blue"
        onClick={() => setIsCliniqueFormOpen(true)}
        mt={{ base: '4', md: '0' }}
        width={{ base: '100%', md: 'auto' }}
      >
        Ajouter une chambre
      </Button>
    </Flex>
          <PaymentSummaryComponent/>
          <Box>
            {isLoading ? (
              <Flex justifyContent="center" align="center" height="200px" fontSize="20px">
                <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
                Loading
              </Flex>
            ) : (
              <Table variant="simple" color="gray.500" mb="24px" mt="12px">
                <Thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <Tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <Th
                          key={header.id}
                          colSpan={header.colSpan}
                          pe="10px"
                          borderColor={borderColor}
                          cursor="pointer"
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          <Flex
                            justifyContent="space-between"
                            align="center"
                            fontSize={{ sm: '10px', lg: '20px' }}
                            color="gray.400"
                          >
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {{
                              asc: '',
                              desc: '',
                            }[header.column.getIsSorted() as string] ?? null}
                          </Flex>
                        </Th>
                      ))}
                    </Tr>
                  ))}
                </Thead>
                <Tbody>
                {table.getRowModel().rows.map((row , rowIndex) => {
                  return (
                    <Tr key={row.id}>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <Td
                            key={cell.id}
                            fontSize={{ sm: '14px' , lg :"20px" }}
                            minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                            borderBottomWidth="1px"
                            backgroundColor={`rgba(127, 204, 252, ${rowIndex % 2 === 0 ? 0 : 0.1})`} 
                            borderStyle="solid"
                            color={textColor}
                          >
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
              </Tbody>
              </Table>
            )}
          </Box>
          <Flex justify="center">
            <Box>
              <Pagination currentPage={page.currentPage} totalPages={page.totalPages} onPageChange={handlePageChange} />
            </Box>
          </Flex>
        </Card>
        <Modal isOpen={isCliniqueFormOpen} onClose={() => setIsCliniqueFormOpen(false)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Ajouter chambre</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <AddClinique onClose={handleCloseAddClinique} onCliniqueAdded={handleAddClinique }  />
            </ModalBody>
          </ModalContent>
        </Modal>
        <Modal isOpen={isCliniqueEditOpen} onClose={handleEditCliniqueClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Modifier Chambre</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Pass the required props to EditClinique component */}
            <EditClinique
              clinique={editingClinique}
              onClose={handleEditCliniqueClose}
              onCliniqueEdited={handleEditCliniqueModalSave}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      </>
    );
  };

  export default CliniquesTable;
  const formatDate = (date: Date, format: string = 'dd/MM/yyyy'): string => {
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    };
    return new Intl.DateTimeFormat('fr-FR', options).format(date);
  };
  