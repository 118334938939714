import React, { useRef, useState } from "react";
import {
  Box,
  Button,
  ChakraProvider,
  Flex,
  Text,
  Heading,
  Image,
  VStack,
  useColorModeValue,
  Collapse,
  IconButton,
} from "@chakra-ui/react";
import { FaRunning, FaSpa, FaUserMd } from "react-icons/fa";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import Footerlanding from "./footerlanding";
import SignInModal from "./signinmodal";
import { motion } from "framer-motion"; // Import Framer Motion

const SignIn = () => {
  const homeRef = useRef<HTMLDivElement | null>(null);
  const faqRef = useRef<HTMLDivElement | null>(null);
  const servicesRef = useRef<HTMLDivElement | null>(null);
  const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);
  const [faqOpen, setFaqOpen] = useState<number | null>(null);

  const scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const carouselImages = [
    "/images/image2.jpeg",
    "/images/image3.jpeg",
  ];

  const faqData = [
    {
      question: "Qu'est-ce que la kinésithérapie?",
      answer:
        "La kinésithérapie implique des techniques de thérapie physique visant à améliorer le mouvement et à réduire la douleur grâce à des exercices, des massages et d'autres méthodes thérapeutiques.",
    },
    {
      question: "Comment puis-je prendre un rendez-vous?",
      answer:
        "Vous pouvez prendre un rendez-vous en appelant notre cabinet. Nous offrons des horaires flexibles pour répondre à vos besoins.",
    },
    {
      question: "Proposez-vous des services de réhabilitation?",
      answer:
        "Oui, nous sommes spécialisés dans des programmes de réhabilitation post-blessure adaptés au plan de récupération de chaque patient.",
    },
  
    {
      question: "Comment puis-je bénéficier des services de kinésithérapie couverts par la CNAM?",
      answer:
        "Pour bénéficier des services de kinésithérapie couverts par la CNAM, vous devez vous inscrire auprès de votre médecin traitant, qui vous fournira une référence. Assurez-vous de vérifier que le kinésithérapeute est agréé par la CNAM.",
    },
    {
      question: "Quelles sont les conditions pour obtenir un remboursement par la CNAM?",
      answer:
      "Les conditions pour obtenir un remboursement par la CNAM incluent la soumission de votre dossier médical, la référence de votre médecin, et la facturation des services par un kinésithérapeute agréé. De plus, vous devez fournir un formulaire de prise en charge pour que votre traitement soit couvert. Vous devez également respecter les délais de soumission des demandes.",
    },
  ];
  

  const servicesData = [
    {
      title: "Massage Thérapeutique",
      description: "Massages relaxants et thérapeutiques adaptés à vos besoins.",
      image: "/images/image1.jpg",
      icon: <FaSpa />,
    },
    {
      title: "Réhabilitation Post-Blessure",
      description: "Programmes spécialisés pour la récupération après une blessure ou une chirurgie.",
      image: "/images/rehab.jpg",
      icon: <FaUserMd />,
    },
    {
      title: "Thérapie Sportive",
      description: "Soins complets pour les athlètes afin de prévenir et de traiter les blessures.",
      image: "/images/sports-therapy.jpg",
      icon: <FaRunning />,
    },
    {
      title: "Soins Spa",
      description: "Services de spa pour vous détendre et vous revitaliser.",
      image: "/images/spa.jpg",
      icon: <FaSpa />,
    },
  ];

  return (
    <ChakraProvider>
      {/* Navbar */}
      <Flex
        as="nav"
        bg="black"
        p="1rem"
        color="white"
        justify="space-between"
        align="center"
        position="fixed"
        width="100%"
        zIndex={1000}
      >
        <Box display="flex" alignItems="center">
          <Image
            src="/images/bennahialogo.png"
            alt="Logo"
            boxSize="50px"
            mr="10px"
          />
          <Text fontSize="xl" fontWeight="bold">
            Centre Ben Nahia
          </Text>
        </Box>
        <Flex>
          <Button variant="ghost" onClick={() => scrollToSection(homeRef)}>
            Accueil
          </Button>
          <Button variant="ghost" onClick={() => scrollToSection(servicesRef)}>
            Services
          </Button>
          <Button variant="ghost" onClick={() => scrollToSection(faqRef)}>
            FAQ
          </Button>
          <Button backgroundColor="#fddbd1" onClick={() => setIsSignInModalOpen(true)}>
            Se Connecter
          </Button>
        </Flex>
      </Flex>

      {/* Hero Section */}
      <Box ref={homeRef} position="relative" pt="70px">
        <Carousel
          autoPlay
          interval={5000} // 5 seconds
          infiniteLoop
          showThumbs={false}
          showIndicators={true}
          showStatus={false}
        >
          {carouselImages.map((src, index) => (
            <div key={index}>
              <Image
                src={src}
                alt={`Slide ${index}`}
                objectFit="cover"
                width="100%"
                height="600px"
                borderRadius="md"
              />
            </div>
          ))}
        </Carousel>
        <Box
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          textAlign="center"
        >
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <Heading color="white" fontSize="4xl">
              Bienvenue chez Centre Ben Nahia
            </Heading>
            <Text color="white" fontSize="lg">
              Nous fournissons une expertise en kinésithérapie pour votre bien-être
            </Text>
          </motion.div>
        </Box>
      </Box>

      {/* Services Section */}
      <motion.div
        ref={servicesRef}
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Box p="4rem" bg={useColorModeValue("gray.100", "gray.800")}>
          <Heading textAlign="center" mb="2rem">
            Nos Services
          </Heading>
          <Flex wrap="wrap" justify="center" gap="2rem">
            {servicesData.map((service, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <VStack
                  bg="white"
                  p="2rem"
                  boxShadow="lg"
                  borderRadius="md"
                  w="300px"
                  h="100%" // Fixed height for uniformity
                  transition="transform 0.3s"
                  _hover={{ transform: "scale(1.05)" }}
                  align="center"
                >
                  <Box mb="1rem" fontSize="2rem">
                    {service.icon}
                  </Box>
                  <Text fontSize="xl" textAlign={"center"} fontWeight="bold">
                    {service.title}
                  </Text>
                  <Text textAlign="center">{service.description}</Text>
                </VStack>
              </motion.div>
            ))}
          </Flex>
        </Box>
      </motion.div>

      {/* New Well-Designed Section */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
<Box p="4rem" bg={useColorModeValue("white.100", "gray.700")} borderRadius="md">
<Heading textAlign="center" color={useColorModeValue("blue.800", "white")} mb="2rem">
            Pourquoi Choisir Nos Services?
          </Heading>
          <Text fontSize="lg" textAlign="center" color={useColorModeValue("gray.800", "gray.200")}>
            Au Centre Ben Nahia, nous nous engageons à vous offrir les meilleurs soins de santé. Nos services sont
            personnalisés et adaptés à vos besoins spécifiques. Que ce soit pour une réhabilitation, un massage
            ou des soins de spa, notre équipe expérimentée est là pour vous aider à retrouver votre bien-être.
          </Text>
        </Box>
      </motion.div>

      {/* Google Maps Section */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
          <Box
          width="100%"
          textAlign="center"
          backgroundColor="white.100"
      
     
        >
          <Box mb={4} p={4} >
          
            <Heading textAlign="center" color={useColorModeValue("blue.800", "white")} mb="2rem">
            Trouvez-nous plus rapidement sur la carte ci-dessous :
            </Heading>
            <hr style={{ borderColor: '#000', borderWidth: '2px', margin: '10px 0' }} />
          </Box>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3247.671187547153!2d11.032588174901012!3d35.512407939231025!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x130223c8db3b79cf%3A0x42d75de554f131d5!2sCentre%20Ben%20Nahia%20Oussama(%20r%C3%A9%C3%A9ducation)!5e0!3m2!1sfr!2stn!4v1700483229895!5m2!1sfr!2stn"
            width="100%"
            height="450"
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
          />
        </Box>
      </motion.div>

      {/* FAQ Section */}
      <motion.div
        ref={faqRef}
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Box p="4rem">
          <Heading textAlign="center" mb="2rem">
          Questions Fréquemment Posées
          </Heading>
          <VStack spacing={4} align="stretch">
            {faqData.map((item, index) => (
              <Box key={index} borderWidth={1} borderRadius="md" p={4}>
                <Flex justify="space-between" align="center">
                  <Text fontWeight="bold">{item.question}</Text>
                  <IconButton
                    icon={faqOpen === index ? <RiEyeCloseLine /> : <MdOutlineRemoveRedEye />}
                    onClick={() => setFaqOpen(faqOpen === index ? null : index)}
                    variant="outline"
                    colorScheme="teal" aria-label={""}                  />
                </Flex>
                <Collapse in={faqOpen === index}>
                  <Text mt={2}>{item.answer}</Text>
                </Collapse>
              </Box>
            ))}
          </VStack>
        </Box>
      </motion.div>

      {/* Footer */}
      <Footerlanding />

      {/* Sign In Modal */}
      <SignInModal isOpen={isSignInModalOpen} onClose={() => setIsSignInModalOpen(false)} />
    </ChakraProvider>
  );
};

export default SignIn;
