import React from 'react';
import { Button, Flex, Text } from '@chakra-ui/react';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}
const Pagination: React.FC<PaginationProps> = ({ currentPage, totalPages, onPageChange }) => {
  const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);
  return (
    <Flex justifyContent="center" my={4}>
      <Button
        onClick={() => {
          console.log("Previous clicked");
          onPageChange(currentPage - 1);
        }}
        disabled={currentPage === 1}
        m={1}
      >
        Previous
      </Button>
      {pageNumbers.map((page) => (
        <Button
          key={page}
          onClick={() => {
            console.log(`Page ${page} clicked`);
            onPageChange(page);
          }}
          variant={page === currentPage ? 'solid' : 'outline'}
          colorScheme={page === currentPage ? 'blue' : 'gray'} 
          m={1}
        >
          {page}
        </Button>
      ))}
      <Button
        onClick={() => {
          onPageChange(currentPage + 1);
        }}
        disabled={currentPage === totalPages}
        m={1}
      >
        Next
      </Button>
    </Flex>
  );
};
export default Pagination;
