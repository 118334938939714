// chakra imports
import { Box, Flex, Stack } from '@chakra-ui/react';
//   Custom components
import Brand from 'components/sidebar/components/Brand';
import Links from 'components/sidebar/components/Links';

// FUNCTIONS

function SidebarContent(props: { routes: RoutesType[] }) {
	const { routes } = props;
	const sidebarRoutes = routes.filter(route => route.layout === '/admin') ; 
	// SIDEBAR
	return (
		<Box >
		<Flex  position='relative' direction='column' w={{ base: "auto", md: "auto", xl: "100%" , lg :"auto" }} h='200%' pt='10px' borderRadius='30px'>
			<Box fontSize="2xl"  mb="4"> {/* Add margin bottom to create space between Brand and Links */}
				<Brand /> {/* Increase fontSize or use a different value that suits your design */}
			</Box>
			<Stack direction='column' mt='-5%'  mb='auto'>
				<Box>
					<Links routes={sidebarRoutes} />
				</Box>
			</Stack>
<Stack>
	
</Stack>
		</Flex>
		{/* <div style ={{ position: 'absolute', bottom: 0, width: '100%' , alignItems	: 'center' , justifyContent: 'center'}} >
		<p>Support </p>
		support goes here with social media 
		</div> */}
		</Box>
	);
}

export default SidebarContent;
