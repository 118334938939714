import React, { useState, useEffect } from 'react';
import apiClient from '../appclient';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement, LineElement } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Flex, FormLabel, Input, Button, Box, Select, Text, Grid } from '@chakra-ui/react';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LineElement
);

type StatType = 'services' | 'packs' | 'arrousa';

const formatDate = (date: string) => {
  if (!date) return '';
  const d = new Date(date);
  const year = d.getFullYear();
  const month = d.getMonth() + 1; // Months are 0-based
  const day = d.getDate();
  return `${year}-${month}-${day}`;
};

const SpaStats = () => {
  const [chartData, setChartData] = useState<any>(null);
  const [selectedStat, setSelectedStat] = useState<StatType>('services');
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = localStorage.getItem('token');
        if (!accessToken) {
          throw new Error('Access token not found in local storage');
        }

        // Format dates to YYYY-M-D
        const formattedStartDate = formatDate(startDate);
        const formattedEndDate = formatDate(endDate);

        const params = {
          startDate: formattedStartDate,
          endDate: formattedEndDate
        };

        const response = await apiClient.get('api/caisse/spa-payment-stats', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params
        });

        const data = response.data;
        setChartData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [selectedStat, startDate, endDate]);

  if (!chartData) return <Text>Chargement...</Text>;

  const transformData = (data: any) => {
    if (!data) return { labels: [], values: [], totalAmounts: [] };

    switch (selectedStat) {
      case 'services':
        return {
          labels: data.servicesStats.map((item: any) => item._id),
          values: data.servicesStats.map((item: any) => item.totalSold),
          totalAmounts: data.servicesStats.map((item: any) => item.totalAmount)
        };
      case 'packs':
        return {
          labels: data.packsStats.map((item: any) => item._id),
          values: data.packsStats.map((item: any) => item.totalSold),
          totalAmounts: data.packsStats.map((item: any) => item.totalAmount)
        };
      case 'arrousa':
        return {
          labels: data.arrousaStats.map((item: any) => item._id),
          values: data.arrousaStats.map((item: any) => item.totalSold),
          totalAmounts: data.arrousaStats.map((item: any) => item.totalAmount)
        };
      default:
        return { labels: [], values: [], totalAmounts: [] };
    }
  };

  const { labels, values, totalAmounts } = transformData(chartData);

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      tooltip: {
        callbacks: {
          label: function(context: any) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += context.parsed.y.toFixed(1);
            }
            return label;
          }
        }
      }
    },
    scales: {
      y: {
        ticks: {
          stepSize: 1 // Set the step size to 1
        }
      }
    }
  };
  

  const totalAmountForSelectedStat = totalAmounts.reduce((acc: number, amount: number) => acc + amount, 0);

  const statNames: Record<StatType, string> = {
    services: 'Statistiques des services',
    packs: 'Statistiques des packs',
    arrousa: 'Statistiques Aroussa'
  };

  const handleReset = () => {
    setStartDate('');
    setEndDate('');
    // Optionally, you might want to fetch default data here
    // e.g., fetchData(); but without params to get default data
  };

  return (
    <Box mt={5}>
      <Flex
        direction={{ base: 'column', md: 'row' }}
        align="center"
        justify="space-between"
        gap={4}
        mb={5}
      >
        <FormLabel htmlFor="statType" mb={0}>Sélectionner le type de statistique :</FormLabel>
        <Select
          id="statType"
          onChange={(e) => setSelectedStat(e.target.value as StatType)}
          value={selectedStat}
          size="md"
          width={{ base: 'full', md: '20%' }}
          mb={{ base: 4, md: 0 }}
        >
          <option value="services">Statistiques des services</option>
          <option value="packs">Statistiques des packs</option>
          <option value="arrousa">Statistiques Aroussa</option>
        </Select>

        <Flex direction="column" gap={2} width={{ base: 'full', md: 'auto' }}>
          <FormLabel htmlFor="startDate" mb={0}>Date de début :</FormLabel>
          <Input
            id="startDate"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            size="md"
          />
        </Flex>
        <Flex direction="column" gap={2} width={{ base: 'full', md: 'auto' }}>
          <FormLabel htmlFor="endDate" mb={0}>Date de fin :</FormLabel>
          <Input
            id="endDate"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            size="md"
          />
        </Flex>
        <Button onClick={handleReset} colorScheme="blue" size="md" mt={{ base: 2, md: 0 }}>
          Réinitialiser
        </Button>
      </Flex>

      <Box mb={5}>
        <Grid
          templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
          gap={4}
        >
          <Box p={4} borderWidth="1px" borderRadius="md" boxShadow="md" bg="white">
            <Text fontSize="md" fontWeight="bold">Total des séances SPA :</Text>
            <Text fontSize="xl">{chartData.totalSpas}</Text>
          </Box>
          <Box p={4} borderWidth="1px" borderRadius="md" boxShadow="md" bg="white">
            <Text fontSize="md" fontWeight="bold">Total des séances payées :</Text>
            <Text fontSize="xl">{chartData.paidCount}</Text>
          </Box>
          <Box p={4} borderWidth="1px" borderRadius="md" boxShadow="md" bg="white">
            <Text fontSize="md" fontWeight="bold">Total des séances non payées :</Text>
            <Text fontSize="xl">{chartData.unpaidCount}</Text>
          </Box>
          <Box p={4} borderWidth="1px" borderRadius="md" boxShadow="md" bg="white">
            <Text fontSize="md" fontWeight="bold">Total payé :</Text>
            <Text fontSize="xl">{chartData.totalPaidWithDeposit}</Text>
          </Box>
          <Box p={4} borderWidth="1px" borderRadius="md" boxShadow="md" bg="white">
            <Text fontSize="md" fontWeight="bold">Montant total pour {statNames[selectedStat]} :</Text>
            <Text fontSize="xl">{totalAmountForSelectedStat}</Text>
          </Box>
        </Grid>
      </Box>

      <Box mt={5}>
        <Bar
          data={{
            labels: labels,
            datasets: [{
              label: 'Total Vendu',
              data: values,
              backgroundColor: 'rgba(75, 192, 192, 0.2)',
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 1
            }]
          }}
          options={chartOptions}
        />
      </Box>
    </Box>
  );
}

export default SpaStats;
