import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import apiClient from 'views/admin/appclient';

type AddMedProps = {
  isOpen: boolean;
  onClose: () => void;
  onAddMedicalRecord: () => void;
};

const AddMed: React.FC<AddMedProps> = ({ isOpen, onClose, onAddMedicalRecord }) => {
  const [formData, setFormData] = useState({
    nom: '',
    tel: '',
    fax: '',
    email: '',
    spécialité: '',
    mobile: '',
    adresse: '',
    hop: '',
    // Add more fields as needed
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    // Assuming your backend endpoint for creating a new medical record is '/api/medecin'
    const apiUrl = '/api/medecin';
  
    try {
   const token = localStorage.getItem('token');
      const response = await apiClient.post(apiUrl, formData, {
        headers: {
          'Content-Type': 'application/json',
        
           'Authorization': `Bearer ${token}`
        },
      });
  
      if (!response) {
        console.error(`Failed to add medical record. Status: ${response.status}`);
        return;
      }
  
      const createdMedicalRecord = response.data;
      onClose();
      onAddMedicalRecord();
      console.log('Medical record added successfully:', createdMedicalRecord);
    } catch (error) {
      console.error('Error adding medical record:', error);
    }
  };
  
  

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Medical Record</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Nom</FormLabel>
            <Input
              type="text"
              name="nom"
              value={formData.nom}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>Téléphone</FormLabel>
            <Input
              type="tel"
              name="tel"
              value={formData.tel}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>Spécialité</FormLabel>
            <Input
              type="text"
              name="spécialité"
              value={formData.spécialité}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Adresse</FormLabel>
            <Input
              type="text"
              name="adresse"
              value={formData.adresse}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Hôpital / Cabinet / Clinique</FormLabel>
            <Input
              type="text"
              name="hop"
              value={formData.hop}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Email</FormLabel>
            <Input
              type="text"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
        <Button style={{ marginRight: "5%" }} colorScheme="blue" onClick={handleSubmit}>
            Ajouter
          </Button>
          <Button  mr={3} onClick={onClose}>
            Annuler
          </Button>
       
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddMed;
