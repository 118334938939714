import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import apiClient from "views/admin/appclient";

interface AddUserProps {
    onUserAdded?: (userData: any) => void;
    onClose: () => void;
  }
  const AddUser: React.FC<AddUserProps> = ({ onUserAdded  , onClose}) => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    role: "user", 
    password:"",
  });

  const toast = useToast();
  const [loading, setLoading] = useState(false); 
  const handleChange = (e:any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleCancel = () => {
    onClose(); 
  };
  const handleSubmit = async (e:any) => {
    e.preventDefault();

    try {
      setLoading(true);
      const response = await apiClient.post("/api/user/create", formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      toast({
        title: "User Added",
        description: "The user has been added successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      // Clear the form data
      setFormData({
        fullName: "",
        email: "",
        role: "user",
        password: "",
        // Reset other form fields as needed
      });

      // Trigger a callback if provided
      if (onUserAdded) {
        onUserAdded(response.data);
      }
    } catch (error) {
      console.error("Error adding user:", error);

      toast({
        title: "Error",
        description: "An error occurred while adding the user.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    finally {
      setLoading(false); 
    }
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <FormControl>
          <FormLabel>Nom complet </FormLabel>
          <Input
            type="text"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            required
          />
        </FormControl>

        <FormControl mt={4}>
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </FormControl>
        <FormControl mt={4}>
          <FormLabel>Mot de passe</FormLabel>
          <Input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
        </FormControl>

        <FormControl mt={4}>
          <FormLabel>Role</FormLabel>
          <Select
            name="role"
            value={formData.role}
            onChange={handleChange}
            required
          >
            <option value="user">User</option>
            <option value="ADMIN">Admin</option>
            <option value="DOCTOR">Doctor</option>
            <option value="PHYSTOTHERAPIST">Kiné</option>     
          </Select>
        </FormControl>
        <Flex justifyContent="space-between">
              
        <Button type="submit" mt={4} colorScheme="blue" disabled={loading}>
          {loading ? <Spinner size="sm" color="black.500" /> : "Ajouter"}
        </Button>
        <Button type="button" mt={4} colorScheme="red" onClick={handleCancel} ml={2}>
            Annuler
        </Button>
        </Flex>
      </form>
    </Box>
  );
};

export default AddUser;
